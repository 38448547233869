import React, { Component } from 'react';

import { Button, Form, FormGroup, Label, Input, FormText, Alert, UncontrolledAlert } from 'reactstrap';
import $ from 'jquery';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from 'react-router-dom';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export const validMobile = new RegExp(/^[6789]\d{9}$/);

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            mobilenumber: '',
            alertFade: true,
            formMessage: '',
            formStatus: '',
            show: false,
            basicTitle: '',
            basicType: "default",
            showotp: 0,
            otp: "",
            registration: 0,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            loadshow: "show-n",
            cnfpassword: "",
            checkbx: 0,
            shownext: "show-n",
            errocheck: "",
            showerr: "show-n",
            errinp: "",
            regerrmsg: "",
            showresend: "show-m",
            errorms: "show-n",
            errocheckotp: ""
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.formHandler = this.formHandler.bind(this);
        this.cipher = this.cipher.bind(this);
        this.formOTPHandler = this.formOTPHandler.bind(this);
        this.formRegHandler = this.formRegHandler.bind(this);
        this.changeFormHandler = this.changeFormHandler.bind(this);
        this.changeCheckboxHandler = this.changeCheckboxHandler.bind(this);
        this.changeDigitHandler = this.changeDigitHandler.bind(this);
        this.resetCounter = this.resetCounter.bind(this);
        this.showresend = this.showresend.bind(this);
        this.changeHandlerMobile = this.changeHandlerMobile.bind(this);
    }

    changeDigitHandler(event) {
        var d1 = $("#rdigit-1").val();
        var d2 = $("#rdigit-2").val();
        var d3 = $("#rdigit-3").val();
        var d4 = $("#rdigit-4").val();
        var d5 = $("#rdigit-5").val();
        var d6 = $("#rdigit-6").val();
        var evalid = d1 + "" + d2 + "" + d3 + "" + d4 + "" + d5 + "" + d6;

        this.setState({ otp: evalid });
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        if (localStorage.getItem("s") == undefined) {
            localStorage.setItem("s", 0)
        }
        if (localStorage.getItem("rb") == 1) {
            this.setState({
                loadshow: "show-m"
            })
            var cc1 = localStorage.getItem("regcntr");
            // console.log("cccc ",cc1)
            var countDownDate1 = new Date(cc1).getTime();
            // console.log("countDownDate ",countDownDate1);
            // Update the count down every 1 second
            var x1 = setInterval(function () {

                // Get today's date and time
                var now1 = new Date().getTime();
                // console.log("now ",now1);
                // Find the distance between now and the count down date
                var distance1 = parseInt(countDownDate1) - parseInt(now1);

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance1 / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance1 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance1 % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance1 % (1000 * 60)) / 1000);

                // Output the result in an element with id="demo"
                document.getElementById("counteregistration").innerHTML = "Please wait for " + minutes + " mins " + seconds + " secs ";

                //   console.log("distance ", distance1)
                // If the count down is over, write some text 
                if (distance1 < 0) {
                    clearInterval(x1);
                    document.getElementById("counteregistration").innerHTML = "";
                    localStorage.setItem("rb", 0);
                    window.location.reload();
                }
            }, 1000);
        }
        if (localStorage.getItem("rs") != undefined) {

            if (localStorage.getItem("rs") == 1 || localStorage.getItem("rs") == "1") {
                try {
                    var element = document.getElementById("rsbtn");
                    element.classList.remove("show-m");
                    element.classList.add("show-n");

                    var ccr = localStorage.getItem("cntrrst");
                    var countDownDater = new Date(ccr).getTime();

                    // Update the count down every 1 second
                    var xr = setInterval(function () {

                        // Get today's date and time
                        var nowr = new Date().getTime();

                        // Find the distance between now and the count down date
                        var distancer = countDownDater - nowr;
                        // console.log("distance ", distancer)
                        // Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distancer / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distancer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distancer % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distancer % (1000 * 60)) / 1000);

                        // Output the result in an element with id="demo"
                        //   console.log("Please wait for "+minutes + " mins " + seconds + " secs ");
                        try {
                            // document.getElementById("resendopt").innerHTML = "(Resend OTP: ) "+ parseInt(seconds) + " secs ";
                            document.getElementById("resendopt").innerHTML = "Resend OTP in " + seconds + " seconds";
                            // If the count down is over, write some text 
                            // console.log("distace resend ", distancer)
                            if (distancer < 0) {
                                clearInterval(xr);
                                document.getElementById("resendopt").innerHTML = "";
                                localStorage.setItem("rs", 0);
                                var element = document.getElementById("rsbtn");
                                element.classList.remove("show-n");
                                element.classList.add("show-m");
                                // this.showresend()
                                // this.resetCounter()
                                //    window.location.reload();
                            }

                        }
                        catch (e) {

                        }
                    }, 1000);
                }
                catch (e) {

                }

            }
        }
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }
    showresend() {
        this.setState({
            showresend: "show-m"
        })
    }
    resetCounter() {
        var a = decode(localStorage.getItem("a"));
        var formdata = {
            a: a
        }
        // console.log("formdata ", formdata);
        redirectURL.post('/registration/resetacounter', formdata, {
            headers: {
                'Content-Disposition': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Methods': 'POST'
            }
        })
            .then((response) => {
                window.location.reload();
            })
    }

    changeHandler(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (isNaN(value) == false) {
            this.setState({
                [name]: value
            });

        }
        else {
            this.setState({
                [name]: ""
            });

        }
    }

    changeFormHandler(event) {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({
            [name]: value
        });

    }
    changeCheckboxHandler(event) {
        // console.log("here")
        var checkbx = $("#checkbxrg").is(":checked");
        if (checkbx == true) {
            if (this.state.mobilenumber != "") {
                if (this.state.mobilenumber.length == 10) {

                    $("#myLogbtn").attr("type", "submit");
                    $("#myLogbtn").css("background-color", "#198754");
                }
                else {
                    $("#myLogbtn").attr("type", "button");
                    $("#myLogbtn").css("background-color", "#C4C4C4");
                }
            }
            else {

                $("#myLogbtn").attr("type", "button");
                $("#myLogbtn").css("background-color", "#C4C4C4");
            }
            this.setState({
                checkbx: 1,
                shownext: "show-m",
                errocheck: ""
            })
        }
        else {
            $("#myLogbtn").attr("type", "button");
            $("#myLogbtn").css("background-color", "#C4C4C4");
            this.setState({
                checkbx: 1,
                shownext: "show-n"
            })
        }
    }
    formHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);
        var checkbx = $("#checkbxrg").is(":checked");
        console.log("checkbx ", checkbx)
        if (checkbx == true) {
            // var data = new FormData();
            // data.append('m',this.state.mobilenumber)
            var evalid = this.state.mobilenumber;
            if (evalid.length > 10 || evalid.length < 10) {
                this.setState({
                    show: true,
                    basicTitle: "Please enter valid mobile number.",
                    basicType: "danger",
                    mobilenumber: ""
                })
            }
            else {

                var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                var formdata = {
                    m: this.state.mobilenumber
                }
                // console.log("formdata ", formdata);
                redirectURL.post('/registration/send', formdata, {
                    headers: {
                        'Content-Disposition': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Methods': 'POST'
                    }
                })
                    .then((response) => {
                        // console.log(response.data)
                        if (response.data.data.status == "OTP_TRIGGERED") {
                            localStorage.setItem("a", ppwwdd(this.state.mobilenumber))
                            localStorage.setItem("s", 1)
                            localStorage.setItem("rs", 1)
                            var dt = moment.parseZone().add(31, "seconds").format("MM DD, YYYY HH:mm:ss");
                            localStorage.setItem("cntrrst", dt)
                            this.setState({
                                formStatus: response.data.status,
                                showotp: 1,
                                loadshow: "show-m"
                            });
                            window.location.reload();
                        }
                        else {
                            this.setState({
                                // mobilenumber: '',
                                errinp: "errinp",
                                showerr: "show-m",
                                regerrmsg: response.data.data.message
                            });


                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }
        }
        else {
            this.setState({
                errocheck: "errfntchk"
            })
        }
    }
    resendotp() {
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var evalid = decode(localStorage.getItem("a"));
        if (evalid.length > 10 || evalid.length < 10) {
            this.setState({
                show: true,
                basicTitle: "Please enter valid mobile number.",
                basicType: "danger",
                mobilenumber: ""
            })
        }
        else {

            var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var formdata = {
                m: evalid
            }
            // console.log("formdata ", formdata);
            redirectURL.post('/registration/resend', formdata, {
                headers: {
                    'Content-Disposition': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Methods': 'POST'
                }
            })
                .then((response) => {
                    console.log(response.data)
                    if (response.data.data.status == "OTP_TRIGGERED") {
                        localStorage.setItem("rs", 1)
                        var dt = moment.parseZone().add(31, "seconds").format("MM DD, YYYY HH:mm:ss");
                        localStorage.setItem("cntrrst", dt)

                        this.setState({
                            formStatus: response.data.status,
                            showotp: 1,
                            loadshow: "show-m"
                        });
                        window.location.reload();
                    }
                    else {
                        this.setState({
                            mobilenumber: '',
                            errinp: "errinp",
                            showerr: "show-m",
                            regerrmsg: response.data.data.message
                        });


                    }

                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }
    formOTPHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var evalid = this.state.otp;
        if (evalid.length > 6 || evalid.length < 6) {
            this.setState({
                show: true,
                basicTitle: "Please enter valid OTP",
                basicType: "danger",
                otp: ""
            })
        }
        else {

            var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var usrpwd = this.state.otp;
            var a = decode(localStorage.getItem("a"));
            var formdata = {
                m: usrpwd,
                a: a
            }
            // console.log("formdata ", formdata);
            redirectURL.post('/registration/verify', formdata, {
                headers: {
                    'Content-Disposition': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Methods': 'POST'
                }
            })
                .then((response) => {
                    if (response.data.status == "success") {

                        localStorage.setItem("registration", 1)
                        localStorage.setItem("s", 0)
                        this.setState({
                            formStatus: response.data.status,
                            otp: '',
                            // registration:1,
                            showotp: 0
                        });

                    }
                    else {
                        if (response.data.b == 1) {
                            localStorage.setItem("rb", 1);
                            var dt = moment.parseZone().add(5, "minutes").format("MM DD, YYYY HH:mm:ss");
                            localStorage.setItem("regcntr", dt)
                            this.setState({
                                formMessage: response.data.message,
                                formStatus: response.data.status,
                                alertFade: false,
                                otp: '',
                                // show:true,
                                // basicType:"danger",
                                // basicTitle:response.data.message
                                errocheckotp: "errocheckotp",
                                errorms: "show-m",
                            });
                            window.location.reload()
                        }
                        else {
                            this.setState({
                                formMessage: response.data.message,
                                formStatus: response.data.status,
                                alertFade: false,
                                otp: '',
                                // show:true,
                                // basicType:"danger",
                                // basicTitle:response.data.message
                                errocheckotp: "errocheckotp",
                                errorms: "show-m",
                            });
                        }

                    }

                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }

    closeAlert() {
        this.setState({
            show: false,
            basicTitle: ""
        })
    }


    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    isNumber() {
        var evt = $("#mobilenumber").val();
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    formRegHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

        var email = this.state.email.replace(/\s/g, "").toLowerCase();

        var verifypwd = "";

        var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (this.state.password.match(decimal)) {
            verifypwd = true;
        }
        else {
            verifypwd = false;
        }
        var fvalid = this.state.firstname;
        var lvalid = this.state.lastname;
        var evalid = this.state.email;
        if (fvalid == "" && evalid == "") {
            this.setState({
                show: true,
                basicTitle: "Please enter valid data in fields",
                basicType: "danger",
                firstname: "",
                lastname: "",
                email: "",
                password: ""
            })
        }
        else {
            // console.log("this.state.mobilenumber ", this.state.mobilenumber)
            console.log(this.state.password + "==" + this.state.cnfpassword)
            if (verifypwd == true) {
                if (this.state.password == this.state.cnfpassword) {
                    var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                    var usrpwd = ppwwdd(this.state.password)
                    var a = localStorage.getItem("a")
                    var formdata = {
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email: email,
                        password: usrpwd,
                        a: a
                    }
                    // console.log("formdata ", formdata);
                    redirectURL.post('/registration', formdata, {
                        headers: {
                            'Content-Disposition': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Methods': 'POST'
                        }
                    })
                        .then((response) => {
                            if (response.data.status == "success") {
                                localStorage.removeItem("a");
                                localStorage.removeItem("s");
                                localStorage.removeItem("regcntr");
                                localStorage.removeItem("rb");
                                localStorage.removeItem("registration");

                                localStorage.clear();
                                this.setState({
                                    show: true,
                                    basicTitle: "Successfully registered. Please check you mail to activate your account.",
                                    basicType: "success",
                                    firstname: '',
                                    lastname: '',
                                    email: '',
                                    password: '',
                                    role: '',
                                });
                                localStorage.setItem("s", 0);

                            }
                            else {
                                this.setState({
                                    show: true,
                                    basicTitle: response.data.message,
                                    basicType: "danger",
                                    firstname: '',
                                    lastname: '',
                                    email: '',
                                    password: '',
                                    role: '',
                                });

                            }

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Passwords did not match",
                        basicType: "danger"
                    });
                }
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                    basicType: "danger"
                })
            }
        }
    }

    removeOtpScreen() {
        localStorage.setItem("s", "0");
        window.location.reload();
    }
    changeHandlerMobile = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;

        const re = /^[0-9\b]+$/;

		if (((value === '') || (re.test(value))) && (value.length <= 10)) {
			this.setState({ [name]: value, showerr: "show-n", errinp: "" });
		}

		if (!validMobile.test(value) || !$("#checkbxrg").is(":checked")) {
			$("#myLogbtn").attr("type", "button")
			$("#myLogbtn").css("background-color", "#C4C4C4");
		}
		else {
			$("#myLogbtn").attr("type", "submit")
            $("#myLogbtn").css("background-color", "#198754");
		}
    }

    onClickShowLogin() {
        localStorage.setItem("t", 1)
        window.location.reload();
    }
    render() {
        if (localStorage.getItem("registration") == 1 || localStorage.getItem("registration") == "1") {
            return <Redirect to={"/registration"} push={true} />
        }
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert.bind(this)}
                >
                </SweetAlert>
                {this.state.alert}

                {(localStorage.getItem("s") == 0 && localStorage.getItem("registration") == undefined) ?
                    <form className="theme-form" onSubmit={this.formHandler}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="col-form-label">Mobile No. </label>
                                    <input required
                                        type="text"
                                        className={"form-control myforminp " + (this.state.errinp)}
                                        name="mobilenumber"
                                        id="mobilenumber"
                                        autocomplete="off"
                                        value={this.state.mobilenumber}
                                        onChange={this.changeHandlerMobile}
                                    />
                                    <div className={"errfnt " + this.state.showerr}>
                                        <i className="icofont icofont-warning-alt"></i> {this.state.regerrmsg}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="text-left mt-2 m-l-20 anchrtg">
                                &nbsp;
                            </div>
                        </div>
                        <div className="form-row mt-10p">
                            <div className={"col-sm-12 anchrtg " + (this.state.errocheck)}>
                                <input type="checkbox"
                                    name="checkbx"
                                    id="checkbxrg"
                                    value="1"
                                    onClick={this.changeCheckboxHandler}
                                />
                                &nbsp;I agree to the <a href="">T&amp;C</a> and <a href="">Privacy Policies</a>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="text-left mt-2 m-l-20 anchrtg">
                                &nbsp;
                            </div>
                        </div>
                        <div className="form-row mt-10p">
                            <div className="col-sm-3">
                                <button type="button" className={"mt-10p btn myLogbtn "} id="myLogbtn" style={{ backgroundColor: "#C4C4C4" }}>Get OTP
                                </button>
                            </div>
                            <div className="col-sm-12">
                                <div className="text-left mt-2 m-l-20 anchrtg">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="text-left mt-2 m-l-20 anchrtg">
                                    Are you already user?&nbsp;&nbsp;<a href={"javascript:;"} onClick={this.onClickShowLogin.bind(this)} className="btn-link text-capitalize">Log In</a>
                                </div>
                            </div>

                        </div>

                    </form>
                    :
                    (localStorage.getItem("s") == 1) ?
                        <form className="theme-form  digit-group" onSubmit={this.formOTPHandler} data-group-name="digits" autocomplete="off">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="col-form-label w100">Mobile No.
                                            <span className="float-right rmmob" onClick={this.removeOtpScreen.bind(this)}>Change</span>
                                        </label>
                                        <span
                                            className="form-control myforminp"
                                            name="mobilenumber"
                                            id="mobilenumber"
                                            readOnly>{(localStorage.getItem("a") != "" && localStorage.getItem("a") != undefined) ? decode(localStorage.getItem("a")) : ""}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="col-form-label w100">OTP
                                            <span id="resendopt" className=" float-right"></span>
                                            <span id="rsbtn" className={"float-right rmmob " + (this.state.showresend)} onClick={this.resendotp.bind(this)}>Resend</span>
                                        </label>
                                        {/* <input required 
                                    type="text" 
                                    className="form-control" 
                                    name="otp"
                                    id="mobilenumber" 
                                    value={this.state.otp} 
                                    onChange={this.changeHandler} placeholder="Enter OTP"/> */}
                                        <div className={"" + (this.state.errocheckotp)}>
                                            <input type="text" className="dig" id="rdigit-1" name="rdigit-1" data-next="rdigit-2" onChange={this.changeDigitHandler} placeholder="*" />
                                            <input type="text" className="dig" id="rdigit-2" name="rdigit-2" data-next="rdigit-3" data-previous="rdigit-1" onChange={this.changeDigitHandler} placeholder="*" />
                                            <input type="text" className="dig" id="rdigit-3" name="rdigit-3" data-next="rdigit-4" data-previous="rdigit-2" onChange={this.changeDigitHandler} placeholder="*" />
                                            <input type="text" className="dig" id="rdigit-4" name="rdigit-4" data-next="rdigit-5" data-previous="rdigit-3" onChange={this.changeDigitHandler} placeholder="*" />
                                            <input type="text" className="dig" id="rdigit-5" name="rdigit-5" data-next="rdigit-6" data-previous="rdigit-4" onChange={this.changeDigitHandler} placeholder="*" />
                                            <input type="text" className="dig" id="rdigit-6" name="rdigit-6" data-previous="rdigit-5" onChange={this.changeDigitHandler} placeholder="*" />
                                        </div>
                                        <div className={"errfnt " + this.state.errorms}>
                                            <i className="icofont icofont-warning-alt"></i> Invalid OTP
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group form-row mt-3">
                                <div className="col-md-12 textAlignCenter">
                                    {(this.state.otp.length == 6) ?
                                        <button type="submit" style={{ backgroundColor: "#198754" }} className="btn myLogbtn">Submit OTP
                                        </button>
                                        :
                                        <button type="button" style={{ backgroundColor: "#C4C4C4" }} className="btn myLogbtn">Submit OTP
                                        </button>
                                    }
                                </div>
                                <div className="col-sm-12">
                                    <div className="text-left mt-2 m-l-20">
                                        Are you already user?&nbsp;&nbsp;<a href={"/"} className="btn-link text-capitalize">Log In</a>
                                    </div>
                                </div>

                            </div>

                        </form> : ""
                }
                {(localStorage.getItem("registration") == 1) ?
                    <form className="theme-form" onSubmit={this.formRegHandler}>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">First Name</label>
                                    <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeFormHandler} placeholder="Enter First Name" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="col-form-label">Last Name</label>
                                    <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeFormHandler} placeholder="Enter Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Email Address</label>
                            <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeFormHandler} placeholder="Email Address" />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Password</label>
                            <input type="password" required className="form-control" name="password" value={this.state.password} onChange={this.changeFormHandler} placeholder="**********" />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Confirm Password</label>
                            <input type="password" required className="form-control" name="cnfpassword" value={this.state.cnfpassword} onChange={this.changeFormHandler} placeholder="**********" />
                        </div>
                        <div className="form-row">
                            <div className="col-sm-12">
                                <button type="submit" className="btn myLogbtn">Sign Up
                                </button>
                            </div>
                            <div className="col-sm-12">
                                <div className="text-left mt-2 m-l-20">
                                    Are you already user?&nbsp;&nbsp;<a href={"/"} className="btn-link text-capitalize">Login</a>
                                </div>
                            </div>

                        </div>

                    </form>
                    : ""
                }

                <div className={"overlay-part " + (this.state.loadshow)}>
                </div>

                <div className={"counterload " + (this.state.loadshow)} id="counteregistration"></div>
            </div>

        )
    }
}

function CheckPassword(inputtxt) {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (inputtxt.match(decimal)) {
        return true;
    }
    else {
        return false;
    }
}
function CheckField(inputtxt) {
    var decimal = /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9]+$/;
    if (inputtxt.match(decimal)) {
        return true;
    }
    else {
        return false;
    }
}
function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}


$(document).ready(function () {
    $('.digit-group').find('input').each(function () {
        $(this).attr('maxlength', 1);
        $(this).on('keyup', function (e) {
            if (isNaN($(this).val())) {
                $(this).val("");
            }
            else {
                var parent = $($(this).parent());

                if (e.keyCode === 8 || e.keyCode === 37) {
                    var prev = parent.find('input#' + $(this).data('previous'));

                    if (prev.length) {
                        $(prev).select();
                    }
                } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                    var next = parent.find('input#' + $(this).data('next'));

                    if (next.length) {
                        $(next).select();
                    } else {
                        if (parent.data('autosubmit')) {
                            parent.submit();
                        }
                    }
                }
            }
        });
    });
})


export default Register;