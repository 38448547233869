
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
 
const configureApproveAction = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("TransitDelay ",props.data);
        props.context.componentParent.assignTruck(props);
    };
    var status = props.data.status;
    return (
        <div style={{textAlign:"center"}}>
            {(status == 1)?
            <button title="Assign Truck" style={{paddingTop:"0px"}} className={"btn btn-info"} onClick={handleOverspeedClick}>Assign Truck</button>
            : "Rejected"}
        </div>
    );
};

export default configureApproveAction;