import React, {Component} from 'react';
import axios from 'axios';
// import jwtDecode from 'jwt-decode';
// import { Redirect } from 'react-router';
var googleAnalytics = require("../googleanalytics");
var redirectURL = require('../../redirectURL');

// Import authService

class UserMenuMobile extends Component {
    constructor(props) {
		super(props);
		this.state = {
            welcomeMessage: ""
		}
	}
	logOut = async(e) => {
        e.preventDefault();
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"manual"
        }
        await redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then((resp) => {
            console.log("resp ", resp)
        })

        if (googleAnalytics.page.enableGA) {
            let pageTitle = "Logout";
            let formAction = googleAnalytics.page.actionType.miscAction;
            let eventOptions = {
                "category": pageTitle,
                "action": formAction,
                "label": googleAnalytics.page.action.userLogout,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var loginType = 0;
        if(localStorage.getItem("is_enmovil_transporter") == 1)
        {
            loginType = 1;
        }
        else if(localStorage.getItem("is_load_management") == 1){
            loginType = 2;
        }
    	await localStorage.removeItem('token');
    	await localStorage.removeItem('userid');
    	await localStorage.removeItem('dept_code');
    	await localStorage.removeItem('firstname');
    	await localStorage.removeItem('lastname');
        await localStorage.removeItem('email');
        await localStorage.removeItem('is_admin');
    	await localStorage.removeItem('is_global');
    	await localStorage.removeItem('is_dept_admin');
    	await localStorage.removeItem('usermenucodes');
    	await localStorage.removeItem('loading_port_code');
        await localStorage.removeItem('loadingPointName');
        await localStorage.removeItem('a');
        await localStorage.clear();
        sessionStorage.setItem('ref',null);
    	//localStorage.setItem('menuText',[]);
        await delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer:''
        });
        console.log("logout successfully",localStorage.getItem('token'));
        
        
        await relaodpage(loginType);
    }
    setWelcomeMessage() {
        let firstName = localStorage.getItem("firstname");
        let lastName = localStorage.getItem("lastname");
        let fullName = localStorage.getItem("email");
        if (firstName !== "undefined" || lastName !== "undefined") {
            fullName = firstName;
        }
        //this.setState({welcomeMessage: "Welcome " + fullName })
        this.setState({welcomeMessage: fullName })
    }
    componentDidMount() {
        this.setWelcomeMessage();
    }
    render() {
    	/*if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
		{
    		return <Redirect to="/" push={true} />
		}*/
        return (
            <li>
                <a href="#" onClick={this.logOut.bind(this)} title="Logout">
                    <i className="icon-power-off"></i>
                    Logout
                </a>
            </li>
        );
    }
}

function relaodpage(loginType){
    if(loginType == 1)
    {
        window.location.href="/tptlogin";
    }
    else if(loginType == 2)
    {
        window.location.href="/optirun";
    }
    else
    {
        window.location.reload();
    }
}

export default UserMenuMobile;




