import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';
import Footer from '../common/footer/footer.component';
import FooterMobile from '../common/footer/footerMobileComponent';
import SignupForm from './register';
import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';
//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

class Login extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
            loginMessage:'',
            is_enmovil_transporter:0,
            is_load_management:0,
            active_tab: '1',
            navMenus:false
        };
		this.toggle = this.toggle.bind(this);
        this.toggleHeaderNav = this.toggleHeaderNav.bind(this);
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
    toggle(tab) {
        if (localStorage.getItem("t") !== tab) {
            
            localStorage.setItem("t",tab)
            this.setState({
                active_tab: tab
            });
        }

    }

   
    toggleHeaderNav() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }

    render() {
        let style = this.state.style;
        if(window.location.hostname == "3.108.113.108")
        {
            var background = require('../../assets/images/login-image.jpg');
        }
        else
        {
            if(this.state.is_enmovil_transporter == 1 || window.location.hostname != "autometrics.in" || this.state.is_load_management == 1)
            {
                var background = require('../../assets/images/auth-layer.png');
            }
            else
            {
                var background = require('../../assets/images/login-image.jpg');
            }
        }
               
       // console.log("window.location.hostname-",window.location.hostname)
        return (
            <div>
                {/* Loader starts */}
                {/* <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div> */}
                {/* Loader ends */}
               
                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                     {/* Page Header */}
                    
                    {/* Page Header End */}
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >
                            
                            <div className="row" style={{height:"100vh"}}>                               
                                <div className="col-md-8 col-sm-12 logbg">
                                {/* <img src={require('../../assets/images/login_banner.png')} className={"logo-login w100"} alt=""/> */}
                                    <div className="login-banner-content">
                                        {/* <h2 className="login-head-title">Welcome to <br /> Maruti Suzuki AGI Glasspac System</h2> */}
                                        
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mobt-30p">
                                    <div className="auth-innerright" >
                                        <div className="authentication-box">
                                            <Nav tabs className={'tabs-color'}>
                    
                                                <NavItem className={'login-width'}>
                                                    <NavLink
                                                        className={classnames({ active: 1 })}
                                                        onClick={() => { this.toggle('1'); }} >
                                                        LOGIN
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem >
                                                    <NavLink
                                                        className={classnames({ active: localStorage.getItem("t") === '2' })}
                                                        onClick={() => { this.toggle('2'); }} >
                                                        Sign Up
                                                    </NavLink>
                                                </NavItem> */}
                                            </Nav>
                                            <TabContent className="ltb" activeTab="1">
                                                <TabPane tabId="1">
                                                    <Row>
                                                        <Col sm="12">
                                                            <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                        <Col sm="12">
                                                            <SignupForm />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                    
                        </div>
                        {/*login page end*/}
                    </div>
                </div>
                {/*page-wrapper Ends*/}
                {/* <div  className="mobsh">
                    <Footer />
                </div>
                <div className="mobshw">
                    <FooterMobile className="mobshw" />
                </div> */}
            </div>

        )
    }
}


export default Login;
