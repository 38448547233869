
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
 
const configureAction = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("TransitDelay ",props.data);
        props.context.componentParent.changeStatus(props);
    };
    var status = props.data.status;
    var agi_do_number = props.data.agi_do_number;
    var documentation_completed = props.data.documentation_completed;
    var labelName = "";
    var btnCls = "";
    if(status == 1)
    {
        labelName = "Gate-1 In Time";
        btnCls = "primary";
    }
    else if(status == 2)
    {
        if(agi_do_number !=undefined && agi_do_number !="")
        {
            labelName = "Gate-2 In Time";
            btnCls = "success";
        }
        else
        {
            labelName = "Generate Delivery Order";
            btnCls = "warning";
        }
    }
    else if(status == 3)
    {
        labelName = "Loading Started On";
        btnCls = "info";
    }
    else if(status == 4)
    {
        labelName = "Loading Completed On";
        btnCls = "warning";
    }
    else if(status == 5 && documentation_completed == 0)
    {
        labelName = "Documentation Completed On";
        btnCls = "primary";
    }
    else if(status == 5 && documentation_completed == 1)
    {
        labelName = "Gate-2 Out Time";
        btnCls = "default";
    }
    else if(status == 6)
    {
        labelName = "Gate-1 Out Time";
        btnCls = "danger";
    }
    return (
        <div style={{textAlign:"center"}}>
            {/* <i title={labelName} style={{fontSize:'20px',color:"#198754"}} onClick={handleOverspeedClick} className="icofont icofont-settings"></i> */}
            <button title="Update" style={{paddingTop:"0px"}} className={"btn btn-"+btnCls} onClick={handleOverspeedClick}>{labelName}</button>
        </div>
    );
};

export default configureAction;
