import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import redirectURL from '../../redirectURL';
import { getDDMMMYYYYHHMMDefault } from '../utils';
var moment = require('moment');
// Import authService
class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            notificationData : "",
            notificationCount : "",
            rowData : []
        }

       this.redirectToModule = this.redirectToModule.bind(this);
    }
    redirectToModule(rowId){
        // console.log(rowId);
        var userRole = localStorage.getItem('roles');
        var params = {
            rowId:rowId,
        }
        
    }
    componentDidMount(){
        var userEmail = localStorage.getItem('email');
        var userRole = localStorage.getItem('roles');
        // console.log("userRole ", userRole)
        
        var erparmas = {
            userEmail:userEmail,
            read_status:0,            
        }
        var erparmas1 = {
            userRole:userRole,
            read_status:0,            
        }
       
    }
    
    render() {
        var userRole = localStorage.getItem('roles');
        
        var ismll = 0
        
        return (
            <li className="onhover-dropdown">
                <a className="txt-dark">
                    <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/notification.png")} alt="header-notification" />
        <span className="badge badge-pill badge-primary notification">{(this.state.notificationCount==0)?"":this.state.notificationCount}</span>
                </a>
                {(ismll == 0)?
                <ul className="notification-dropdown onhover-show-div" style={{height:"300px",overflowY:"auto"}}>
                    <li>Notifications <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">{(this.state.notificationCount==0)?"No New":this.state.notificationCount +" New"}</span></li>
                    {/* {console.log("Render loop ", this.state.rowData)} */}

                    {this.state.rowData.map((e) => (
                    <li>
                        <div className="media">
                            <div className="media-body">
                                <p className="mb-0">{e.user_notification}</p>
                                {e.notification_date !=undefined && e.notification_date !='' ?
                                <span><i className="icofont icofont-clock-time p-r-5"></i>{moment.parseZone(e.notification_date).format("DD-MM-YYYY HH:mm")}</span>
                                : ""}
                                &nbsp;&nbsp;
                                <span style={{color:"#e5503f"}} onClick={this.redirectToModule.bind(this,e._id)}>Mark As Read</span>
                            </div>
                        </div>
                    </li>
                    ))
                    }
                    
                    {/* <li className="text-center">You have Check <a href="#">all</a> notification  </li> */}
                </ul>
                :""}

                {(ismll == 1)?
                <ul className="notification-dropdown onhover-show-div">
                    <li>Notification <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">{(this.state.notificationCount==0)?"No New":this.state.notificationCount +" New"}</span></li>
                    {/* {console.log("Render loop ", this.state.rowData)} */}

                    {this.state.rowData.map((e) => (
                    <li>
                        <div className="media">
                            <div className="media-body">
                                <p className="mb-0">{e.notification_message}</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>{getDDMMMYYYYHHMMDefault(e.notification_creation_time)}</span> &nbsp;&nbsp;
                                <span style={{color:"#e5503f"}} onClick={this.redirectToModule.bind(this,e._id)}>Mark As Read</span>
                            </div>
                        </div>
                    </li>
                    ))
                    }
                    
                    {/* <li className="text-center">You have Check <a href="#">all</a> notification  </li> */}
                </ul>

                :""}
            </li>
        );
    }
}

export default Notification