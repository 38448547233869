
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
 
const configureApproveAction = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        //console.log("TransitDelay ",props.data);
        props.context.componentParent.changeApproveStatus(props);
    };
    return (
        <div style={{textAlign:"center"}}>
            <button title="Approve/Reject" style={{paddingTop:"0px"}} className={"btn btn-danger"} onClick={handleOverspeedClick}>Approve/Reject</button>
        </div>
    );
};

export default configureApproveAction;