import React, { Component } from 'react';
import { Redirect } from 'react-router';

// Import custom components
import ErrorHandler from './common/errorHandler';
import Header from './common/header/header.component';
import Sidebar from './common/sidebar/sidebar.component';
import Footer from './common/footer/footer.component';
import FooterMobile from './common/footer/footerMobileComponent';
//import './api-config';
import '../assets/css/encss.css';
import '../assets/css/responsive.css';
import axios from 'axios';

import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Provider } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { Router, browserHistory } from 'react-router';
import { sessionService, sessionReducer } from 'redux-react-session';
import { useBeforeunload } from 'react-beforeunload';

import $ from 'jquery';


var moment = require('moment');
var googleAnalytics = require("./common/googleanalytics");
var redirectURL = require('./redirectURL');
const reducer = combineReducers({
    session: sessionReducer
});

const store = createStore(reducer);

// Init the session service
sessionService.initSessionService(store, { driver: 'COOKIES' });

var rurl = '';


class App extends Component {
    constructor(props) {
        super(props);
        this.showside = this.showside.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            sidebar: true,
            navMenus: true,
            ssinner: 'show-n',
            sideimg: require('../assets/icons/sidenav-blue.png'),
            imgbg: 'whgb',
            l20n: '',
            showOutIcon: 'show-m',
            sclose: 'show-m',
            logginStatus: true,
            userroles: [],
            pageStyle: {},
			basicType:"default",
            loadshow:'show-n',
            overly:'',
            show:false,
        }
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];


        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.autologout = this.autologout.bind(this);
        this.userLastActivity = this.userLastActivity.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
        this.checkLoginTime = this.checkLoginTime.bind(this);
        this.decipher = this.decipher.bind(this);
        //this.clearStorage = this.clearStorage.bind(this);

        var userroles = localStorage.getItem("roles");
        this.setState({
            userroles: userroles
        })
        //console.log("userroles", userroles);
        try {
            if (userroles.indexOf("led") == -1) {

                for (var i in this.events) {
                    window.addEventListener(this.events[i], this.resetTimeout);
                }
                this.setTimeout();
            }
        }
        catch (e) {
            console.error(e);
        }
        //console.log(this.state.sidebar);
    }

    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
        // this.setupBeforeUnloadListener();
        var usertype = localStorage.getItem("user_type");

        this.warnTimeout = setTimeout(this.warn, 1 * 1000);
        if (usertype == 'DCTUSER') {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 120 * 1000);
        }
        else {
            this.logoutTimeout = setTimeout(this.autologout, 60 * 30 * 1000);
        }

    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        //alert("You will be logged out automatically in 1 minute.");
    }

    logout() {
        console.log("loginggggOuttt")
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId: localStorage.getItem('userid'),
            logoutype: "manual"

        }
        redirectURL.post("/manage/removeloggeduser", userloggparams)
            .then((resp) => {
                console.log("resp ", resp)
            })

        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
        localStorage.removeItem('userid');
        localStorage.removeItem('dept_code');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
        localStorage.removeItem('loadingPointName');
        localStorage.clear();

        sessionStorage.setItem('ref', null);
        //localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer: ''
        });
        //console.log("logout successfully",localStorage.getItem('token'));

        //localStorage.setItem('token',"");        
        //localStorage.setItem('userid',"");

        //window.location.reload();
    }

    autologout() {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId: localStorage.getItem('userid'),
            logoutype: "auto"

        }
        redirectURL.post("/manage/removeloggeduser", userloggparams)
            .then((resp) => {
                console.log("resp ", resp)
            })
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // Send a logout request to the API
        //console.log("Sending a logout request to the API...");
        this.setState({ logginStatus: false });
        this.destroy(); // Cleanup
        localStorage.removeItem('token');
        localStorage.removeItem('userid');
        localStorage.removeItem('dept_code');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('email');
        localStorage.removeItem('loading_port_code');
        localStorage.removeItem('loadingPointName');
        localStorage.clear();

        sessionStorage.setItem('ref', null);

        //localStorage.setItem('menuText',[]);
        delete axios.defaults.headers.common['Authorization'];
        this.setState({
            redirectToReferrer: ''
        });
        if (is_enmovil_transporter == 1) {
            window.location.href = "/tptlogin";
        }
        //console.log("logout successfully",localStorage.getItem('token'));

        //localStorage.setItem('token',"");        
        //localStorage.setItem('userid',"");

        //window.location.reload();
    }

    checkLoginTime(loggedTime) {
        console.log("initial loggedTime", loggedTime);
        var now = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        //var now = moment.parseZone(new Date(nowdate)).utcOffset("+05:30")._d;
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("+05:30")._d;
        // var loggedTime = moment.parseZone(new Date(loggedTime)).format('YYYY-MM-DD HH:MM:ss')
        //var loggedTime = moment.parseZone(new Date(loggedTime)).utcOffset("-05:30")._d
        var lTime = moment.parseZone(loggedTime).format("YYYY-MM-DD HH:mm:ss");
        console.log("now", now);
        console.log("loggedTime", loggedTime);
        console.log("loggedTime", lTime);

        if (loggedTime != undefined) {
            //var sessionDuration = moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(loggedTime,"DD/MM/YYYY HH:mm:ss"))).format("mm");
            //moment.parseZone(new Date(data1[0].createdon))._d
            var sessionDurationInHrs = moment.duration(moment(now).diff(lTime));
            var sessionDuration = sessionDurationInHrs.asMinutes();
            console.log("Sess Dur", parseInt(sessionDuration));
            //console.log("Token", localStorage.getItem('token'));
            if (parseInt(sessionDuration) >= parseInt(120)) {
                console.log("Sess Dur1", sessionDuration);
                this.autologout();
            }
            else {
                this.userLastActivity();
            }
        }
        else {
            this.userLastActivity();
        }
    }

    userLastActivity() {
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId: localStorage.getItem('userid')
        }
        var comp_code = localStorage.getItem('comp_code');
        if (comp_code != undefined && comp_code != '') {
            userloggparams.comp_code = comp_code;
        }
        redirectURL.post("/manage/updateUserLastActivity", userloggparams)
            .then((resp) => {
                console.log("userActivity ", resp)
            })
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }


    componentDidMount() {
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

        if (localStorage.getItem('token') == '' && localStorage.getItem('token') == null) {

        }
        else {
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId: localStorage.getItem('userid')
            }
            //console.log("userloggparams ", userloggparams)


            redirectURL.post("/manage/loggeduser", userloggparams)
                .then((resp) => {
                    //console.log("resp ", resp)
                    var recc = resp.data;
                    if (recc.length > 0) {
                        if (recc[0].is_logged == 0) {
                            this.autologout();
                        }
                        else {

                            this.checkLoginTime(recc[0].last_activity);
                        }
                    }
                    else {
                        // this.logout();
                    }
                })


        }
        //console.log("usermenus ",localStorage.getItem("usermenus"))
        var allmenus = []
        var dynmicemus = []
        if (localStorage.getItem("usermenus") != '' && typeof localStorage.getItem("usermenus") != "undefined") {
            var menulist = JSON.parse(localStorage.getItem("usermenus"));
            // console.log("menulist ", menulist)
            try {
                menulist.map((menu) => {
                    //console.log("Menu ", menu)
                    if (menu.sublinks != '' && menu.sublinks != undefined) {

                        menu.sublinks.map((item) => {

                            allmenus.push(item.value)

                        })

                    }
                    if (menu.path == '' || menu.path == null) {
                        var level1 = menu.children;
                        //console.log("level1 ", level1)

                        if (level1.length > 0) {
                            level1.map((mn) => {

                                if (mn.sublinks != '' && mn.sublinks != undefined) {
                                    mn.sublinks.map((item) => {

                                        allmenus.push(item.value)

                                    })


                                }


                                if (mn.path != '' && mn.path != null) {
                                    allmenus.push(mn.path)
                                }
                                else {
                                    var level2 = mn.children

                                    if (level2.length > 0) {
                                        level2.map((mnu) => {
                                            if (mnu.path != '' && mnu.path != null) {
                                                allmenus.push(mnu.path)
                                            }

                                            if (mnu.sublinks != '' && mnu.sublinks != undefined) {
                                                mnu.sublinks.map((item) => {

                                                    allmenus.push(item.value)

                                                })
                                            }

                                        })
                                    }

                                }

                            })
                        }

                    }
                    else {
                        allmenus.push(menu.path)

                        if (menu.sublinks != '' && menu.sublinks != undefined) {
                            menu.sublinks.map((item) => {

                                allmenus.push(item.value)

                            })
                        }

                    }
                })
            }
            catch (e) {

            }
        }
        //console.log("window.location ", window.location)
        var pageurl = window.location.pathname
        allmenus.push("/")
        allmenus.push("/profile/changepassword")
        allmenus.push("/error-404")
        allmenus.push("/error-405")
        allmenus.push("/logout")
        allmenus.push("/returnTripsDashboard")
        allmenus.push("/get_cyclone_report");
        allmenus.push("/analytics");
        var rurl = [];
        //console.log("allmenus ",allmenus)
        //console.log("path is ",pageurl)
        var otherdepts = ["AD", "SA"];
        // console.log("appjsx deptcode ",localStorage.getItem("dept_code"));
        if (allmenus.length > 0) {
            try {
                //console.log(localStorage.getItem("dept_code").indexOf(""))
                if (localStorage.getItem("dept_code").indexOf("AD") >= 0 || localStorage.getItem("dept_code").indexOf("SA") >= 0
                ) {

                }
                else {
                    if (localStorage.getItem("is_load_management") != 1 && localStorage.getItem("roles").includes("DCT") != true) {
                        if (allmenus.indexOf(pageurl) >= 0) {
                            console.log("Valid url")
                        }
                        else {
                            var pgrl = pageurl.split("/")
                            // console.log("Splitpgrl ",pgrl)
                            if (pgrl.length > 0) {
                                var loadurl = ''
                                if (pgrl.length > 1) {
                                    loadurl = "/" + pgrl[1];
                                }
                                if (allmenus.indexOf(loadurl) >= 0) {
                                    //   console.log("yes")
                                }
                                else {
                                    //  console.log("no");
                                    window.location.href = "/"
                                }
                            }

                            //window.location.href="/"
                        }
                    }
                    else {
                        // allmenus.push("/lmwelcome");
                        // allmenus.push("/viewLoadSummaryDetails");
                        // if(allmenus.indexOf(pageurl) >= 0)
                        // {
                        //     console.log("Valid url")
                        // }
                        // else{
                        //     window.location.href="/lmwelcome"
                        // }
                    }
                }
            }
            catch (e) {

            }
            //console.log(allmenus.indexOf(pageurl))
            var spliturl = pageurl.split("/")

            allmenus.map((a) => {
                //console.log("a ", a)
                //console.log("pageurl length ", pageurl.length)
                if (a.length > 1) {
                    //console.log("pageurl ", pageurl)
                    if (pageurl.indexOf(a) >= 0) {
                        //console.log("Yes")
                        rurl.push(0)
                    }
                    else {
                        //console.log("No")
                        //rurl.push(1)
                        //window.location.href="/error-404"

                    }
                }


            })

            //}
            //console.log("rurl ", rurl)
            if (rurl.length > 0) {
                if (rurl.indexOf(0) >= 0) {

                }
                else {
                    // window.location.href="/error-404"
                }
            }
            else {
                if (pageurl == "/") {

                }
                else {
                    // window.location.href="/error-404"
                }

            }

        }
    }


    componentWillReceiveProps(newProps) {
        if (localStorage.getItem('token') == '' && localStorage.getItem('token') == null) {

        }
        else {
            var userloggparams = {
                token: localStorage.getItem('token'),
                userId: localStorage.getItem('userid')
            }

            redirectURL.post("/manage/loggeduser", userloggparams)
                .then((resp) => {
                    //console.log("resp ", resp)
                    var recc = resp.data;
                    if (recc.length > 0) {
                        if (recc[0].is_logged == 0) {
                            this.autologout();
                        }
                        else {
                            console.log("Last Activity", recc[0].last_activity);
                            this.checkLoginTime(recc[0].last_activity);
                        }
                    }
                    else {
                        // this.logout();
                    }
                })
        }
    }

    destroy() {
        this.clearTimeout();
    
        for (var i in this.events) {
          window.removeEventListener(this.events[i], this.resetTimeout);
        }
      }

    showside(){
        //console.log("asdas");
        showSideBar();
    }
    openCloseSidebar = () => {
        // console.log("this.state.sidebar ", this.state.sidebar)
        if(this.state.sidebar){
            this.setState({ 
                defwidth:"345px",
                sidebar:true, 
                ssinner:'show-m', 
                //sideimg:require('../assets/icons/SIDE-NAV.png'),
                imgbg:'',
                l20n:'l20n',
                showOutIcon:'show-m',
                sclose:'show-n'
            })
            // document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
            var sidebaricon = document.querySelector(".sidebaricon");
            if(sidebaricon.classList.contains('show-n'))
            {
                document.querySelector(".sidebar-menu").classList.remove("w345");
                document.querySelector(".page-sidebar").classList.remove("w345");
                document.querySelector(".sidebar-menu").classList.add("w55");
                document.querySelector(".page-sidebar").classList.add("w55");
                document.querySelector(".sidebaricon").classList.remove('show-n');
                document.querySelector(".sidebaricon").classList.add('show-m');    
            }
            else{
                document.querySelector(".sidebar-menu").classList.remove("w55");
                document.querySelector(".page-sidebar").classList.remove("w55");
                document.querySelector(".sidebar-menu").classList.add("w345");
                document.querySelector(".page-sidebar").classList.add("w345");
                document.querySelector(".sidebaricon").classList.remove('show-m');
                document.querySelector(".sidebaricon").classList.add('show-n');
                document.querySelector(".sidebar-blueicon").classList.remove('show-n');
                document.querySelector(".sidebar-blueicon").classList.add('show-m');
                document.querySelector(".collapstext").classList.remove('show-n');
                document.querySelector(".collapstext").classList.add('show-m');
                
            }
        }
        // else{
        //     this.setState({ 
        //         sidebar:true, 
        //         ssinner:'show-m', 
        //         //sideimg:require('../assets/icons/SIDE-NAV.png'),
        //         imgbg:'',
        //         l20n:'l20n',
        //         showOutIcon:'show-n',
        //         sclose:'show-n'
        //     })
        //     document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        //     this.state.sidebar=false;
        // }
    }

    openCloseSidebarMobile = () => {
        // console.log("this.state.sidebar ", this.state.sidebar)
        if(this.state.sidebar){
            this.setState({ 
                defwidth:"345px",
                sidebar:true, 
                ssinner:'show-m', 
                //sideimg:require('../assets/icons/SIDE-NAV.png'),
                imgbg:'',
                l20n:'l20n',
                showOutIcon:'show-m',
                sclose:'show-n'
            })
            // document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
            var sidebaricon = document.querySelector(".sidebariconmb");
            // console.log("asdsa ", sidebaricon.classList. contains('show-n'))
            if(sidebaricon.classList.contains('show-n') == true)
            {
                document.querySelector(".sidebar-menu").classList.remove("w345");
                document.querySelector(".page-sidebar").classList.remove("w345");
                document.querySelector(".sidebar-menu").classList.add("w0");
                document.querySelector(".page-sidebar").classList.add("w0");
                // document.querySelector(".mobclx").classList.remove('show-m');
                // document.querySelector(".mobclx").classList.add('show-n'); 
                document.querySelector(".mobic").classList.remove('show-n');
                document.querySelector(".mobic").classList.add('show-m');  
                document.querySelector(".mboverlay-part").classList.remove("show-m");
                document.querySelector(".mboverlay-part").classList.add("show-n");  
            }
            else{
                document.querySelector(".sidebar-menu").classList.remove("w0");
                document.querySelector(".page-sidebar").classList.remove("w0");
                document.querySelector(".sidebar-menu").classList.add("w345");
                document.querySelector(".page-sidebar").classList.add("w345");
                // document.querySelector(".sidebariconmb").classList.remove('show-m');
                // document.querySelector(".sidebariconmb").classList.add('show-n');
                // document.querySelector(".mobclx").classList.remove('show-n');
                // document.querySelector(".mobclx").classList.add('show-m'); 
                document.querySelector(".mobic").classList.remove('show-m');
                document.querySelector(".mobic").classList.add('show-n');
                document.querySelector(".mboverlay-part").classList.remove("show-n");
                document.querySelector(".mboverlay-part").classList.add("show-m");
                
            }
        }
        // else{
        //     this.setState({ 
        //         sidebar:true, 
        //         ssinner:'show-m', 
        //         //sideimg:require('../assets/icons/SIDE-NAV.png'),
        //         imgbg:'',
        //         l20n:'l20n',
        //         showOutIcon:'show-n',
        //         sclose:'show-n'
        //     })
        //     document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        //     this.state.sidebar=false;
        // }
    }

    toggle() {
      //  console.log("test", this.state.navMenus);
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    } 
    onShowFullscreen()
    {
        fullscreen()
    }
    // clearStorage() {

    //     let session = sessionStorage.getItem('ref');
    //    // console.log("session ",session)
    //     if (session == null) {
    //         this.autologout()
    //         localStorage.removeItem('token');
            
    //     }
    //     //sessionStorage.setItem('ref', 0);
    // }

	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false, open1:false });
	 };
    appendTransporter(){
        var transporter = this.state.transporter.value;
        var params = {
            transporter_code:transporter
        }
       // console.log("transporte ", transporter)
        redirectURL.post("/dashboard/transportinfo",params)
        .then((response) => {
            //console.log("respson tpt ", response.data)
            var transport= response.data.records;
            var roles = [localStorage.getItem("roles")];
            if(transport.length > 0)
            {
                var rols = transport[0].roles;
                if(rols.length > 0)
                {
                    rols.map((item) => {
                        roles.push(item)
                    })
                }
            }
           // console.log("Roels ", transport[0].transporter_code)
           localStorage.setItem("roles",roles)
           localStorage.setItem("transportercode",[transport[0].transporter_code])

           localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
            //console.log("response.data.usermenucodes ", response.data.usermenucodes);
            localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
            localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
            
            
            this.setState({
                showmodal:0,
                showpop:"show-n"
            })
            window.location.reload()
        })
    }
    
    selecttransporters(){
		let items = [];    
        var transporters = this.state.transporters;
        if(transporters.length > 0)
        {
            transporters.map((item) => {
                items.push({
                    value:item.transporter_code,
                    label:item.firstname
                })
            })
        }
		return items;
	}

	onChangetTransporterItem(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

    onCloseSidebar(){

    }


    decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }


    render() {
        
        var hideyes=false
       try{
            if(localStorage.getItem("roles").indexOf("led") >= 0)
            {
                hideyes=true
            }
            else{
                hideyes = false
            }
        }
        catch(e){
            hideyes = false
        }
        
        if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
        {
            return <Redirect to="/" push={true} />
        }
       // return <Redirect to="/pages/maintenance" push={true} />
        //console.log("Check",localStorage.getItem('token'));
        var pageStyle = this.state.pageStyle;
        const modalStyles  = {
            width:'500px !important',
            }

    const { open } = this.state;
    var currentpage = window.location.pathname
        return (


            <div className="page-wrapper">

                {
                (hideyes == true)?
                    <div className="page-body-wrapper" style={{overflow:"hidden"}} id="plivebody">
                        <Header />
                        {/* <div  id="fscreen" className="fs-body">
                            <div className="fs-content">
                                <button onClick={this.onShowFullscreen.bind(this)}>FullScreen Mode</button>
                            </div>
                         </div> */}
                         {this.props.children}
                    </div>
                    :
                <div className="page-body-wrapper sidebar-close" id="pageBoby">
                <Header />
                {(currentpage != "/profile" && currentpage != "/support")?
                    <Sidebar context= { this } />
                   
                :""}
                    <div className="page-body" id="pbody" style={this.state.pageStyle}>
                    {(currentpage != "/profile" && currentpage != "/support")?
                        <a href={"javascript:void(0)"} className={"mobnone sidebaricon "+(this.state.showOutIcon)}>
                            
                            <img src={require('../assets/icons/menu.png')} id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar} className={"sidemenu sidebrclose "+(this.state.imgbg)+" "+(this.state.sclose)}  alt=""/>
                       
                        </a>
                        :""}
                        {(currentpage != "/profile" && currentpage != "/support")?
                        <a href={"javascript:void(0)"} className={"mobshw mobic sidebariconmb "+(this.state.showOutIcon)}>
                            
                            <img src={require('../assets/icons/menu.png')} style={{width:"48px"}} id="sidebar-toggle" defaultChecked={this.state.sidebar} data-id="1" onClick={this.openCloseSidebarMobile.bind(this)} className={"sidemenu sm-menu sidebrclose "}  alt=""/>
                       
                        </a>
                       :""}

                        <ErrorHandler>
                        {this.props.children}
                        
                        </ErrorHandler>
                        
                        <div className="mboverlay-part show-n"></div>
                    </div>
                    <div  className="mobsh">
                        <Footer />
                    </div>
                    <div className="mobshw">
                        <FooterMobile className="mobshw" />
                    </div>
                </div>
                }

                {
                    (localStorage.getItem("roles") == 'tpt_admin' && this.state.showmodal == 1 )?
                    
                        <div className={"modalpop " + (this.state.showpop)} >
                            <div className={"col-sm-12 col-lg-12 col-md-12"} style={{textAlign:"center",padding:'20px', background:'#fff'}}>
                                <label className="col-xl-12 col-lg-12" style={{textAlign:"left"}}>Select Transporter:</label>
                                <div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
                                <Select 
                                    placeholder={"Select Transporter"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangetTransporterItem.bind(this)} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.transporter}
                                    options={this.selecttransporters()} 
                                /> 
                                </div> 
                                <button className="btn btn-warning mt-20p" onClick={this.appendTransporter.bind(this)}>Go</button>
                            
                            </div>
                        </div>
                    :""
                }
                {
                    (localStorage.getItem("roles") == 'tpt_admin' && this.state.showmodal == 1 )?                    
                        <div className={"modalpopoverlay " + (this.state.showpop)} ></div>
                    :""
                }


            </div>

        );
    }
}



function showSideBar() {
    alert("test");
    var c = document.getElementById('pageBoby');
    c.removeClass = ' sidebar-close';

}
window.onload = function () {

}
function fullscreen() {
    var ele = document.getElementById("plivebody")
    // $("#fscreen").hide();
    console.log("ele ", ele)
    if (ele.requestFullscreen) {
        console.log("S1")
        ele.requestFullscreen();
    } else if (ele.webkitRequestFullscreen) {
        console.log("S2")
        ele.webkitRequestFullscreen();
    } else if (ele.mozRequestFullScreen) {
        console.log("S3")
        ele.mozRequestFullScreen();
    } else if (ele.msRequestFullscreen) {
        console.log("S4")
        ele.msRequestFullscreen();
    } else {
        // $("#fscreen").show();
        console.log('Fullscreen API is not supported.');
    }
}


export default App;


