import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            open1: false,
            open2: false,
        };
    }

    onCloseModal = () => {
        this.setState({ 
            open: false, 
            open1: false, 
            open2: false
         });
    };
    showTermsModal(){
        this.setState({
            open:true
        })
    }
    showConsentModal(){
        this.setState({
            open1:true
        })
    }
    showPrivacyModal(){
        this.setState({
            open2:true
        })
    }
    render(){
        const { open } = this.state;
        const { open1 } = this.state;
        const { open2 } = this.state;

        return (
            <div className="container-fluid">
                <footer className="main-footer" style={{position:"relative"}}>
                    <div className="container">
                    {(localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined)?
                               
                        <p className="mobfbt11" style={{ cursor: "auto" }}> AGI Glasspac
                        </p>
                        :
                        <p className="mobfbt11" style={{ cursor: "auto" }}> © COMPANY NAME
                        </p>

            }
                        <div className="pull-right hidden-xs">
                            {/* {(localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined)?
                            <ul>
                                <li><a href="#" onClick={this.showTermsModal.bind(this)}>TERMS OF USE</a></li>
                                <li><a href="#" onClick={this.showConsentModal.bind(this)}>DATA PROVIDER CONSENT POLICY </a></li>
                                <li><a href="#" onClick={this.showPrivacyModal.bind(this)}>PRIVACY POLICY</a></li>
                            </ul>
                            :
                            <ul>
                                <li><a href="#" >TERMS OF USE</a></li>
                                <li><a href="#" >DATA PROVIDER CONSENT POLICY </a></li>
                                <li><a href="#" >PRIVACY POLICY</a></li>
                            </ul>
                            } */}
                            <ul>
                                <li><a href="#" >Powered By Enmovil Solutions Pvt Ltd</a></li>
                            </ul>
                        </div>
                    </div>
                </footer>
                
                <Modal open={open} onClose={this.onCloseModal} styles={{ width: "200px" }} >
                    <div className="row">

                        <div className="col-md-12 col-sm-12">
                            <h3 className="f18 fbold textAlignCenter">TERMS OF USE</h3>
                            <p>Please read these terms and conditions carefully. By accessing this site and any pages thereof, you agree to be bound by the terms and conditions below. Usage of any Maruti Suzuki India Limited’s (hereinafter referred to as "MSIL") website indicates unconditional acceptance of these terms.</p>
                            <p className="fbold">
                            1. Eligibility</p>
                            <p>
                            You warrant that you are competent to contract as per Indian Contract Act, 1872 in accordance with all terms and conditions purchase of <b>AGI Glasspac Solution</b> software sold by MSIL.
                            </p>
                            <p className="fbold">
                            2. CopyrightAGI Glasspac 2022.</p>
                            <p> Copyright in the pages and in the screens displaying the pages, in the information and material therein and in their arrangement, is owned by MSIL unless otherwise indicated.</p>

                            <p className="fbold">3. Trademarks</p>
                            <p>
                            Maruti Suzuki used severally or in conjunction with SUZUKI or other mark(s), Wing device, S device, model names and other marks developed by MSIL in relation to services are marks and service marks owned by MSIL and Suzuki Motor Corporation, Japan.</p>

                            <p className="fbold">4. Use of information and materials</p>
                            <p>The information and materials contained in these pages, and the terms, conditions, and descriptions that appear, are subject to change. Unauthorized use of MSIL websites and systems including but not limited to unauthorized entry into MSIL systems, misuse of passwords, or misuse of any information posted on a site is strictly prohibited. Not all products and services are available in all geographic areas. Your eligibility for particular product and services is subject to final determination by MSIL and/or its affiliates.</p>

                            <p className="fbold">
                            5. Links</p>
                            <p> 
                            This website may contain links to other websites controlled or offered by third parties (non-affiliates of MSIL). MSIL hereby disclaims liability for any information, materials, and products or services posted or offered at any of the third-party sites linked to this website. By creating a link to a third-party website, MSIL does not endorse or recommend any products or services offered or information contained at that website, nor is MSIL liable for any failure of products or services offered or advertised at those sites. Such third party may have a privacy policy different from that of MSIL and the third-party website may provide less security than the MSIL site.</p>

                            <p className="fbold">
                            6. No Warranty</p>
                            <p> 
                            The information and materials contained in this site, including text, graphics, links or other items are provided "as is", "as available". MSIL does not warrant the accuracy, adequacy or completeness of this information and materials and expressly disclaims liability for errors or omissions in this information and materials. No warranty of any kind, implied, expressed, or statutory including but not limited to the warranties of non-infringement of third-party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information and materials.</p>

                            <p className="fbold">
                            7. Limitation of Liability</p>
                            <p> 
                            In no event will MSIL be liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with this site or any linked site or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if MSIL, or representatives thereof, are advised of the possibility of such damages, losses or expenses.</p>

                            <p className="fbold">
                            8. Submissions</p>
                            <p> 
                            All information submitted to MSIL via this site shall be deemed and remain the property of MSIL and MSIL shall be free to use, for any purpose, any idea, concepts, know-how or techniques contained in information a visitor to this site provides MSIL through this site. MSIL shall not be subject to any obligations of confidentiality regarding submitted information except as agreed by the MSIL entity having the direct customer relationship or as otherwise specifically agreed or required by law. Nothing contained herein shall be construed as limiting or reducing MSIL's responsibilities and obligations to customers in accordance with the MSIL privacy promise for consumers.</p>

                            <p className="fbold">
                            9. Availability</p>
                            <p> 
                            This site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to local law or regulation.</p>

                            <p className="fbold">
                            10. Termination/Access Restriction</p>
                            <p> 
                            MSIL reserves the right, in its sole discretion, to terminate your access to any or all MSIL websites and the related services or any portion thereof at any time, without notice.</p>

                            <p className="fbold">
                            11. Modification of these terms of use</p>
                            <p> 
                            MSIL reserves the right to change the terms, conditions, and notices under which the MSIL websites are offered, including but not limited to the charges associated with the use of the MSIL websites. You are responsible for regularly reviewing these terms and conditions.</p>

                            <p className="fbold">
                            12. Additional Terms</p>
                            <p> 
                            Certain sections or pages on this site may contain separate terms and conditions, which are in addition to these terms and conditions. In the event of a conflict, the additional terms and conditions will govern for those sections or pages.</p>

                            <p className="fbold">
                            13. Governing Law</p>
                            <p> 
                            Use of this site shall be governed by all applicable laws, rules and regulations of India, its States and Union Territories.</p>

                            <p className="fbold">
                            14. Offers</p>
                            <p> 
                            *Terms and Conditions apply. Offers may have been brought to you by MSIL authorised dealers. Corporate offer value is maximum offer value, and same is applicable to select corporates only.</p> 

                            <p className="fbold">
                            General Terms</p>
                            <p> 
                            MSIL reserves the right to amend these Terms and Conditions and may withdraw or discontinue the offer without prior notice.</p>
                            <p> 
                            MSIL will not assume any liability of any inability or failure on their part in executing any order registered by any Customer on account of any causes, constituting a force majeure or otherwise, beyond their control.</p>
                            <p> 
                            In case of any dispute, inconvenience or loss due to an act or omission of the Customer, the Customer agrees to indemnify MSIL.</p>
                            <p> 
                            Any dispute relating to enforcement, interpretation or application of these terms and conditions shall be subject to Arbitration by single arbitrator appointed mutually by both the parties. The venue of Arbitration shall be at New Delhi. The Arbitration Proceedings shall be in accordance with the Arbitration and Conciliation Act, 1996 and its allied rules as amended from time to time. The Parties submit to the exclusive jurisdiction of the courts at New Delhi.</p>
                            <p> 
                            The Agreement shall be governed by the laws as applicable in India.</p>
                            <p> 
                            MSIL reserves the right to alter any terms and conditions or the process itself clause at its sole discretion as and when considered necessary. Reasonable efforts will be made to keep Customer informed of such changes.</p>
                            <p> 
                            The Customer accepts and agrees to the above terms and conditions and also gives his unconditional consent for being contacted for Maruti Suzuki Arena Products/Services over his telephone/mobile phone/email/sms. Non-acceptance of any of these terms and conditions will result in disqualification.</p>
                            <p> 
                            MSIL, its Directors, and Employees assume no liability whatsoever for any direct or indirect loss or damage arising from a Customer’s purchase and use of the said software as per the Terms and Conditions.</p>
                            <p> 
                            "Customer" means a prospective customer interested in purchasing the AGI Glasspac Solution from MSIL and includes any Individual/ Firm/ Proprietorship/ Company or other legal entity.</p>
                            <p> 
                            In the event that the Customer is buying the software on behalf of another person, the necessary details of such other person is also required to be mentioned and the requisite details of such other person are required to be produced as per requirement by MSIL.</p>
                            <p> 
                            The details of the Customer as provided by the Customer shall be used by MSIL and it’s Authorized Dealers (Maruti Suzuki Arena or Nexa) for all correspondences with the Customer on FMS subject-matter or on any other subject-matter.</p>
                            <p> 
                            All parties shall ensure compliance to the applicable provisions of the Information Technology Act, 2000 and its allied rules as amended from time to time.
                            The Terms of Use and the Privacy Policy provided on "www.marutisuzuki.com" and "www.Nexaexperience.com" shall be deemed to be a part and parcel of these terms and conditions.</p>
                            <p> 
                            You undertake to have read all terms and conditions before going ahead with your purchase.</p>
                            <p> 
                            The payment gateway is powered by an intermediary providing payment gateway and merchant services.</p>
                            <p> 
                            Payments made by the Customer for purchase of AGI Glasspac Solution through payment gateway are subject to the terms and conditions as provided by the payment gateway intermediary/ merchant service provider. MSIL would not be responsible for any non-payment or wrong payment made in respect of the purchase through the payment gateway.</p>
                            <p> 
                            The purchase price will be displayed on "www.marutisuzuki.com" and www.Nexaexperience.com and you cannot pay more or less than the mentioned amount.
                            The purchase can be done using Debit Card/ Credit Card/ Internet Banking.</p>
                            <p> 
                            The Customer needs to provide accurate and complete information while filling the form online.</p>
                            <p> 
                            After online submission of the form and the valid online payment transaction, the system will generate a Payment Confirmation Reference Number and receipt of payment towards the purchase of AGI Glasspac Solution. The Customer shall use the reference number and receipt to communicate with MSIL.</p>
                            <p> 
                            Cancellation, Modification, Refund</p>
                            <p> 
                            There will be no cancellation of the purchase.</p>
                            
                        </div>
                    </div>
                </Modal>
                
                <Modal open={open1} onClose={this.onCloseModal} styles={{ width: "200px" }} >

                    <div className="row">

                        <div className="col-md-12 col-sm-12">
                            <h3 className="f18 fbold textAlignCenter">DATA PROVIDER CONSENT POLICY</h3>
                            <p className="fbold">1.Introduction</p>
                            <p>(i)This policy is drawn by Maruti Suzuki India Limited (hereinafter referred to as MSIL) and creates the framework to set standards for gaining consent from the data providers. The policy is based on the general legal and ethical principle that valid consent must be obtained from the data providers. This principle reflects the rights of the data providers.
                            </p>
                            <p>
                            (ii)The data provider’s consent is the legal/statutory requirements under Rule 5(1) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 made under the Information Technology Act, 2000. As per the said rule, it is legitimate for MSIL to obtain consent in writing through letter or fax or email from the data provider of the sensitive personal data or information regarding purpose of usage, before collection of such information. Data providers can ask MSIL to release data about themselves at any time. MSIL has informed the data providers that they will be sharing personal data with the third parties for the purpose of carrying out their essential business functions.
                            </p>
                            <p className="fbold">2.Definitions
                            </p>
                            <p>
                            (i)Data means a representation of information, knowledge, facts, concepts or instructions which are being prepared or have been prepared in a formalized manner, and is intended to be processed, is being processed or has been processed in a computer system or computer network, and may be in any form (including computer printouts magnetic or optical storage media, punched cards, punched tapes) or stored internally in the memory of the computer.
                            </p>
                            <p>
                            (ii)Information includes data, message, text, images, sound, voice, codes, computer programmes, software and databases or micro film or computer generated micro fiche.
                            </p>
                            <p>
                            (iii)Cyber incidents means any real or suspected adverse event in relation to cyber security that violates an explicitly or implicitly applicable security policy resulting in unauthorized access, denial of service or disruption, unauthorized use of a computer resource for processing or storage of information or changes to data, information without authorization
                            </p>
                            <p>
                            (iv)Data Subject/Provider: Data Subject/Providers means and includes the employees, managements, contractors, agents, subsidiaries, affiliates, staffs, clients, business partners and users of the computer resources of MSIL.
                            </p>
                            <p>
                            (v)Disclosure means the release, transfer, provision of access to, or divulging of information in any manner (verbally or in writing) by MSIL to persons who are not MSIL employees, affiliates or to any other person or entity outside of MSIL.
                            </p>
                            <p>
                            (vi)Personal information means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person.
                            </p>
                            <p>
                            (vii)Sensitive personal information (SPI): Sensitive personal data or information of a person means such personal information which consists of information relating to;?
                            </p>
                            <p>
                            (i) Password;
                            </p>
                            <p>
                            (ii) Financial information such as Bank account or credit card or debit card or other payment instrument details;
                            </p>
                            <p>
                            (iii) Physical, physiological and mental health condition;
                            </p>
                            <p>
                            (iv) Sexual orientation
                            </p>
                            <p>
                            (v) Medical records and history;
                            </p>
                            <p>
                            (vi)Biometric information;
                            </p>
                            <p>
                            (vii) Any detail relating to the above clauses as provided to MSIL for providing service; and
                            </p>
                            <p>
                            (viii) Any of the information received under above clauses by MSIL for processing, stored or processed under lawful contract or otherwise
                            </p>
                            <p className="fbold">3.Purpose
                            </p>
                            <p>
                            The purpose of this Policy is to ensure that sensitive data and information of data providers are adequately protected and maintained and that providers of such data have given their consent to the purpose of usage of such data, prior to the collection of such data and information.
                            </p>
                            <p className="fbold">4.Applicability
                            </p>
                            <p>
                            This policy applies to all staff members, contractors, agents, shareholders, affiliates, clients, customers, business partners, dealers, sub-dealers, prospective employees and present employees, who provide their sensitive personal data and information to MSIL and all its direct predecessor organizations or bodies.
                            </p>
                            <p className="fbold">5.Data collection method(s)
                            </p>
                            <p>
                            MSIL collects the data and information, either personal or sensitive, from the present and prospective employees, contractors, agents, affiliates, clients, business partners dealers, sub- dealers and other entities through various methods.
                            </p>
                            <p className="fbold">6.Information provided by legal entities including customers
                            </p>
                            <p>
                            During the course of business relations or interaction with MSIL, legal entities including customers often provide information (a) about themselves, their legal status, contact details, phone numbers, emails. Bank account details, PAN No details, bank/wire transfer details (b) personal information and/or sensitive personal data or information or (c) to submit questions about MSIL service. If a person submits a question about MSIL service, he/she will be asked to provide his/her name, telephone number, and e-mail address.
                            </p>
                            <p className="fbold">7.Suppliers, Employee and Contract Workers and Others
                            </p>
                            <p>
                            In order to ensure that MSIL has adequate resources to conduct its business, it may often need to acquire supplies and additional casual labour. To properly pay for these supplies and services, MSIL is required to collect financial information such as banking details and home contact information from service providers. Additionally, the collection of contact information will permit MSIL to maintain a list of suppliers to fill vacancies or to perform similar tasks in the future as such opportunities arise. MSIL also obtains the personal information from its employees and also the prospective employees who are willing to work with it.
                            </p>
                            <p className="fbold">8.General Public.
                            </p>
                            <p>
                            In order to ensure a high quality of staffing as vacancies arise, MSIL will solicit applications from qualified individuals. Although not requested by MSIL, candidates will often supply personal information such as birth dates, citizenship, educational background and community involvement and other sensitive personal information as defined above. In order to ensure the safety and security of its staff and of visitors to its facility, MSIL utilizes Closed-Circuit Cameras in its premises. The data obtained from these cameras is recorded and is used for security, monitoring and other related purposes.
                            </p>
                            <p className="fbold">9.Information provided by customers about others.
                            </p>
                            <p>
                            In providing personal information about other individuals (such as someone in whose name one is registering the car/other products of MSIL), one represents that one has notified them of the purposes for which the information will be used, the recipients of the information, and how they can access and correct the information, and that one has obtained their consent. MSIL processes personal data about its employees etc., its clients and suppliers, customers, Contract Workers and other individuals, including former employees, for a number of business purposes, including, but not limited to:
                            </p>
                            <ul>
                                <li>Scheduling</li>

                                <li>Recruitment</li>

                                <li>Personnel management</li>

                                <li>Payroll and accounting</li>

                                <li>Business and market development</li>

                                <li>Building and managing external relationships</li>

                                <li>Research and development</li>

                                <li>Technology infrastructure</li>

                                <li>Other purposes required by law or regulation</li>
                            </ul>
                            
                            <p className="fbold">10.Sources used
                            </p>
                            <p>
                            MSIL primarily collects personal information directly by requesting that one completes forms or questionnaires, and also in connection with provision of services to one at one’s request. MSIL may also collect information regarding one’s internet protocol address, browser type, domain name and access time. MSIL collects the data and information, either personal or sensitive, through various sources such as emails, logs, website visiting and hard copy and other sources.
                            </p>
                            <p className="fbold">11.Use of Information
                            </p>
                            <p>
                            None of the data or information, either sensitive or personal, collected shall be used for any other purpose than the purposes outlined in the data collection policy.
                            </p>
                            <p className="fbold">12.Data retention
                            </p>
                            <p>
                            As a general rule, data or information, either sensitive or personal, shall be retained for a minimum period outlined in the data retention policy
                            </p>
                            <p className="fbold">13.Accessing, Correcting and Updating Information
                            </p>
                            <p>
                            One is required to correct any erroneous or out-of-date information concerning oneself. One can access the information one provides, correct it, and update it.
                            </p>
                            <p className="fbold">14.Choice
                            </p>
                            <p>
                            MSIL will offer individuals the opportunity to choose whether their personal information is (1) disclosed to a third-party, or (2) used for a purpose other than the purpose for which it was originally collected or subsequently authorized by the individual. For Sensitive Personal Information, MSIL will give individuals the opportunity to affirmatively consent to the disclosure of the information to a third-party or the use of the information for a purpose other than the purpose for which it was originally collected or subsequently authorized by the individual.
                            </p>
                            <p className="fbold">15.Withdrawal of Consent
                            </p>
                            <p>
                            MSIL discloses one’s information to third parties with one’s prior consent; one have the right to give one’s consent for the use and storage of personal information, and to revoke consent at any time.
                            </p>
                            <p className="fbold">16.Confidentiality
                            </p>
                            <p>
                            One’s sensitive personal data or information will be treated as confidential. However, any data obtained as a result of oneself obtaining the services of or for jobs with MSIL may be inspected by the relevant stipulated legal authorities under the Information Technology Act, 2000 and rules and regulations made thereunder, and in case of any lawful order or legal requirements by any relevant governmental agency, provided that such legal authorities are legally obligated to protect any identifiable information from public disclosure, except where disclosure is otherwise required by law or a court of competent jurisdiction. These records will be kept private in so far as permitted by law.
                            </p>
                            <p className="fbold">17.Security
                            </p>
                            <p>
                            The security of one’s personal information is important to MSIL. MSIL provides a framework to establish processes and procedures to protect sensitive personal data and information against security threats, whether accidental or deliberate, external or internal; to ensure confidentiality, integrity and availability of data; and minimize the impact of security incidents. The security team works closely with the senior management team of MSIL to develop the high level policies and for their continuous conformant and enforcement.
                            </p>
                            <p>
                                The data providers have read the present consent policy and are free to ask any questions and MSIL questions have been answered to its satisfaction.
                            </p>
                            <p>
                                The data providers are over 18 years of age and, exercising free power of choice, hereby give consent in relation to their sensitive personal data and information which have been provided to MSIL.
                            </p>
                            <p>
                                The data providers acknowledge that they have read and understood the consent policy and the information provided to them.

                            </p>
                            <p> The data providers acknowledge that they have also read and understood the data collection policy, which clearly contains how and from where and from whom MSIL collects the data.
                            </p>
                            <p>
                                The consent document has been explained to the data providers.
                            </p>
                            <p>
                                The consent document has been explained to the data providers.
                            </p>
                            <p>
                                The data providers acknowledge that they have been advised about the risks associated with data.
                            </p>
                            <p>
                                The data providers acknowledge that they are aware of the fact that they can opt out of data at any time without having to give any reason.
                            </p>
                            <p>
                                The data providers acknowledge that they hereby give permission to MSIL to release the information obtained from them as a result of their visiting of the website of MSIL or the service obtained by them or by filling the form for a job, or during the course of any business
                            </p>
                            <p className="fbold">18.Authorization
                            </p>
                            <p>
                            Data providers have read and understand this consent policy, and voluntarily agree to provide their sensitive personal information
                            </p>

                        </div>
                    </div>
                </Modal>
                <Modal open={open2} onClose={this.onCloseModal} styles={{ width: "200px" }} >

                    <div className="row">

                        <div className="col-md-12 col-sm-12">
                            <h3 className="f18 fbold textAlignCenter">PRIVACY POLICY</h3>
                            <p>
                            Maruti Statement of Privacy At Maruti we take your privacy seriously. Please read the following to learn more about our terms and conditions.</p>
                            <p className="fbold">What the terms and conditions cover</p>
                            <p>This covers Maruti's treatment of personally identifiable information that Maruti collects when you are on Maruti Suzuki site and when you use our services. This policy also covers Maruti's treatment of any Personally identifiable information that Maruti shares with you. This policy does not apply to the practices of companies that Maruti does not own or control or Maruti does not own or employ or manage.</p>
                            <p className="fbold">Information Collection and Use</p>
                            <p>Maruti collects personally identifiable information when you register for a Maruti account. When you choose the services and promotions. Maruti may also receive personally identifiable information from our business partners. When you register with Maruti, we ask for your name, e-mail address, birth date, gender, occupation, industry and personal interest. Once you register with Maruti and sign in to our services, you are not anonymous to us. Maruti uses information for three general purpose: to fulfill your requests for certain products and services and to contact you about specials and new products.</p>
                            <p className="fbold">Information Sharing and Disclosure</p>
                            <p>Maruti will not sell or rent your Personally Identifiable Information to anyone. Maruti will send Personally Identifiable Information about you when: We have consent to share the information We need to share your information to provide the product or service you have requested We respond to subpoenas, court orders or legal process. When we find your action on the web site violates the Maruti terms and condition or any of your usage guidelines for specific products or services.</p>
                            <p className="fbold">Security</p>
                            <p>Your Maruti account information is password-protected for your privacy and security We have taken adequate measures to secure access to your personal data?</p>
                            <p className="fbold">Changes to this Policy</p>
                            <p>Maruti may edit this policy from time to time. If we make any substantial changes ,we will notify you by posting a prominent announcement on our pages.</p>
                            <p className="fbold">Email Disclaimer</p>
                            <p>This electronic transmission contains information from Maruti Suzuki India Limited (MSIL) which is confidential and proprietary, and is intended for use only by the person named herein. In case, you are not the intended recipient, pls. don’t use this information in any manner, whatsoever, and immediately return it to Sender. Any views or opinions presented in this email are solely those of the author and may not necessarily reflect the opinion of MSIL</p>


                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Footer