import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';


import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDDHHMMSS
} from '../common/utils';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}
const initialState = {
    /* etc */
};
export default class AssignSalesOrders extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
			pageTitle: "Transporters",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add Transporter",
            rowId : "",
            transportersList : [],
            plantsList : [],
            customersList : [],
            salesOrders : [],
            uniqueSalesOrders : [],
            detailCellRendererParams:{},
            plant:{value:"",label:"Select Plant"},
            customer:{value:"",label:"Select Customer"},
            noData:"show-n",
            selectedTpts: [{}],
            transporter:[],
            salesOrderInfo:"",
            showForm : "show-n",
            showGrid : "show-m",
            packetrows0: [{}],
            truckTypesList:[],
        };
        this.assignSalesOrdersToTpt = this.assignSalesOrdersToTpt.bind(this);
        window.soDataItem = this.soDataItem.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        loadDateTimeScript();
        var yesterDaydate = moment.parseZone().subtract(1, 'days').format("YYYY-MM-DD");
        var currentdate = moment.parseZone().format("YYYY-MM-DD");
        $("#salesorderDate").val(yesterDaydate);
        $("#salesorderToDate").val(currentdate);
        this.loadPageData();
    }
    
    loadPageData = () =>{
        var params = {
            status : 1
        }
        redirectURL.post("/manage/getTransportersList",params).then(async(response)=>{
            var tptList = response.data;
            var transportersList = [];
            tptList.map(function(t){
                transportersList.push(t.transporter_name)
            });
            this.setState({
                transportersList : tptList
            })
        }).catch((e)=>{
            console.log(e);
        })
        redirectURL.post("/manage/getCustomerAndPlantsList").then(async(response)=>{
            var plantsList = response.data.plants;
            var customersList = response.data.customers;
            this.setState({
                plantsList : plantsList,
                customersList : customersList,
            })            
            this.getSalesOrders();
        }).catch((e)=>{
            console.log(e);
        })
        redirectURL.post("/manage/getTruckTypesList",params).then(async(response)=>{
            var truckTypesList = response.data;
            this.setState({
                truckTypesList : truckTypesList
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        //console.log(this.gridApi);    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
            selectedTpts: [{}],
            transporter:[],
            salesOrderInfo:"",
            select_transporter_0:"",
            packetrows0 : [{}],
            no_of_trucks_0:1,
        });
    }
    
    resetUpload = () => {
        this.setState({
            selectedTpts: [{}],
            salesOrderInfo:"",
            showForm : "show-n",
            showGrid : "show-m",
            packetrows0 : [{}],
            no_of_trucks_0 : 1
        });
        $("#expected_truck_type_0_0").val("");
    }
    onChangePlantItem = (plant) => {
		this.setState(
			{plant},
			() => console.log(`plant Option selected:`, this.state.plant)
        );
	}
    onChangeCustomerItem = (customer) => {
		this.setState(
			{customer},
			() => console.log(`customer Option selected:`, this.state.customer)
          );
	}
    selectplants = () => {
		let items = [];    
		try{
			if(this.state.plantsList.length > 0)
			{
				this.state.plantsList.map((item) =>{
					items.push({"value":item.plant_code,"label":item.plant_code+" - "+item.plant_name+" - "+item.plant_location})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selectcustomers = () => {
		let items = [];    
		try{
			if(this.state.customersList.length > 0)
			{
				this.state.customersList.map((item) =>{
					items.push({"value":item.customer_code,"label":item.customer_name+" - "+item.customer_city})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    getSalesOrders = (e) => {
        if(e !=undefined)
        {
            e.preventDefault();
        }
        var salesorderDate = $("#salesorderDate").val();
        var salesorderToDate = $("#salesorderToDate").val();
        var plant = this.state.plant;
        var customer = this.state.customer;
        var plantsList = this.state.plantsList;
        // console.log(plantsList,"this.state.plantsList")
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        if(salesorderDate !=undefined && salesorderDate !="")
        {
            var original_date = salesorderDate
            salesorderDate = salesorderDate.replace(/\-/g, '');
            salesorderToDate = salesorderToDate.replace(/\-/g, '');
            var params = {original_date,salesorderDate,salesorderToDate,plant}
            if(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="undefined" && localStorage.getItem("plant_code") !="")
            {
                params.plant = localStorage.getItem("plant_code")
            }
            else
            {
                if(plant.value !=undefined && plant !="")
                {
                    params.plant = plant.value
                }
            }
            redirectURL.post("/manage/getSalesOrders",params).then(async(response)=>{
                console.log(response.data);
                var salesOrders = response.data;
                console.log(salesOrders,"salesOrders-after")
                // if(plant !=undefined && plant !="")
                // {
                //     salesOrders = salesOrders.filter((sl) => sl.PLANT == plant.value)
                // }

                //COMMENTING THIS CODE BECAUSE WE ARE NOT ABLE TO ACCESS THE SAP API
                
                // if(customer.value !=undefined && customer.value !="")
                // {
                //     salesOrders = salesOrders.filter((sl) => sl.SOLD_PARTY == customer.value)                    
                // }
                // var uniqueSo = groupBy(salesOrders, rdata => rdata.SO_NUM);
                // var usalesOrders = [];
                // await uniqueSo.forEach(async(wares, wkeys) => {
                //     wares.map(function(wa,index){
                //         var sno = index+1;
                //         wa.item_unique_code = wkeys+"-"+sno
                //     });
                //     usalesOrders.push({
                //         sales_order_number : wkeys,
                //         tpt_details : wares[0].tpt_details,
                //         transporter_exists : wares[0].transporter_exists,
                //         item_details : wares
                //     });
                // })
                // await usalesOrders.map(async function(uso){
                //     if(uso.item_details.length > 0)
                //     {
                //         var plantCode = uso.item_details[0].PLANT;
                //         var plantDetails = plantsList.filter((e) => e.plant_code == plantCode);
                //         if(plantDetails.length > 0)
                //         {
                //             uso.plant_details = plantDetails[0].plant_code+" - "+plantDetails[0].plant_name+" - "+plantDetails[0].plant_location;
                //         }
                //         else
                //         {
                //             uso.plant_details = plantCode;
                //         }                        
                //     }
                // })
                await this.setState({
                    salesOrders : salesOrders,
                    // uniqueSalesOrders : usalesOrders,
                    uniqueSalesOrders : salesOrders,
                    loadshow : "show-n",
                    overly : "show-n",                    
                    noData : "show-m",
                    showGrid : "show-m",
                    showForm : "show-n",
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Please select schedule dates",
                basicType : "warning",
            });
        }
    }
    onSuccessShowData = () => {
        var salesorderDate = $("#salesorderDate").val();
        var plant = this.state.plant;
        var customer = this.state.customer;
        var plantsList = this.state.plantsList;
        if(salesorderDate !=undefined && salesorderDate !="")
        {
            var original_date = salesorderDate
            salesorderDate = salesorderDate.replace(/\-/g, '');
            var salesorderToDate = $("#salesorderToDate").val();
            salesorderToDate = salesorderToDate.replace(/\-/g, '');
            var params = {original_date,salesorderDate,salesorderToDate}
            if(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="undefined" && localStorage.getItem("plant_code") !="")
            {
                params.plant = localStorage.getItem("plant_code")
            }
            else
            {
                if(plant.value !=undefined && plant !="")
                {
                    params.plant = plant.value
                }
            }
            this.setState({
                loadshow : "show-m",
                overly : "show-m",
            }); 
            redirectURL.post("/manage/getSalesOrders",params).then(async(response)=>{
                console.log(response.data);
                var sos = response.data;
                var uniqueSo = groupBy(sos, rdata => rdata.SO_NUM);
                var usalesOrders = [];
                await uniqueSo.forEach(async(wares, wkeys) => {
                    if(wares[0].transporter_exists == 0)
                    {
                        wares.map(function(wa,index){
                            var sno = index+1;
                            wa.item_unique_code = wkeys+"-"+sno
                        });
                    }
                    usalesOrders.push({
                        sales_order_number : wkeys,
                        tpt_details : wares[0].tpt_details,
                        transporter_exists : wares[0].transporter_exists,
                        item_details : wares
                    });
                })
                await usalesOrders.map(async function(uso){
                    if(uso.item_details.length > 0)
                    {
                        var plantCode = uso.item_details[0].PLANT;
                        var plantDetails = plantsList.filter((e) => e.plant_code == plantCode);
                        if(plantDetails.length > 0)
                        {
                            uso.plant_details = plantDetails[0].plant_code+" - "+plantDetails[0].plant_name+" - "+plantDetails[0].plant_location;
                        }
                        else
                        {
                            uso.plant_details = plantCode;
                        }                        
                    }
                })
                await this.setState({
                    salesOrders : response.data,
                    uniqueSalesOrders : usalesOrders,
                    selectedTpts: [{}],
                    salesOrderInfo:"",
                    showForm : "show-n",
                    showGrid : "show-m",
                    packetrows0 : [{}],
                    no_of_trucks_0 : 1,
                    loadshow : "show-n",
                    overly : "show-n",
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
    }
    onCellClicked = async(params) =>{
        if(params.colDef.field == "item_detais")
        {
            if(params != "")
            {
                var itemDetails=[];
                console.log("Step 3 ",params.data)
                var propsdata = params;
                this.setState({detailCellRendererParams:{}});
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                                { headerName: "Item",field: "SO_ITEM", filter:true,resizable: true },
                                { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                                { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData: function(param) {
                            if(params.data.item_details !=undefined && params.data.item_details !="")
                            {
                                // itemDetails = (params.data.item_details);
                                itemDetails = JSON.parse(params.data.item_details);
                            }
                            param.successCallback(itemDetails);                            
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'item_detais')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "assign_tpt")
        {            
            var salesOrderInfo = params.data;
            var selectedTpts=[];
            // console.log(salesOrderInfo,"salesOrderInfo");
            // if(salesOrderInfo.transporter_exists == 1 && salesOrderInfo.tpt_details.length > 0)
            // {
            //     var soInfo = [];
            //     await salesOrderInfo.tpt_details.map(async function(e,index){
            //         selectedTpts.push({
            //             transporter : "",
            //             no_of_trucks : ""
            //         });
            //         var transporter = {"value":e.transporter_code,"label":e.transporter_name}
            //         window.soDataItem("no_of_trucks_"+index,e.no_of_trucks);
            //         window.soDataItem("select_transporter_"+index,transporter);
            //         var packetData = "packetrows"+index;
            //         packetData = packetData.toString();
            //         console.log(packetData,"packetData")
            //         this.setState({
            //             [packetData]: [{}]
            //         });
            //     })
            //     await this.setState({
            //         selectedTpts : selectedTpts
            //     });
            // }
            // console.log(selectedTpts,"selectedTpts")
            this.setState({
                // sliderType : 1,
                // sliderTitle : "Assign Transporters",
                // overly :"show-m",
                // bulkslide : "slider-translate-50p",
                showForm : "show-m",
                showGrid : "show-n",
                salesOrderInfo : salesOrderInfo,
            })
        }
    }
    soDataItem = (key,value) => {
        console.log(key,value)
        this.setState({
            [key] : value
        });
    }
    assignSalesOrdersToTpt = (e) => {
        e.preventDefault();
        var salesOrderInfo = this.state.salesOrderInfo;
        var salesOrdersInfo = [];
        salesOrdersInfo.push(salesOrderInfo);
        console.log(salesOrdersInfo, "salesOrderInfo");
        if(salesOrdersInfo.length > 0)
        {
            var tptLength = $("#tptLength").val();
            var tptData=[];
            var check = 0;
            for(var r=0;r<tptLength;r++)
            {
                var transporter=this.state["select_transporter_"+r];
                console.log(transporter,"select_transporter_"+r,"transporter")
                var transporter_code = "";
                var transporter_name = "";
                if(transporter !=undefined && transporter !="")
                {
                    if(transporter !=undefined && transporter !="")
                    {
                        transporter_code = transporter.value;
                        transporter_name = transporter.label;
                    }
                    var no_of_trucks=$("#no_of_trucks_"+r).val();
                    var remarks=$("#remarks_"+r).val();
                    if(transporter_code !='' && transporter_code !=undefined && transporter_name !="" && transporter_name !=undefined && no_of_trucks !='' && no_of_trucks !=undefined)
                    {
                        var expectedTruckType = [];
                        for(var t=0;t<no_of_trucks;t++)
                        {
                            var expTruck=$("#expected_truck_type_"+r+"_"+t).val();
                            if(expTruck !=undefined && expTruck !="")
                            {
                                expectedTruckType.push(expTruck);
                                check = 1;
                            }
                        }
                        var selectedTptDetails = {"transporter_code":transporter_code,"transporter_name":transporter_name,"no_of_trucks":no_of_trucks,"remarks":remarks,"expected_truck_type":expectedTruckType}
                        tptData.push(selectedTptDetails)
                        check = 1;
                    }
                    else
                    {
                        check = 0;
                        break;
                    }
                }
                else
                {                    
                    check = 0;
                }
            }
            console.log(tptData,"tptData")
            if(check == 1)
            {
                var assigned_by = localStorage.getItem("email");
                var params = {salesOrdersInfo,tptData,assigned_by}
                redirectURL.post("/manage/assignSalesOrdersToTpt",params).then((response)=>{
                    console.log(response);
                    if(response.data.message == "Success")
                    {
                        // var insertedcount = response.data.insertedcount;
                        // var notinsertedcount = response.data.notinsertedcount;
                        // var respMsg = "Assigned Count : "+insertedcount+", Not Assigned Count : "+notinsertedcount;
                        // var basicType = "success";
                        // if(notinsertedcount > 0)
                        // {
                        //     basicType = "warning"
                        // }
                        this.setState({
                            basicTitle:response.data.message,
                            basicType : "success",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n",
                            bulkslide:"",
                            sliderType:"",
                            selectedTpts: [{}],
                            transporter:[],
                            salesOrderInfo:""
                        })
                        // this.onSuccessShowData();
                        this.loadPageData();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:response.data.message,
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n"
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Fields should not be empty",
                    basicType : "warning",
                });
            }
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Assign Transporter to continue",
                basicType : "warning",
            });
        }
    }
    onChangeTptItem = (idx,transporter) => {
		this.setState(
			{ ["select_transporter_"+idx] : transporter},
			() => console.log(`transporter Option selected:`, this.state["select_transporter_"+idx])
          );
	}
    changeHandler = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        this.setState({[name]:value});
    }
    selecttpts(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.transportersList.length > 0)
			{
				this.state.transportersList.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    handleAddRow = () => {
        const item = {
            transporter: "",
            no_of_trucks: "",
        };
        var selectedTpts = [...this.state.selectedTpts, item];
        var ind = (selectedTpts.length)-1;
        var packetData = "packetrows"+ind;
        packetData = packetData.toString();
        // console.log(packetData,"packetData")
        this.setState({
            selectedTpts: selectedTpts,
            [packetData]: [{}],
        });
    };
    handleRemoveRow = (idx) => {
        this.state.selectedTpts.splice(idx, 1);
        this.setState({ selectedTpts: this.state.selectedTpts });
    };
    packetsHandler = (idx,e)=> {
        var noOfPackets = e.target.value;
        if(parseInt(noOfPackets) > 1)
        {
            var lespcks = parseInt(noOfPackets)-1;
            // if(lespcks == 0)
            // {
            //     lespcks = 1;
            // }
            var dataSet=[{}];
            for(var n=0;n<lespcks;n++)
            {
                const item = {
                    ["expected_truck_type_"+idx]: "",
                };
                this.setState({
                    ['packetrows'+idx]: [...dataSet, item]
                });
                var dataSet=[...dataSet, item];
            }
            // console.log("Select", event.target.value);
            let name = e.target.name;
            let value = e.target.value;
            this.setState({[name]:value});
        }
        else
        {
            // alert("No of Trucks should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                ["expected_truck_type_"+idx]: "",
            };
            this.setState({
                ['packetrows'+idx]: [item]
            });
        }
    }
	render(){
        var columnwithDefs = [      
            {
                headerName: "Sales Order Number",
                field: "sales_order_number",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },      
            {
                headerName: "Scheduled Date",
                field: "SCH_DATE",
                width:130,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.item_details.length > 0)
                    {
                        try{                            
                            var sch_date = JSON.parse(params.data.item_details)[0].SCH_DATE;                        
                            var year = sch_date.substring(0, 4);
                            var month = sch_date.substring(4, 6);
                            var day = sch_date.substring(6, 8);
                            sch_date = year + '-' + month + '-' + day;
                            return sch_date;
                        }catch(e){
                            var sch_date = params.data.item_details[0].SCH_DATE; 
                            return sch_date; 
                        }
                    }
                }
            },   
            {
                headerName: "Sold Party",
                field: "SOLD_PARTY",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.item_details.length > 0)
                    {
                        return JSON.parse(params.data.item_details)[0].SOLD_PARTY
                    }
                }
            },   
            {
                headerName: "Ship Party",
                field: "SHIP_PARTY",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.item_details.length > 0)
                    {
                        return JSON.parse(params.data.item_details)[0].SHIP_PARTY
                    }
                }
            },
            {
                headerName: "Customer Name",
                field: "NAME",
                width:250,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.item_details.length > 0)
                    {
                        return JSON.parse(params.data.item_details)[0].NAME
                    }
                }
            },   
            {
                headerName: "Ship City",
                field: "SHIP_CITY",
                width:130,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.item_details.length > 0)
                    {
                        return JSON.parse(params.data.item_details)[0].SHIP_CITY
                    }
                }
            },   
            {
                headerName: "Plant",
                // field: "plant_details",
                field: "plant_location",
                width:220,
                filter: true,
                resizable: true,
                editable:false,
                // valueGetter:function(params){
                //     if(params.data.item_details.length > 0)
                //     {
                //         var plantCode = params.data.item_details[0].PLANT;
                //         if(this.state.plantsList.length > 0)
                //         {
                //             var plantDetails = this.state.plantsList.filter((e) => e.plant_code == plantCode);
                //             if(plantDetails.length > 0)
                //             {
                //                 return plantDetails[0].plant_code+" - "+plantDetails[0].plant_name+" - "+plantDetails[0].plant_location;
                //             }
                //             else
                //             {
                //                 return plantCode;
                //             }
                //         }
                //     }
                // }
            },
            {
                headerName: "Item Details",
                field: "item_detais",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-primary" style="padding-top:0px">View</button>';
                    return htmloption;
                }
            },
            {
                headerName: "Assign Transporters",
                field: "assign_tpt",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-warning" style="padding-top:0px">Assign</button>';
                    return htmloption;
                }
            },
            // {
            //     headerName: "Assign Transporter",
            //     field: "transporter_name",
            //     width:180,
            //     filter: true,
            //     resizable: true,
            //     editable:function(params){
            //         if(params.data.transporter_exists == 1)
            //         {
            //             return false;
            //         }
            //         else{
            //             return true;
            //         }
            //     },
            //     cellEditor: 'agSelectCellEditor',
            //     cellClass: function(params){
            //         if(params.data.transporter_exists == 1)
            //         {
            //             return [];
            //         }
            //         else{
            //             return ["cellstylegridB"];
            //         }
            //     }, 
            //     cellEditorParams: {
            //         values: this.state.transportersList,
            //     }
            // },
        ]
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12'>
                        <h5>
                            <i className="icofont icofont-user cus-i"></i> <span>Assign Sales Orders</span>
                            {/* <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                            <button className="btn btn-success" style={{float:"right",marginRight:"10px"}} onClick={this.addNewRow.bind(this)}>Add Transporter</button> */}
                        </h5>
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.getSalesOrders}>
                            <div className="row mt-10p">
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label className="">Scheduled From Date</label> 
                                        <input type="text" name="salesorderDate" id="salesorderDate" autoComplete='off' className="form-control forminp datetimepicker" required  />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label className="">Scheduled To Date</label> 
                                        <input type="text" name="salesorderToDate" id="salesorderToDate" autoComplete='off' className="form-control forminp datetimepicker" required  />
                                    </div>
                                </div>
                                {(localStorage.getItem("plant_code")) == undefined || localStorage.getItem("plant_code") == "undefined" || (localStorage.getItem("plant_code")) == "" ?
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="">Plant</label> 
                                        <Select 
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangePlantItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.plant}
                                        options={this.selectplants()} />
                                    </div>
                                </div>
                                :""}
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="">Customer</label> 
                                        <Select 
                                        placeholder={"Select Customer"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeCustomerItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.customer}
                                        options={this.selectcustomers()} />
                                    </div>
                                </div>
                                <div className="col-sm-1 mt-30p">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Fetch</button>
                                    </div>
                                </div>
                                {/* {((this.state.uniqueSalesOrders.length) > 0) ?
                                <div className="col-sm-2 mt-30p">
                                    <div className="form-group" style={{float:"right"}}>
                                        <button type="button" className='btn btn-warning' onClick={this.assignSalesOrdersToTpt}>Assign</button>
                                    </div>
                                </div>
                                :""} */}
                            </div>
                        </form>
                    </div>
                    {((this.state.uniqueSalesOrders.length) > 0) ?
                    <div className='col-xl-12 col-lg-12'>
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGrid}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.uniqueSalesOrders}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                onCellClicked={this.onCellClicked}
                                />
                        </div>
                        {/* <button type="button" style={{float:"right",marginTop:"2%"}} className='btn btn-warning' onClick={this.assignSalesOrdersToTpt}>Assign</button> */}
                        <form method="POST" id="upform" className={"theme-form mt-20p "+this.state.showForm} onSubmit={this.assignSalesOrdersToTpt}>
                            <h5 style={{marginBottom:"2%",color:"#198754"}}>Assign Transporters For Sales Order - {this.state.salesOrderInfo.sales_order_number}</h5>
                            {this.state.selectedTpts.map((item, idx) => (
                                <div className="row" key={idx}>
                                    <div className='col-sm-3'>
                                        <label className="">Transporters</label>
                                        <Select
                                            placeholder={"Select Transporter"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeTptItem.bind(this,idx)}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state["select_transporter_"+idx]}
                                            name={"select_transporter_"+idx}
                                            id={"select_transporter_"+idx}
                                            options={this.selecttpts()} />
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="">No Of Trucks</label> 
                                            <input type="text" name={"no_of_trucks_"+idx} id={"no_of_trucks_"+idx} className="form-control num-cls" onBlur={this.packetsHandler.bind(this,idx)} autoComplete="off" required  />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="">Remarks</label> 
                                            <input type="text" name={"remarks_"+idx} id={"remarks_"+idx} value={this.state["remarks_"+idx]} className="form-control" onChange={this.changeHandler} autoComplete="off"  />
                                        </div>
                                    </div>
                                    {this.state['packetrows'+idx] !=undefined ? 
                                    <div className="row col-sm-12">
                                    {this.state['packetrows'+idx].map((item1, idx1) => (
                                        <div className="col-xl-3" key={idx1}>
                                            <div className="form-group">
                                                <label className="">Truck Type* : </label>
                                                <select type="text" name={"expected_truck_type_"+idx+"_"+idx1} id={"expected_truck_type_"+idx+"_"+idx1}className="form-control" onChange={this.changeHandler}>
                                                    <option value="">Select Truck Type</option>
                                                    {(this.state.truckTypesList.map(function(tt){
                                                    return <option value={tt.truck_type}>{tt.truck_type}</option>
                                                    }))}
                                                </select>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-xl-12" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{cursor:"pointer"}} onClick={() => this.handleRemoveRow(idx)}>Remove</h6>
                                        </div>
                                    </div>
                                    :""}
                                    <div class="hr-dashed"></div>
                                </div>
                            ))}
                            <div class="row">
                                <input type="hidden" id="tptLength" value={this.state.selectedTpts.length}/>
                                <div className="col-xl-12 input_fields_wrap m-top-1" style={{marginTop:"1%"}} id="addMrERp">
                                    <button class="btn btn-warning" type="button" onClick={this.handleAddRow}>Add Transporter +</button>
                                </div>
                                <div className="col-sm-12" style={{marginTop:"1%"}}>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    <div className={'col-xl-12 col-lg-12 '+this.state.noData}>
                        <h5>No Data Found...</h5>
                    </div>
                    }
                </div>
                
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"auto"}}>
                    <h4 className="subH">
                        {this.state.sliderTitle}
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                            {/* <form method="POST" id="upform" className="theme-form mt-20p" onSubmit={this.assignSalesOrdersToTpt}>
                                {this.state.selectedTpts.map((item, idx) => (
                                    <div className="row" key={idx}>
                                        <div className='col-sm-6'>
                                            <label className="">Transporters</label>
                                            <Select
                                                placeholder={"Select Transporter"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeTptItem.bind(this,idx)}
                                                className={"border-radius-0"}
                                                style={{borderRadius:"0px"}}
                                                value={this.state["select_transporter_"+idx]}
                                                name={"select_transporter_"+idx}
                                                id={"select_transporter_"+idx}
                                                options={this.selecttpts()} />
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="">No Of Trucks</label> 
                                                <input type="text" name={"no_of_trucks_"+idx} id={"no_of_trucks_"+idx} value={this.state["no_of_trucks_"+idx]} className="form-control num-cls" onChange={this.changeHandler} required  />
                                            </div>
                                        </div>
                                        <div className="col-xl-12" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{cursor:"pointer"}} onClick={() => this.handleRemoveRow(idx)}>Remove</h6>
                                        </div>
                                    </div>
                                ))}
                                <div class="row">
                                    <input type="hidden" id="tptLength" value={this.state.selectedTpts.length}/>
                                    <div className="col-xl-12 input_fields_wrap m-top-1" style={{marginTop:"1%"}} id="addMrERp">
                                        <button class="btn btn-warning" type="button" onClick={this.handleAddRow}>Add Transporter +</button>
                                    </div>
                                    <div className="col-sm-12" style={{marginTop:"1%"}}>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form> */}
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadDateTimeScript(){
	// alert("datetime");
	$('.datetimepicker').datetimepicker({
		datepicker: true,
		timepicker: false,
        // minuteStepping:30,
        format: 'Y-m-d',
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

