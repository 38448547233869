import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';


import {
	getHyphenYYYYMMDDHHMMSS,
} from '../common/utils';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}

export default class AssignTruck extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    
                ]
            },
            animateRows: true,
			pageTitle: "Transporters",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add Transporter",
            rowId : "",
            salesOrders : [],
            uniqueSalesOrders : [],
            trucksList : [],
            salesOrderInfo : [],
            rowSelection:'multiple',
            itemDetails:[],
            plant:{value:"",label:"Select Plant"},
            customer:{value:"",label:"Select Customer"},
            noData:"show-n"
        };
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        loadDateTimeScript();
        this.loadPageData();
    }
    
    loadPageData = () =>{
        redirectURL.post("/manage/getCustomerAndPlantsList").then(async(response)=>{
            var plantsList = response.data.plants;
            var customersList = response.data.customers;
            this.setState({
                plantsList : plantsList,
                customersList : customersList,
            })
        }).catch((e)=>{
            console.log(e);
        })
        var params = {};
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            params.transporter_code = localStorage.getItem("transportercode");
        }
        redirectURL.post("/manage/getTrucksList",params).then(async(response)=>{
            console.log(response.data);
            this.setState({
                trucksList : response.data,
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

    getSalesOrders = (e) => {
        e.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly : "show-m",
        });
        var startDate = $("#startDate").val();
        var endDate = $("#endDate").val();
        var plant = this.state.plant;
        var customer = this.state.customer;
        if(startDate !=undefined && startDate !="" && endDate !=undefined && endDate !="")
        {
            var transporter_code = localStorage.getItem("transportercode")
            var params = {transporter_code,startDate,endDate}
            if(plant.value !=undefined && plant.value !="")
            {
                params.plant = plant.value
            }
            if(customer.value !=undefined && customer.value !="")
            {
                params.customer = customer.value
            }
            redirectURL.post("/manage/getTransporerSalesOrders",params).then(async(response)=>{
                console.log(response.data);
                var salesOrders = response.data;
                this.setState({
                    salesOrders : salesOrders,
                    uniqueSalesOrders : salesOrders,
                    loadshow : "show-n",
                    overly : "show-n",                    
                    noData : "show-m"
                })
                loadDateTimeScript();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Please select sales orders date",
                basicType : "warning",
            });
        }
    }
	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        //console.log(this.gridApi);    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
        });
    }
    showBulkUpload(){
        this.setState({
            sliderType : 2,
            sliderTitle : "Bulk Upload",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            transporter:'',
            truck:''
        });
        $("#reporting_time").val("");
        $("#driver_name").val("");
        $("#driver_mobile").val("");
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                { 
                    name: 'transporter_code',
                    inputName: 'transporter_code',
                    required: false,
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Transporter Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Transporter Email',
                    inputName: 'transporter_email',
                    required: false
                },
                { 
                    name: 'Transporter Mobile',
                    inputName: 'transporter_mobile',
                    required: false
                }                   
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData,"csvData")
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
    
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    saveNewRowData = (e) => {
        e.preventDefault();
        var reporting_time = $("#reporting_time").val();
        var driver_name = $("#driver_name").val();
        var driver_mobile = $("#driver_mobile").val();
        var salesOrder = this.state.salesOrder;
        var truck = this.state.truck;
        var itemDetails = this.state.itemDetails;
        console.log(itemDetails,"itemDetails")
        if(reporting_time !=undefined && reporting_time !="" && driver_name !=undefined && driver_name !="" && driver_mobile !=undefined && driver_mobile !="" && salesOrder !=undefined && salesOrder !="" && truck !=undefined && truck !="")
        {
            if(salesOrder.value !="" && salesOrder.value !=undefined)
            {
                if(truck.value !="" && truck.value !=undefined)
                {
                    if(itemDetails.length > 0)
                    {
                        var salesordernumber = salesOrder.value;
                        var truck_capacity = truck.value;
                        var truck_no = truck.label;
                        var salesOrders = this.state.salesOrders;
                        var salesOrderInfo = salesOrders.filter((e) => e.sales_order_number == salesordernumber);
                        if(salesOrderInfo.length > 0)
                        {
                            this.setState({
                                loadshow : "show-m",
                                overly : "show-m"
                            });
                            var params = {
                                sales_order_number : salesOrderInfo[0].sales_order_number,
                                sold_party : salesOrderInfo[0].sold_party,
                                ship_party : salesOrderInfo[0].ship_party,
                                ship_city : salesOrderInfo[0].ship_city,
                                plant_code : salesOrderInfo[0].plant_code,
                                plant_name : salesOrderInfo[0].plant_name,
                                plant_location : salesOrderInfo[0].plant_location,
                                sch_date : getHyphenYYYYMMDDHHMMSS(salesOrderInfo[0].sch_date),
                                customer_name : salesOrderInfo[0].customer_name,
                                item_details : JSON.stringify(itemDetails),
                                transporter_code : localStorage.getItem("transportercode"),
                                truck_no : truck_no,
                                truck_capacity : truck_capacity,
                                reporting_time : reporting_time,
                                driver_name : driver_name,
                                driver_mobile : driver_mobile
                            }
                            redirectURL.post("/manage/assignsalesordertotransporter",params).then((response)=>{
                                console.log(response);
                                if(response.data.message == "Success")
                                {
                                    this.setState({
                                        basicTitle:"Updated Successfully.",
                                        basicType : "success",
                                        show : true,
                                        loadshow : "show-n",
                                        overly : "show-n",
                                        bulkslide:"",
                                        sliderType:"",
                                    })
                                    this.backToScreen();
                                }
                                else
                                {
                                    this.setState({
                                        basicTitle:response.data.message,
                                        basicType : "warning",
                                        show : true,
                                        loadshow : "show-n",
                                        overly : "show-n"
                                    })
                                }
                            })
                        }
                        else
                        {
                            this.setState({
                                show : true,
                                basicTitle : "Something went wrong.",
                                basicType : "warning",
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n"
                            });
                        }
                    }
                    else
                    {
                        this.setState({
                            show : true,
                            basicTitle : "Select Items to continue.",
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n"
                        });
                    }
                }
                else
                {
                    this.setState({
                        show : true,
                        basicTitle : "Truck Should not be Empty.",
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    });
                }
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Sales Order Should not be Empty.",
                    basicType : "warning",
                    show : true,
                    loadshow : "show-n",
                    overly : "show-n"
                });
            }
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Fields Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/manage/savebulktransporters",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        overly:'show-n',
                    })
                    this.loadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    backToScreen = () => {
        window.location.href="/assignedtrucks";
    }
    onCellClicked = (e) =>{
        if(e.colDef.field == "edit")
        {
            var rowId = e.data._id;
            var transporter_code = e.data.transporter_code;
            var transporter_name = e.data.transporter_name;
            console.log(transporter_code,transporter_name,"transporter_code")
            this.setState({
                sliderType : 3,
                sliderTitle : "Edit Transporter",
                rowId:rowId,
                overly :"show-m",
                bulkslide : "slider-translate",
            })
            $("#transporter_code").val("");
            $("#transporter_code").val(transporter_code);
            $("#transporter_name").val("");
            $("#transporter_name").val(transporter_name);
        }
    }
    onCellUpdateData = (e) =>{
        if(e.colDef.field == "status")
        {
            var status = e.data.status;
            if(status !=undefined && status !="")
            {
                var rowId = e.data._id;
                var params = {
                    rowId : rowId,
                    status : status,
                }
                redirectURL.post("/manage/updatetransporterstatus",params).then((response)=>{
                    console.log(response);
                    this.loadPageData();
                })
            }
        }
    }
    onChangeSalesOrderItem = (salesOrder) => {
        var salesOrders = this.state.salesOrders;
        var salesordernumber = salesOrder.value;
        var salesOrderInfo = [];
        if(salesordernumber !=undefined && salesordernumber !="")
        {
            var salesOrderDetails = salesOrders.filter((e) => e.sales_order_number == salesordernumber);
            if(salesOrderDetails.length > 0)
            {
                if(salesOrderDetails[0].item_details.length > 0)
                {
                    salesOrderInfo = JSON.parse(salesOrderDetails[0].item_details);
                    this.setState(
                        { salesOrder,salesOrderInfo},
                        () => console.log(`salesOrder Option selected:`, this.state.salesOrder, this.state.salesOrderInfo)
                    );
                }
                else
                {
                    this.setState({
                        show : true,
                        basicTitle : "No Items Found For Selected Sale Order",
                        basicType : "warning"
                    });
                }
            }
        }
	}
    onChangeTruckItem = (truck) => {
		this.setState(
			{ truck : truck},
			() => console.log(`truck Option selected:`, this.state.truck)
          );
	}
    selectsalesorders(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.uniqueSalesOrders.length > 0)
			{
				this.state.uniqueSalesOrders.map((item) =>{
					items.push({"value":item.sales_order_number,"label":item.sales_order_number})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selecttrucks(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.trucksList.length > 0)
			{
				this.state.trucksList.map((item) =>{
					items.push({"value":item.truck_capacity,"label":item.truck_no})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    onRowSelection(event){
        var itemDetails=[];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            console.log(item.data.assigned_check,typeof(item.data.assigned_check))
            if(item.data.assigned_check == 1)
            {
            }
            else
            {
                itemDetails.push(item.data)
            }
        });
        console.log(itemDetails,"itemDetails")
        this.setState({
            itemDetails:itemDetails
        })
    }
    onChangePlantItem = (plant) => {
		this.setState(
			{plant},
			() => console.log(`plant Option selected:`, this.state.plant)
        );
	}
    onChangeCustomerItem = (customer) => {
		this.setState(
			{customer},
			() => console.log(`customer Option selected:`, this.state.customer)
          );
	}
    selectplants = () => {
		let items = [];    
		try{
			if(this.state.plantsList.length > 0)
			{
				this.state.plantsList.map((item) =>{
					items.push({"value":item.plant_code,"label":item.plant_code+" - "+item.plant_name+" - "+item.plant_location})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selectcustomers = () => {
		let items = [];    
		try{
			if(this.state.customersList.length > 0)
			{
				this.state.customersList.map((item) =>{
					items.push({"value":item.customer_code,"label":item.customer_name+" - "+item.customer_city})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
	render(){
        var salesOrderInfo = this.state.salesOrderInfo;
        var assignedCheck = salesOrderInfo.filter((ac) => ac.assigned_check == 1);
        var checkSelection = true;
        if(salesOrderInfo.length == assignedCheck.length)
        {
            checkSelection = false;
        }
        console.log(checkSelection,"checkSelection")
        var columnwithDefs = [
            {
                headerName: "Actions",
                field: "_id",
                colId: "_id",
                width: 40,
                pinned: 'left',
                filter: false,resizable: true,
                headerCheckboxSelection: checkSelection,
                //headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: params =>{
                    return params.data.assigned_check ? false
                      : true;
                },
                cellStyle: params => {
                    return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                      : '';
                }
            },
            { headerName: "Item Code",field: "item_unique_code", filter:true,resizable: true,cellStyle: params => {
                return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                  : '';
            }},
            { headerName: "Item",field: "SO_ITEM", filter:true,resizable: true,cellStyle: params => {
                return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                  : '';
            }},
            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true,cellStyle: params => {
                return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                  : '';
            }},
            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true,cellStyle: params => {
                return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                  : '';
            }},
            { headerName: "Status",field: "assigned_check", filter:true,resizable: true,valueGetter:function(params){if(params.data.assigned_check == 1){return "Truck Assigned"}else{return "Yet to assign";}},cellStyle: params => {
                return params.data.assigned_check ? {'pointerEvents': 'none', opacity: '0.4' }
                  : '';
            }},
        ];
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12'>
                        <h5>
                            <i className="icofont icofont-truck-alt cus-i"></i> <span>Assign Truck</span>
                            <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.backToScreen.bind(this)}>Back</button>
                        </h5>
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.getSalesOrders}>
                            <div className="row mt-10p">
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label className="">Sales Order From Date</label> 
                                        <input type="text" name="startDate" id="startDate" autoComplete='off' className="form-control forminp datepicker" required  />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label className="">Sales Order To Date</label> 
                                        <input type="text" name="endDate" id="endDate" autoComplete='off' className="form-control forminp datepicker" required  />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="">Plant</label> 
                                        <Select 
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangePlantItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.plant}
                                        options={this.selectplants()} />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="">Customer</label> 
                                        <Select 
                                        placeholder={"Select Customer"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeCustomerItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.customer}
                                        options={this.selectcustomers()} />
                                    </div>
                                </div>
                                <div className="col-sm-2 mt-30p">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Fetch</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {((this.state.uniqueSalesOrders.length) > 0) ?
                    <div className='col-xl-12 col-lg-12'>
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.saveNewRowData}>
                            <div className="row mt-30p">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="">Sales Orders</label> 
                                        <Select 
                                        placeholder={"Select Sales Order"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeSalesOrderItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.salesOrder}
                                        options={this.selectsalesorders()} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="">Trucks</label> 
                                        <Select 
                                        placeholder={"Select Truck"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeTruckItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.truck}
                                        options={this.selecttrucks()} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="">Reporting Time</label> 
                                        <input type="text" name="reporting_time" id="reporting_time" className="form-control forminp datetimepicker" required  />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="">Driver Name</label> 
                                        <input type="text" name="driver_name" id="driver_name" className="form-control forminp" required  />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label className="">Driver Mobile</label> 
                                        <input type="text" name="driver_mobile" id="driver_mobile" className="form-control forminp" required  />
                                    </div>
                                </div>
                                {/* {((this.state.salesOrderInfo.length) > 0) ?
                                <div className="col-sm-12">
                                    <h4>Sale Order Item Details</h4>
                                    <div id="myGrid" style={{width:"100%",height:"300px"}} className={"ag-theme-balham"}>    
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.salesOrderInfo}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            stopEditingWhenGridLosesFocus= {true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            // components={this.state.components}
                                            enableRangeSelection= {true}
                                            rowSelection={this.state.rowSelection}
                                            onRowSelected={this.onRowSelection.bind(this)}
                                            />
                                        </div>
                                </div>
                                :""} */}
                                {((this.state.salesOrderInfo.length) > 0) ?
                                <div className="col-sm-12 mt-30p">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" onClick={this.backToScreen.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </div>
                                :""}
                            </div>
                        </form>
                    </div>
                    :
                    <div className={'col-xl-12 col-lg-12 '+this.state.noData}>
                        <h5>No Data Found...</h5>
                    </div>
                    }
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function loadDateTimeScript(){
	// alert("datetime");
	$('.datetimepicker').datetimepicker({
		datepicker: true,
        // minuteStepping:30,
        format: 'Y-m-d H:i',
	});
	$('.datepicker').datetimepicker({
		datepicker: true,
        timepicker: false,
        // minuteStepping:30,
        format: 'Y-m-d',
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}

