import ReactGA from 'react-ga';
var redirectURL = require('../redirectURL');

export const initGA = () => {
    let hostName = window.location.hostname;
    let userId = (window.localStorage.userid) ? window.localStorage.userid : "";
    let initOptions = null;
    if (userId) {
        initOptions = {
            "gaOptions": {
                userId: userId
            }
        }
    }

    let trackingId = "UA-162953564-3";
    if (hostName == "localhost") {
        trackingId = "UA-162953564-1";
    } else if (hostName == "164.52.197.10") {
        // Test Server - http://164.52.197.10:3000/dashboard/default
        trackingId = "UA-162953564-3";
    } else {
       // trackingId = "G-XSH6DM5EWC";
        trackingId = "UA-162953564-4"; // Autometrics Web
    }
    // ReactGA.initialize("G-D0LGECP69Y");
    //console.log("IN initGA hostName and trackingId = ", window.location.hostname, trackingId);
    try {
        if (initOptions) {
            // console.log("IN initGA with initOptions = ", initOptions);
            ReactGA.initialize(trackingId, initOptions);
        } else {
            ReactGA.initialize(trackingId);
            // console.log("IN initGA withOUT initOptions = ", initOptions);
        }
    } catch (error) {
        // console.error("IN initGA, Error while initialize, trackingId, error = ", trackingId, error);
    }

    let setUserOption = {
        userId: userId
    }
    if (userId) {
        try {
            let setUserOptionResult = ReactGA.set(setUserOption);
            // console.log("IN initGA, User was set, setUserOption = ", setUserOption);
        } catch (error) {
            // console.error("IN initGA, Error while setting User, setUserOption and error = ", setUserOption, error);
        }
       
    }
}

export const logPageView = () => {
    initGA();
    let pathName = window.location.pathname;
    let search = window.location.search;
    let totalPath = pathName + search;
    var params = {
        screenpath:totalPath,
        screen_type:"view",
        userid:localStorage.getItem("userid"),
        user_type:localStorage.getItem("user_type"),
        user_email:localStorage.getItem("email"),
        dept_code:localStorage.getItem("dept_code"),
        user_roles:localStorage.getItem("roles")
    }
    // redirectURL.post("/overview/saveuseranalytics",params)
    // .then((response) => {
    //     console.log("Log Page View ", response.data.message)
    // })
    // console.log("IN logPageView pathname and totalPath = ", window.location.pathname, totalPath);
    try {
        ReactGA.set({page: totalPath});
        ReactGA.pageview(totalPath);
        // console.log("IN logPageView, pageview logged, totalPath = ", totalPath);
    } catch (error) {
        // console.log("IN GA logEvent, Error while setting Page and Pageviews = ", totalPath, error);
    }
    
}

export const logEvent = (options) => {
    initGA();
    let category = (options.category) ? options.category :  "Page: " + window.location.pathname;
    let action = (options.action) ? options.action : "Custom Action";
    let label = (options.label) ? options.label : "Event occured";
    let value = (options.value) ? options.value : 0;
    let nonInteraction = (options.nonInteraction) ? options.nonInteraction : false;
    let transport = (options.transport) ? options.transport : "";

    let pathName = window.location.pathname;
    let search = window.location.search;
    let totalPath = pathName + search;

    let eventOptions = {
        category: category,
        action: action
    };

    if (label) {
        eventOptions.label = label;
    }
    if (value) {
        eventOptions.value = value;
    }
    if (nonInteraction) {
        eventOptions.nonInteraction = nonInteraction;
    }
    if (transport) {
        eventOptions.transport = transport;
    }

    var params = {
        screenpath:totalPath,
        screen_type:"event",
        userid:localStorage.getItem("userid"),
        user_type:localStorage.getItem("user_type"),
        user_email:localStorage.getItem("email"),
        dept_code:localStorage.getItem("dept_code"),
        user_roles:localStorage.getItem("roles"),
        event_category:category,
        event_label:label,
        event_value:value,
        event_nonInteraction:nonInteraction,
        event_transport:transport
    }
    // redirectURL.post("/overview/saveeventuseranalytics",params)
    // .then((response) => {
    //     // console.log("Log Page View ", response.data.message)
    // })

    
    try {
        ReactGA.event(eventOptions);
        // console.log("IN GA logEvent, Event was logged, options and eventOptions = ", options, eventOptions);
    } catch (error) {
        // console.log("IN GA logEvent, eventOptions and FAILED ERROR = ", eventOptions, error);
    }
    
}

export const page = {
    
    "url": window.location.pathname,
    "enableGA": true,
    "enableForProd": true,
    title: {
        "containerExportsHighSea": "Container Exports (High Sea)",
    },
    actionType: {
        "gridAction": "Grid Action",
        "counterAction": "Counter Action",
        "formAction": "Form Action",
        "miscAction": "Misc Action",
    },
    action: {
        "formSubmitted": "Form Submitted",
        "formSubmittedGetData": "Form Submitted - Get Data",
        "formSubmittedAssignTransporter": "Form Submitted - Assign Transporter",
        "formSubmittedUpdateNow": "Form Submitted - Update Now",
        "formSubmittedUpdateData": "Form Submitted - Update Data",
        "formSubmittedApplyChanges": "Form Submitted - Apply Changes",
        "formSubmittedSearchByTransporter": "Form Submitted - Search By Transporter",
        "formSubmittedSearchByTruckNo": "Form Submitted - Search By Truck No",
        "formSubmittedGenerate": "Form Submitted - Generate",
        "formSubmittedSelectConsigner": "Form Submitted - Select Consigner",
        "formSubmittedAddMenu": "Form Submitted - Add Menu",
        "formSubmittedUpdateMenu": "Form Submitted - Update Menu",
        "formSubmittedUpdateMenuOrder": "Form Submitted - Update Menu Order",
        "formSubmittedAddRole": "Form Submitted - Add Role",
        "formSubmittedAssignMenus": "Form Submitted - Assign Menus to Role",
        "formSubmittedAssignRolesToUser": "Form Submitted - Assign Roles to User",
        "formSubmittedChangePassword": "Form Submitted - Change Password",
        "formSubmittedResetPassword": "Form Submitted - Reset Password",
        "formSubmittedLogin": "Form Submitted - Login",
        "formSubmittedUserRegistration": "Form Submitted - User Registration",
        "userLogout": "User - Logout",
        "fileUploaded": "File Uploaded",
        "rowUpdated": "Row Updated",
        "viewRoute": "View Route",
        "transitDelay": "Transit Delay",
        "gpsAvailable": "GPS Available",
        "overspeed": "Overspeed",
        "enrouteStoppages": "Enroute Stoppages",
        "nightDrive": "Night Drive",
        "loadingDelay": "Loading Delay",
        "unloadingDelay": "Unloading Delay",
        "deviation": "Deviation",
        "icdToMsil": "ICD to MSIL",
        "msilToIcd": "MSIL to ICD",
        "insideIcd": "Inside ICD",
        "icdToPort": "ICD to Port",
        "insidePort": "Inside Port",
        "highSeas": "High Seas",
        "trackCourier": "Track Courier",
        "roleAssigned": "Role Assigned",
    },
    colDef: {
        exportsConsignments: {
            "msil_transit_delay": "ICD to MSIL",
            "msil_to_icd_transit_delay": "MSIL to ICD",
            "icd_delay": "Inside ICD",
            "rail_transit_delay": "ICD to Port",
            "port_delay": "Inside Port",
            "sea_transit_delay": "High Seas",
        },
        importsConsignments: {
            "msil_transit_delay": "IN Transit to MSIL",
            "icd_delay": "Inside ICD Garhi",
            "rail_transit_delay": "In Transit to ICD Garhi",
            "port_delay": "In Port",
            "sea_transit_delay": "High Seas",
        },
        importsCoils: {
            "high_seas": "High Seas",
            "arrived_at_indian_port": "Arrived at Indian Port",
            "in_transit_to_icd": "In transit to ICD",
            "inside_icd": "Inside ICD",
            "in_transit_to_msil": "In transit to MSIL",
            "inside_msil": "Inside MSIL",
        }
    },
    kpi: {
        "all": "All",
        "transit_delay": "Transit Delays",
        "no_gps_data": "GPS Data NA",
        "overspeeding_exception": "Overspeeding",
        "nightdriving_exception": "Night Driving",
        "loading_delay_exception": "Loading Delay",
        "unloading_delay_exception": "Unloading Delay",
        "enroute_exception": "Stoppages",
    }





}
