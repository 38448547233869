import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
var redirectURL = require('../redirectURL');
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class GenerateQRcode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgSrc : "",
            show : false,
            basicType : "",
            basicTitle : ""
        };
	}
    
	
    componentDidMount(){        
        this.loadPageData();
    }
    
    loadPageData = () =>{
        var delivery_order_number = this.props.match.params.donumber
        if(delivery_order_number !=undefined && delivery_order_number !="")
        {
            delivery_order_number = decode(delivery_order_number);
            var query = {
                delivery_order_number : delivery_order_number
            }
            redirectURL.post("/manage/generateQrCodeForMobile",query).then((response)=>{  
                var message = response.data.message;
                if(message == "Success")
                {            
                    this.setState({
                        imgSrc: response.data.code,
                    })
                }
                else
                {                    
                    this.setState({
                        show : true,
                        basicType : "warning",
                        basicTitle : "No Data Found"
                    });
                }
            })
        }
        else
        {
            this.setState({
                show : true,
                basicType : "warning",
                basicTitle : "No Data Found"
            });
        }
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
        });
    }
    
	render(){
        
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12' style={{textAlign:"center"}}>
                        {(this.state.imgSrc !=undefined && this.state.imgSrc !="")?
                            <img style={{width:"250px",top:"50%",position:"relative"}} src={this.state.imgSrc}/>
                        : ""}
                    </div>
                </div>
            </div>
        );
    }
}

