import React, {Component} from 'react';
import { SideNav, Nav } from "react-sidenav";
import {Link} from 'react-router-dom';
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';
import $ from 'jquery';
//import {MENUITEMS} from '../../../constants/menu'

// Import custom components
//import UserPanel from './user-panel.component';

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedPath: "1", 
            mainmenu: null,
        };
    }
   // state = {  };
    onItemSelection = (arg, e)=> {
        this.setState({ selectedPath: arg.path });
    };

    componentWillMount() {

    }
    componentDidMount = async () =>{
        // onloadMenuBarSettings()
        var currentUrl = window.location.pathname;
        if (!this.state.mainmenu) {
            //console.log("IN componentDidMount, this.state.mainmenu ", this.state.mainmenu);
            let menuslist = localStorage.getItem('usermenus');
            // console.log("IN componentDidMount, menuslist = ", menuslist);
            if (menuslist) {
                const MENUITEMS = JSON.parse(menuslist); 
                await this.setState({
                    mainmenu:MENUITEMS
                })
            }
        }
        // console.log("this.state.mainmenu ", this.state.mainmenu)
        if (this.state.mainmenu) {
            await this.state.mainmenu.filter(items => {
                // console.log("items ", items)
                // console.log("items.sublinks ", items.sublinks)
                if(items.sublinks != undefined)
                {
                    var c = currentUrl.split("/");
                    // console.log('crl  ',items.sublinks)
                    if(items.sublinks.indexOf(c[1]) >= 0)
                    {
                        this.setNavActive(items);
                    }
                }
                if (!items.children){ 
                    if (items.path === currentUrl)
                        this.setNavActive(items)
                    return false 
                }
                items.children.filter(subItems => {
                    if (subItems.path === currentUrl)
                        this.setNavActive(subItems)
                    if (!subItems.children) return false
                    subItems.children.filter(subSubItems => {
                        if (subSubItems.path === currentUrl)
                            this.setNavActive(subSubItems)
                    })
                })
            })
        }

    }

    setNavActive(item) {
        // console.log("item", item)
        var currentUrl = window.location.pathname;
        let MENUITEMS = [...this.state.mainmenu];
        if (this.state.mainmenu) {
            
            MENUITEMS.filter(menuItem => {
                // if(menuItem != item)
                //     menuItem.active = false
                // if(menuItem.children && menuItem.children.includes(item))
                //     menuItem.active = true
                // if(menuItem.children){
                //     // menuItem.children.filter(submenuItems => {
                //     //     if(submenuItems.children && submenuItems.children.includes(item))
                //     //         menuItem.active = true
                //     //     if(submenuItems != item)
                //     //         submenuItems.active = false
                //     // })

                //     menuItem.children.filter(submenuItems => {                    
                //         if(submenuItems.children && submenuItems.children.includes(item))
                //         {
                //             //console.log("Step1")
                //             menuItem.active = true
                //         }
                            
                //         if(submenuItems != item)
                //         {
                //             //console.log("Step2")
                //             submenuItems.active = false
                //             //this.props.context.openCloseSidebar();
                //         }
                //         if(submenuItems.children && submenuItems.children.length > 0)
                //         {
                //             //console.log("Step3")
                //             if(submenuItems.children.includes(item) == true)
                //             {
                //                 //console.log("Step4")
                //                 this.onHideSidebar();
                //             }
                //         } 
                //         else{
                //         // console.log("Step5")
                //             if(item.is_link == true)
                //             {
                //             // console.log("Step6")
                //                 if(menuItem.children.includes(item) == true)
                //                 {
                //                     //console.log("Step7")
                //                     this.onHideSidebar();
                //                 }
                //             }
                            
                //         }
                //             //console.log("submenuItems ", submenuItems)              
                //     })
                // }
                if(menuItem.code == item.code)
                {
                    menuItem.active = "active";
                }
                else{
                    menuItem.active = "";
                }
                // MENUITEMS.push(menuItem);
            })
        }
        // item.active = !item.active

        this.setState({
            mainmenu:MENUITEMS
        })
        var sidebaricon1 = document.querySelector(".sidebariconmb");
            // console.log("asdsa ", sidebaricon.classList. contains('show-n'))
        if(sidebaricon1.classList.contains('show-n') == true)
        {
            onloadMenuBarSettings()
        }
        var sidebaricon = document.querySelector(".sidebaricon");
        if(sidebaricon.classList.contains('show-n') == true)
        {
            onloadMenuBarSettings()
        }
    }
    
    onCloseSidebar(){
        // document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        
        //document.querySelector(".sidebaricon").classList.remove('show-n');
        //document.querySelector(".sidebaricon").classList.add('show-m');
        // document.querySelector(".siconbg").classList.remove('show-m');
        // document.querySelector(".siconbg").classList.add('show-n');
        // document.querySelector(".sidemenu").classList.remove('l20n');
        // document.querySelector(".sidemenu").classList.add('whgb');
        document.querySelector(".sidebrclose").classList.remove('show-n');
        document.querySelector(".sidebrclose").classList.add('show-m');

        
        document.querySelector(".sidebar-blueicon").classList.remove('show-m');
        document.querySelector(".sidebar-blueicon").classList.add('show-n');
        document.querySelector(".collapstext").classList.remove('show-m');
        document.querySelector(".collapstext").classList.add('show-n');

        //console.log(document.querySelector(".sidebrclose").classList);
        var sidebaricon = document.querySelector(".sidebaricon");
        // if(sidebaricon.classList.contains('show-n'))
        // {
            //console.log("Yr")
            
            // document.querySelector(".sidebar-menu").classList.add("w55");
            // document.querySelector(".page-sidebar").classList.add("w55");
            // document.querySelector(".sidebaricon").classList.remove('show-n');
            // document.querySelector(".sidebaricon").classList.add('show-m');   
            
            document.querySelector(".sidebar-menu").classList.add("w55");
            document.querySelector(".page-sidebar").classList.add("w55");
            document.querySelector(".sidebaricon").classList.remove('show-n');
            document.querySelector(".sidebaricon").classList.add('show-m'); 


        // }
        // if(sidebaricon.classList.contains('show-m'))
        // {
        //     document.querySelector(".sidebaricon").classList.remove('show-m');
        //     document.querySelector(".sidebaricon").classList.add('show-n');    
        // }
        // if(document.querySelector(".sidebrclose").hasClass('show-m'))
        // {            
        //     document.querySelector(".sidebrclose").classList.remove('show-m');
        //     document.querySelector(".sidebrclose").classList.add('show-n');
        // }
    }
    onHideSidebar(){
        document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        document.querySelector(".sidebaricon").classList.remove('show-n');  
        document.querySelector(".sidebaricon").classList.add('show-m');  
        // document.querySelector(".siconbg").classList.remove('show-m');
        // document.querySelector(".siconbg").classList.add('show-n');
        document.querySelector(".sidemenu").classList.remove('l20n');
        document.querySelector(".sidemenu").classList.add('whgb');
        document.querySelector(".sidebrclose").classList.remove('show-n');
        document.querySelector(".sidebrclose").classList.add('show-m');
    }
    render() {
        const theme = {
            selectionColor: "#C51162"
        };
        //console.log("IN render, 1 this.state.mainmenu ", this.state.mainmenu);
        let mainmenu = null;
        if (!this.state.mainmenu) {
           // console.log("IN Render, 2 this.state.mainmenu ", this.state.mainmenu);
            let menuslist = localStorage.getItem('usermenus');
            if (menuslist) {
                const MENUITEMS = JSON.parse(menuslist); 
                this.setState({
                    mainmenu:MENUITEMS
                })
            }
        }
       // console.log("this.state.mainmenu ", this.state.mainmenu);
       var sideTitlecls = "";
       var sideHeadercls = "";
       if(localStorage.getItem("is_enmovil_transporter") == 1)
       {
            var sideTitlecls = "sideTitlecls";
            var sideHeadercls = "sideHeadercls";
       }
    //    console.log("this.state.mainmenu ", this.state.mainmenu)
       if (this.state.mainmenu) {
            mainmenu = this.state.mainmenu.map((menuItem, i) =>
                <li className={`${menuItem.active?'active':''}`} key={i}>
                    { (menuItem.group_heading)?
                        <div className={"sidebar-title "+sideTitlecls}>{menuItem.group_heading}</div>
                        :''}
                    { (menuItem.is_link === false )?
                    <a className={"sidebar-header "+sideHeadercls} href="javascript:void(0)" onClick={() => this.setNavActive(menuItem)}>
                        <i className={`icon-${menuItem.icon}`}></i>
                        <span>{menuItem.name}</span>
                        <i className="fa fa-angle-right pull-right"></i>
                    </a>
                        :''}
                    { (menuItem.is_link === true )?
                    (menuItem.active == "active")?
                        <Link 
                            to={`${process.env.PUBLIC_URL}${menuItem.path}`} 
                            className={`sidebar-header ${menuItem.active?' ':' '}`+sideHeadercls} 
                            onClick={() => this.setNavActive(menuItem)}
                            >
                            {/* <i className={`nav-icon icon-${menuItem.icon}`}></i> */}
                            <span className="ml-10p ic">
                                {/* <img src={require(`../../../assets/icons/menus/${menuItem.menu_icon}_white_s.png`)} /> */}
                                {menuItem.active ?
                                <i style={{color:"white"}} className={"fa fa-"+menuItem.menu_icon}></i>
                                :
                                <i style={{color:"#198754"}} className={"fa fa-"+menuItem.menu_icon}></i>
                                }
                            </span>
                            {/* <i className={`nav-icon icofont icofont-${menuItem.icon}`}></i> */}
                            <span className="nav-text">{menuItem.name}</span>
                            {/* {menuItem.children?
                            <i className="fa fa-angle-right pull-right"></i>:''} */}
                        </Link>
                        :
                        <Link 
                            to={`${process.env.PUBLIC_URL}${menuItem.path}`} 
                            className={`sidebar-header ${menuItem.active?' ':' '}`+sideHeadercls+" "+menuItem.menu_icon} 
                            onClick={() => this.setNavActive(menuItem)}
                            >
                            {/* <i className={`nav-icon icon-${menuItem.icon}`}></i> */}
                            <span  className="ml-10p ic" style={{color:"red"}}>
                                {/* <img src={require(`../../../assets/icons/menus/${menuItem.menu_icon}_s.png`)} /> */}
                                {menuItem.active ?
                                    <i style={{color:"white"}} className={"fa fa-"+menuItem.menu_icon}></i>
                                    :
                                    <i style={{color:"#198754"}} className={"fa fa-"+menuItem.menu_icon}></i>
                                }
                            </span>
                            {/* <i className={`nav-icon icofont icofont-${menuItem.icon}`}></i> */}
                            <span className="nav-text">{menuItem.name}</span>
                            {/* {menuItem.children?
                            <i className="fa fa-angle-right pull-right"></i>:''} */}
                        </Link>
                        :''}
                    {menuItem.children?
                    <ul 
                    className={`sidebar-submenu ${menuItem.active?'menu-open':''}`} 
                    style={ menuItem.active?{ opacity: 1, transition: 'opacity 500ms ease-in' }: {}}
                    >
                        {menuItem.children.map((childrenItem, index) =>
                            <li key={index} className={childrenItem.children?childrenItem.active?'active':'':''}>
                                { (childrenItem.is_link === false )?
                                <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenItem)} >
                                    <i className="fa fa-angle-right"></i>{childrenItem.name}</a>
                                    :''}

                                { (childrenItem.is_link === true )?
                                    <Link
                                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                        className={childrenItem.active?'active':''}
                                        onClick={() => this.setNavActive(childrenItem)}
                                    >
                                        <i className="fa fa-angle-right"></i>{childrenItem.name} </Link>
                                    :''}
                                {childrenItem.children?
                                    <ul className={`sidebar-submenu ${childrenItem.active?'menu-open':''}`}>
                                        {childrenItem.children.map((childrenSubItem, key) =>
                                            <li className={childrenSubItem.active?'active':''} key={key}>
                                                {/* { (childrenSubItem.is_link === true )?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active?'active':''}
                                                    >
                                                        <i className="fa fa-angle-right"></i>{childrenSubItem.name}</Link>
                                                :''} */}

                                                { (childrenSubItem.is_link === false )?
                                                    <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenSubItem)} >
                                                        <i className="fa fa-angle-right"></i>{childrenSubItem.name}</a>
                                                :''}
                                                { (childrenSubItem.is_link === true )?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active?'active':''}
                                                        onClick={() => this.setNavActive(childrenSubItem)}
                                                    >
                                                        <i className="fa fa-angle-right"></i>{childrenSubItem.name}</Link>
                                                :''}

                                            </li>
                                            )}
                                    </ul>
                                    :''}
                            </li>
                        )}
                    </ul>
                        :''}
                </li>
            )
        }
        var sideMenuCls = "";
        if(localStorage.getItem("is_enmovil_transporter") == 1)
        {
            var sideMenuCls = "sideMenuCls";
        }
        return (
            <div className={"page-sidebar w0 custom-scrollbar page-sidebar-open "+sideMenuCls}>
               
                {/* <ul className="sidebar-menu mt-80p">
                    {mainmenu}
                </ul>
                 */}
                <nav class="sidebar-menu">
                    <div class="scrollbar" id="style-1">
                        <ul>
                            
                            {mainmenu}
                    
                        </ul>
                    </div>

                </nav>
                <a href="javascript:void(0)" className="mobnone sidebar-blueicon show-n" onClick={this.onCloseSidebar.bind(this)}>
                    <span className="sidebaropen-icon">
                        <img src={require('../../../assets/icons/menu.png')} className="" onClick={this.onCloseSidebar.bind(this)} />
                        <span className="collapstext show-n" style={{marginLeft:"10px"}}>COLLAPSE MENU</span>
                    </span>
                </a>
                <a href="javascript:void(0)" className="mobnone mobclose sidebar-blueicon show-n" onClick={this.onCloseSidebar.bind(this)}>
                    <img src={require('../../../assets/icons/menu.png')} style={{width:"30px"}} className="sidebaropen-icon" onClick={this.onCloseSidebar.bind(this)} />
                    
                </a>
            </div>
        );
    }
}
$(document).ready(function(){
    $(".mboverlay-part").on("click",function(){
        onloadMenuBarSettings()
    })
    $(".sidebar-header").on("click",function(){
       
        // alert(window.screen.width)
        if(window.screen.width < 900)
        {
            
            var sidebaricon1 = document.querySelector(".sidebariconmb");
            // console.log("asdsa ", sidebaricon.classList. contains('show-n'))
            if(sidebaricon1.classList.contains('show-n') == true)
            {
                document.querySelector(".sidebar-menu").classList.remove("w345");
                document.querySelector(".page-sidebar").classList.remove("w345");
                document.querySelector(".sidebar-menu").classList.add("w0");
                document.querySelector(".page-sidebar").classList.add("w0");
                // document.querySelector(".mobclx").classList.remove('show-m');
                // document.querySelector(".mobclx").classList.add('show-n'); 
                document.querySelector(".mobic").classList.remove('show-n');
                document.querySelector(".mobic").classList.add('show-m');  
                document.querySelector(".mboverlay-part").classList.remove("show-m");
                document.querySelector(".mboverlay-part").classList.add("show-n");  
            }
            else{
                document.querySelector(".sidebar-menu").classList.remove("w0");
                document.querySelector(".page-sidebar").classList.remove("w0");
                document.querySelector(".sidebar-menu").classList.remove("w55");
                document.querySelector(".page-sidebar").classList.remove("w55");
                document.querySelector(".sidebar-menu").classList.add("w345");
                document.querySelector(".page-sidebar").classList.add("w345");
                document.querySelector(".mobic").classList.remove('show-m');
                document.querySelector(".mobic").classList.add('show-n');
                document.querySelector(".mboverlay-part").classList.remove("show-n");
                document.querySelector(".mboverlay-part").classList.add("show-m");
                
            }
        }else{
            document.querySelector(".sidebrclose").classList.remove('show-n');
            document.querySelector(".sidebrclose").classList.add('show-m');
            document.querySelector(".sidebar-blueicon").classList.remove('show-m');
            document.querySelector(".sidebar-blueicon").classList.add('show-n');
            document.querySelector(".collapstext").classList.remove('show-m');
            document.querySelector(".collapstext").classList.add('show-n');
            var sidebaricon = document.querySelector(".sidebaricon");
            document.querySelector(".sidebar-menu").classList.add("w55");
            document.querySelector(".page-sidebar").classList.add("w55");
            document.querySelector(".sidebaricon").classList.remove('show-n');
            document.querySelector(".sidebaricon").classList.add('show-m'); 
        }
    })
})

function onloadMenuBarSettings(){
    if(window.screen.width < 900)
        {
            
            var sidebaricon1 = document.querySelector(".sidebariconmb");
            // console.log("asdsa ", sidebaricon.classList. contains('show-n'))
            if(sidebaricon1.classList.contains('show-n') == true)
            {
                document.querySelector(".sidebar-menu").classList.remove("w345");
                document.querySelector(".page-sidebar").classList.remove("w345");
                document.querySelector(".sidebar-menu").classList.add("w0");
                document.querySelector(".page-sidebar").classList.add("w0");
                // document.querySelector(".mobclx").classList.remove('show-m');
                // document.querySelector(".mobclx").classList.add('show-n'); 
                document.querySelector(".mobic").classList.remove('show-n');
                document.querySelector(".mobic").classList.add('show-m');  
                document.querySelector(".mboverlay-part").classList.remove("show-m");
                document.querySelector(".mboverlay-part").classList.add("show-n");  
            }
            else{
                document.querySelector(".sidebar-menu").classList.remove("w0");
                document.querySelector(".page-sidebar").classList.remove("w0");
                document.querySelector(".sidebar-menu").classList.remove("w55");
                document.querySelector(".page-sidebar").classList.remove("w55");
                document.querySelector(".sidebar-menu").classList.add("w345");
                document.querySelector(".page-sidebar").classList.add("w345");
                document.querySelector(".mobic").classList.remove('show-m');
                document.querySelector(".mobic").classList.add('show-n');
                document.querySelector(".mboverlay-part").classList.remove("show-n");
                document.querySelector(".mboverlay-part").classList.add("show-m");
                
            }
        }else{
            document.querySelector(".sidebrclose").classList.remove('show-n');
            document.querySelector(".sidebrclose").classList.add('show-m');
            document.querySelector(".sidebar-blueicon").classList.remove('show-m');
            document.querySelector(".sidebar-blueicon").classList.add('show-n');
            document.querySelector(".collapstext").classList.remove('show-m');
            document.querySelector(".collapstext").classList.add('show-n');
            var sidebaricon = document.querySelector(".sidebaricon");
            document.querySelector(".sidebar-menu").classList.add("w55");
            document.querySelector(".page-sidebar").classList.add("w55");
            document.querySelector(".sidebaricon").classList.remove('show-n');
            document.querySelector(".sidebaricon").classList.add('show-m'); 
        }
}
export default Sidebar