import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';
import Modal from 'react-responsive-modal';
import configureAction from './configureaction';
import configureApproveAction from './configureapproveaction';
import configureWhApprove from './configurewhapproveaction';
import configureTptReject from './configuretptrejectaction';
import assignTruckSelector from './assigntruckselector';
import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDDHHMMSS
} from '../common/utils';
import { uniqueKey } from 'highcharts';
import { element } from 'prop-types';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}

export default class AssignedTrucks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
                configureAction : configureAction,
                configureApproveAction : configureApproveAction,
                assignTruckSelector : assignTruckSelector,
                configureWhApprove : configureWhApprove,
                configureTptReject : configureTptReject
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams:{},
            animateRows: true,
			pageTitle: "Assigned Trucks",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add Plant",
            rowId : "",
            transporters : [],
            transporter : {label:"Select Transporter",value:""},
            customer : {label:"Select Customer",value:""},
            allrowData : [],
            truckAssigned : [],
            securityRejected : [],
            gate1In : [],
            pendingForVerifictaion : [],
            verificationApproved : [],
            verificationRejected : [],
            gate2In : [],
            loadingStarted : [],
            loadingCompleted : [],
            documentationPending : "",
            documentationCompleted : "",
            gate2Out : [],
            gate1Out : [],
            open: false,
            labelName : "",
            sales_order_number : "",
            delivery_order_number : "",
            status : "",
            documentation_completed : "",
            rowDetails : "",
            startDate : "",
            plantsList : [],
            customersList : [],
            pageType : 1,
            assigned : "activet",
            unassigned : "",
            unAssignrowData : [],
            sliderType : 1,
            salesOrderInfo : [],
            trucksList : [],
            myFile : "",
            uploadedFile: "",
            uploaded_from: "",
            lr_no: "",
            item_quantity: "",
            dateField:moment.parseZone().format("YYYY-MM-DD HH:mm"),
            truck_capacity : "",
            itemDetails : [],
            confirm_quantity : "",
            stolList : [],
            batch : [],
            itemQtyDetails : [],
        };
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.getItemQuantity = this.getItemQuantity.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        loadDateTimeScript();
        var fdate = moment.parseZone().format("DD-MM-YYYY");
        var currentdate = moment.parseZone().format("DD-MM-YYYY");
        var params = {
            status : 1
        }
        redirectURL.post("/manage/getTransportersList",params).then(async(response)=>{
            console.log(response.data);
            this.setState({
                transporters : response.data
            })
        }).catch((e)=>{
            console.log(e);
        })
        redirectURL.post("/manage/getCustomerAndPlantsList").then(async(response)=>{
            var plantsList = response.data.plants;
            var customersList = response.data.customers;
            this.setState({
                plantsList : plantsList,
                customersList : customersList,
            })
        }).catch((e)=>{
            console.log(e);
        })
        var tparams = {};
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            tparams.transporter_code = localStorage.getItem("transportercode");
            redirectURL.post("/manage/getTrucksList",tparams).then(async(response)=>{
                console.log(response.data);
                this.setState({
                    trucksList : response.data,
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
        this.loadPageData();
    }
    
    loadPageData = () =>{
        var params = {};
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            params.transporter_code = localStorage.getItem("transportercode");
        }
        if(localStorage.getItem("plant_code") != undefined && localStorage.getItem("plant_code") != "undefined" && localStorage.getItem("plant_code") != "")
        {
            params.plant_code = localStorage.getItem("plant_code");
        }
        
        params.userRoles = localStorage.getItem("roles");
        var pageType = this.state.pageType;
        if(pageType == 1)
        {
            redirectURL.post("/manage/getAssignedTrucksList",params).then(async(response)=>{
                console.log(response.data);
                var rowData = response.data;
                var truckAssigned = rowData.filter((e) => e.status == 1 && e.security_approve_status !== 2);
                var securityRejected = rowData.filter((e) => e.status == 1 && e.security_approve_status == 2);
                var gate1In = rowData.filter((e) => e.status == 2);
                // var pendingForVerifictaion = rowData.filter((e) => e.status == 2 && e.approve_status == 0);
                // var verificationApproved = rowData.filter((e) => e.status == 2 && e.approve_status == 1);
                // var verificationRejected = rowData.filter((e) => e.status == 2 && e.approve_status == 2);
                var gate2In = rowData.filter((e) => e.status == 3);
                var loadingStarted = rowData.filter((e) => e.status == 4);
                var pendingForVerifictaion = rowData.filter((e) => e.status == 3 && e.approve_status == 0);
                var verificationApproved = rowData.filter((e) => e.status == 3 && e.approve_status == 1);
                var verificationRejected = rowData.filter((e) => e.status == 3 && e.approve_status == 2);
                var loadingCompleted = rowData.filter((e) => e.status == 5);
                var documentationPending = rowData.filter((e) => e.status == 5 && e.documentation_completed == 0);
                var documentationCompleted = rowData.filter((e) => e.status == 5 && e.documentation_completed == 1);
                var gate2Out = rowData.filter((e) => e.status == 6);
                var gate1Out = rowData.filter((e) => e.status == 7);
                this.setState({
                    allrowData : rowData,
                    rowData : rowData,
                    truckAssigned : truckAssigned,
                    securityRejected : securityRejected,
                    gate1In : gate1In,
                    pendingForVerifictaion : pendingForVerifictaion,
                    verificationApproved : verificationApproved,
                    verificationRejected : verificationRejected,
                    gate2In : gate2In,
                    loadingStarted : loadingStarted,
                    loadingCompleted : loadingCompleted,
                    documentationPending : documentationPending,
                    documentationCompleted : documentationCompleted,
                    gate2Out : gate2Out,
                    gate1Out : gate1Out,
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
        else
        {
            redirectURL.post("/manage/getUnAssignedTrucksList",params).then(async(response)=>{
                console.log(response.data);
                var rowData = response.data;
                this.setState({
                    unAssignrowData : rowData,
                })
            }).catch((e)=>{
                console.log(e);
            })
        }
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        this.colState = this.gridColumnApi.getColumnState();
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onGridReady1 = params => {
	    this.gridApi1 = params.api;
	    this.gridColumnApi1 = params.columnApi;
	};
	 onGridState1 = () =>{
        this.colState1 = this.gridColumnApi1.getColumnState();
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);
	 }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            assignslide:"",
            lr_no:"",
            item_quantity:"",
            myFile:"",
            item_chk_list:"",
            confirm_quantity:"",
            agi_do_number:"",
            stolList : [],
            batch : [],
        });
        this.resetSecurityChecks();
    }
    resetSecurityChecks = () => {
        $(".form-check-input").prop('checked', false);
        $(".datepicker").val("");
        $("#security_verification").val("");
    }
    showBulkUpload(){
        this.setState({
            sliderType : 2,
            sliderTitle : "Bulk Upload",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            file:'',
            assignslide:''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                { 
                    name: 'plant_code',
                    inputName: 'plant_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'plant_name',
                    inputName: 'plant_name',
                    required: false,
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData,"csvData")
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
    
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    updateStatus = (e) => {
        e.preventDefault();
        var status =  this.state.status;
        var agi_do_number =  this.state.agi_do_number;
        var generate_do_number = 0;
        var documentation_completed = this.state.documentation_completed;
        var dateFieldcheck  = 1;
        if(status == 1)
        {
            var security_verification = $("#security_verification").val();
            if(security_verification == 1)
            {
                var dateField = $("#dateField").val();
                if(dateField !=undefined && dateField !="")
                {
                    dateFieldcheck = 1;
                }
                else
                {
                    dateFieldcheck = 0;
                }
            }
            else
            {
                dateFieldcheck = 1;
                dateField = "";
            }
        }
        else
        {            
            if(status == 2)
            {
                if(agi_do_number !=undefined && agi_do_number !="")
                {
                    var dateField = $("#dateField").val();
                    if(dateField !=undefined && dateField !="")
                    {
                        dateFieldcheck = 1
                    }
                    else
                    {
                        dateFieldcheck = 0;
                    }
                }
                else
                {
                    dateFieldcheck = 1;
                    dateField = "";
                }
            }
            else
            {
                var dateField = $("#dateField").val();
                if(dateField !=undefined && dateField !="")
                {
                    dateFieldcheck = 1
                }
                else
                {
                    dateFieldcheck = 0;
                }
            }
        }
        var delivery_order_number =  this.state.delivery_order_number;
        var labelName =  this.state.labelName;
        console.log(dateField,delivery_order_number,status);
        if(dateFieldcheck == 1)
        {
            if(delivery_order_number !=undefined && delivery_order_number !="" && status !=undefined && status !="")
            {
                this.setState({
                    loadshow : "show-m",
                    overly : "show-m"
                });
                var check = 0;
                var errorMsg = "";
                var rowDetails = this.state.rowDetails;
                var uploadedFile = "";
                var params = {
                    dateField : dateField,
                    delivery_order_number : delivery_order_number,
                    status : status,
                    agi_do_number : agi_do_number,
                    documentation_completed : documentation_completed,
                    status_updated_by : localStorage.getItem("email"),
                }
                if(status == 1)
                {
                    var driver_licence_date = $("#driver_licence_date").val();
                    var truck_insurance_date = $("#truck_insurance_date").val();
                    var truck_fitness_date = $("#truck_fitness_date").val();
                    var puc_date = $("#puc_date").val();
                    var truck_road_tax_date = $("#truck_road_tax_date").val();
                    var driver_licence = $("input[name='driver_licence']:checked").val();
                    var truck_insurance = $("input[name='truck_insurance']:checked").val();
                    var puc = $("input[name='puc']:checked").val();
                    var truck_fitness = $("input[name='truck_fitness']:checked").val();
                    var truck_road_tax = $("input[name='truck_road_tax']:checked").val();
                    var reverse_horn = $("input[name='reverse_horn']:checked").val();
                    var side_view_mirror = $("input[name='side_view_mirror']:checked").val();
                    var hand_break = $("input[name='hand_break']:checked").val();
                    if(driver_licence_date !=undefined && driver_licence_date !="" && truck_insurance_date !=undefined && truck_insurance_date !="" && truck_fitness_date !=undefined && truck_fitness_date !="" && puc_date !=undefined && puc_date !="" && truck_road_tax_date !=undefined && truck_road_tax_date !="" && driver_licence !=undefined && driver_licence !="" && truck_insurance !=undefined && truck_insurance !="" && puc !=undefined && puc !="" && truck_fitness !=undefined && truck_fitness !="" && truck_road_tax !=undefined && truck_road_tax !="" && security_verification !=undefined && security_verification !="")
                    {
                        check = 1;
                        var security_checklist = {
                            driver_licence_date : driver_licence_date,
                            truck_insurance_date : truck_insurance_date,
                            truck_fitness_date : truck_fitness_date,
                            puc_date : puc_date,
                            truck_road_tax_date : truck_road_tax_date,
                            driver_licence : driver_licence,
                            truck_insurance : truck_insurance,
                            puc : puc,
                            truck_fitness : truck_fitness,
                            truck_road_tax : truck_road_tax,
                            reverse_horn : reverse_horn,
                            side_view_mirror : side_view_mirror,
                            hand_break : hand_break,
                        }
                        params.security_checklist = security_checklist;
                        params.security_approve_status = security_verification;
                    }
                    else
                    {
                        check = 0;
                        errorMsg = "All fields are mandatory";
                    }
                }
                if(status == 2)
                {
                    if(agi_do_number !=undefined && agi_do_number !="")
                    {
                        if(rowDetails.gate1_in_time !=undefined && rowDetails.gate1_in_time !="")
                        {
                            var gate1_in_time = moment.parseZone(new Date(rowDetails.gate1_in_time)).utcOffset("-05:30")._d;
                            var dateToChk = dateField+":00";
                            dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                            var comparision = moment(dateToChk).isAfter(moment(gate1_in_time));
                            console.log(gate1_in_time,dateToChk)
                            console.log(comparision,"comparision")
                            if(comparision == true)
                            {
                                check = 1;
                            }
                            else
                            {
                                check = 0;
                                errorMsg = "Gate-2 In Time Should be Grater than Gate-1 In Time";
                            }
                        }
                    }
                    else
                    {
                        var item_chk_list = $("input[name='item_chk_list']:checked").val();
                        if(item_chk_list !=undefined && item_chk_list !="")
                        {
                            var confirm_quantity = this.state.confirm_quantity;
                            if(confirm_quantity !=undefined && confirm_quantity !="")
                            {
                                // if (confirm_quantity.match(/[^0-9]/g))
                                // {
                                //     check = 0;
                                //     errorMsg = "Only Numerical Characters allowed in Item Quantity";
                                // }
                                // else
                                // {                                
                                //     check = 1;
                                // }
                                check = 1;
                            }
                            else
                            {
                                check = 0;
                                errorMsg = "Item quantity can not be empty";
                            }
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Please select the Item to Continue";
                        }
                    }
                }
                if(status == 3)
                {
                    if(rowDetails.gate2_in_time !=undefined && rowDetails.gate2_in_time !="")
                    {
                        var gate2_in_time = moment.parseZone(new Date(rowDetails.gate2_in_time)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(gate2_in_time));
                        console.log(gate2_in_time,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Loading Started On Time Should be Grater than Gate-2 In Time";
                        }
                    }
                }
                if(status == 4)
                {
                    if(rowDetails.loading_started_on !=undefined && rowDetails.loading_started_on !="")
                    {
                        var loading_started_on = moment.parseZone(new Date(rowDetails.loading_started_on)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(loading_started_on));
                        console.log(loading_started_on,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                            uploadedFile = this.state.myFile;
                            if(uploadedFile !=undefined && uploadedFile !="")
                            {
                                check = 1;
                            }
                            else
                            {
                                check = 0;
                                errorMsg = "Upload the image to continue";                                
                            }
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Loading Completed On Time Should be Grater than Loading Started On Time";
                        }
                    }
                }
                if(status == 5 && documentation_completed == 0)
                {
                    if(rowDetails.loading_completed_on !=undefined && rowDetails.loading_completed_on !="")
                    {
                        var loading_completed_on = moment.parseZone(new Date(rowDetails.loading_completed_on)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(loading_completed_on));
                        console.log(loading_completed_on,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                            var lr_no = $("#lr_no").val();
                            var no_boxes = $("#no_boxes").val();
                            var storage_location = $("#storage_location").val();
                            var batch = $("#batch").val();
                            var item_quantity = $("#item_quantity").val();
                            if(lr_no !=undefined && lr_no !="" && no_boxes !=undefined && no_boxes !="" && storage_location !=undefined && storage_location !="" && item_quantity !=undefined && item_quantity !="")
                            {
                                check = 1;
                                params.lr_no = lr_no;
                                params.no_boxes = no_boxes;
                                params.storage_location = storage_location;
                                params.batch = batch;
                                params.item_quantity = item_quantity;
                                params.item_details = rowDetails.loaded_item_details;
                            }
                            else
                            {                                
                                check = 0;
                                errorMsg = "All fieds are mandatory";
                            }
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Documentation Completed Time Should be Grater than Loading Completed On Time";
                        }
                    }
                }
                if(status == 5 && documentation_completed == 1)
                {
                    if(rowDetails.documentation_completed_on !=undefined && rowDetails.documentation_completed_on !="")
                    {
                        var documentation_completed_on = moment.parseZone(new Date(rowDetails.documentation_completed_on)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(documentation_completed_on));
                        console.log(documentation_completed_on,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Gate-2 Out Time Should be Grater than Documentation Completed Time";
                        }
                    }
                }
                if(status == 6)
                {
                    if(rowDetails.gate2_out_time !=undefined && rowDetails.gate2_out_time !="")
                    {
                        var gate2_out_time = moment.parseZone(new Date(rowDetails.gate2_out_time)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(gate2_out_time));
                        console.log(gate2_out_time,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Gate-1 Out Time Should be Grater than Gate-2 Out Time";
                        }
                    }
                }
                if(check == 1)
                {
                    if(status == 2)
                    {
                        if(agi_do_number == "" || agi_do_number == undefined)
                        {
                            generate_do_number = 1;
                            params.item_chk_list = item_chk_list;
                            params.confirm_quantity = confirm_quantity;
                        }
                    }
                    if(status == 4)
                    {
                        params.uploadedFile = uploadedFile;
                        // params.item_chk_list = item_chk_list;
                    }
                    params.generate_do_number = generate_do_number;
                    console.log(params,"params")
                    this.setState({                        
                        loadshow : "show-m",
                        overly : "show-m",
                    });
                    redirectURL.post("/manage/changeAssignedTruckStatus",params).then((response)=>{
                        console.log(response);
                        if(response.data.message == "Success")
                        {
                            var apiCheck = response.data.apiCheck;
                            var respFromApi = response.data.respFromApi;
                            if(apiCheck == 0)
                            {
                                var basicTitle = respFromApi;
                                if(respFromApi.includes("PGI is Done") == true)
                                {
                                    var basicType = "success";
                                }
                                else
                                {
                                    var basicType = "warning";
                                }
                            }
                            else
                            {
                                var basicTitle="Updated Successfully.";
                                var basicType = "success";
                            }
                            this.setState({
                                basicTitle:basicTitle,
                                basicType : basicType,
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n",
                                bulkslide:"",
                                assignslide:"",                                
                                lr_no:"",
                                item_quantity:"",
                                myFile:"",
                                item_chk_list:"",
                                confirm_quantity:"",
                                agi_do_number:"",
                                stolList : [],
                                batch : [],
                            })
                            $("#dateField").val("");
                            this.loadPageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:response.data.message,
                                basicType : "warning",
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n",
                                lr_no:"",
                                item_quantity:"",
                                myFile:"",
                            })
                        }
                    })
                }
                else
                {
                    this.setState({
                        show : true,
                        basicTitle : errorMsg,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    });
                }
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Something went wrong.",
                    basicType : "warning",
                    show : true,
                    loadshow : "show-n",
                    overly : "show-n"
                });
            }
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : labelName+" Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    updateApproveStatus = (e) => {
        e.preventDefault();
        var delivery_order_number =  this.state.delivery_order_number;
        var truck_condition = $("input[name='truck_condition']:checked").val();
        var abnprojected = $("input[name='abnprojected']:checked").val();
        var acdpcrcontamination = $("input[name='acdpcrcontamination']:checked").val();
        var foulsmell = $("input[name='foulsmell']:checked").val();
        var hygeniccondition = $("input[name='hygeniccondition']:checked").val();
        var tarpaulins = $("input[name='tarpaulins']:checked").val();
        var tworopes = $("input[name='tworopes']:checked").val();
        var loadingpractices = $("input[name='loadingpractices']:checked").val();
        var verification = $("#verification").val();
        var remarks = $("#remarks").val();
        if(truck_condition !=undefined && truck_condition !="" && abnprojected !=undefined && abnprojected !="" && acdpcrcontamination !=undefined && acdpcrcontamination !="" && foulsmell !=undefined && foulsmell !="" && hygeniccondition !=undefined && hygeniccondition !="" && tarpaulins !=undefined && tarpaulins !="" && tworopes !=undefined && tworopes !="" && loadingpractices !=undefined && loadingpractices !="" && verification !=undefined && verification !="" && remarks !=undefined && remarks !="")
        {        
            var checkList =  {
                truck_condition : truck_condition,
                abnprojected : abnprojected,
                acdpcrcontamination : acdpcrcontamination,
                foulsmell : foulsmell,
                hygeniccondition : hygeniccondition,
                tarpaulins : tarpaulins,
                tworopes : tworopes,
                loadingpractices : loadingpractices,
            }
            var params = {
                approve_type : 1,
                approve_status : verification,
                remarks : remarks,
                check_list : checkList,
                delivery_order_number : delivery_order_number,
                status_updated_by : localStorage.getItem("email"),
            }
            console.log(params,"params")
            redirectURL.post("/manage/changeAssignedTruckApproveStatus",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        bulkslide:"",
                        assignslide:""
                    })
                    $("#dateField").val("");
                    this.loadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }
            })
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Fields Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/manage/savebulkplants",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        assignslide:"",
                        overly:'show-n',
                    })
                    this.loadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    addNewRow = () => {
        window.location.href="/assigntruck";
    }
    onCellClicked = async(params) =>{
        if(params.colDef.field == "item_detais")
        {
            if(params != "")
            {
                this.setState({
                    detailCellRendererParams : {},
                });
                var itemDetails=[];
                console.log("Step 3 ",itemDetails)
                var propsdata = params;
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: "Item Unique Code",field: "item_unique_code", filter:true,resizable: true },
                                { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                                { headerName: "Item",field: "SO_ITEM", filter:true,resizable: true },
                                { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                                { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData: function(param) {
                            if(params.data.item_details !=undefined && params.data.item_details !="")
                            {
                                itemDetails = JSON.parse(params.data.item_details);
                            }
                            param.successCallback(itemDetails);                            
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'item_detais')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            //console.log("Child ", params);
                        params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "qr_code")
        {
            var query = {
                delivery_order_number : params.data.delivery_order_number
            }
            redirectURL.post("/manage/generateQrCode",query).then((response)=>{
                console.log(typeof(response.data),"typeof");
                
                this.setState({
                    imgSrc: response.data,
                    uploadedFile: "",
                    uploaded_from: "",
                    open: true,
                })
            })
        }
        if(params.colDef.field == "delivery_order_number")
        {
            if(params != "")
            {
                this.setState({
                    detailCellRendererParams : {},
                });
                var itemDetails=[];
                console.log("Step 3 ",itemDetails)
                var propsdata = params;
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: "Status",field: "status", filter:true,resizable: true,valueGetter:function(e){
                                    if(e.data.security_approve_status == 2)
                                    {
                                        return "Security Rejected";
                                    }
                                    if(e.data.status == 1)
                                    {
                                        return "Truck Assgined";
                                    }
                                    else if(e.data.status == 2)
                                    {
                                        if(e.data.generate_do_number == 1)
                                        {
                                            return "Delivery Order Generated";
                                        }
                                        else
                                        {
                                            return "Gate-1 In";
                                        }
                                    }
                                    else if(e.data.status == 3)
                                    {
                                        return "Gate-2 In";
                                    }
                                    else if(e.data.status == 4)
                                    {
                                        return "Loading Started";
                                    }
                                    else if(e.data.status == 5)
                                    {
                                        if(e.data.documentation_completed == 1)
                                        {
                                            return "Documentation Completed";
                                        }
                                        else
                                        {
                                            return "Loading Completed";
                                        }
                                    }
                                    else if(e.data.status == 6)
                                    {
                                        return "Gate-2 Out";
                                    }
                                    else if(e.data.status == 7)
                                    {
                                        return "Gate-1 Out";
                                    }
                                    else if(e.data.status == 8)
                                    {
                                        return "Truck Verification Approved";
                                    }
                                    else if(e.data.status == 9)
                                    {
                                        return "Truck Verification Rejected";
                                    }
                                }},
                                { headerName: "Date",field: "status_date", filter:true,resizable: true,  
                                valueGetter: function(params){
                                    if(params.data.status_date !=undefined && params.data.status_date !="")
                                    {
                                        return getHyphenDDMMMYYYYHHMM(params.data.status_date);
                                    }
                                    else
                                    {
                                        if(params.data.security_approve_status == 2 || params.data.status == 2)
                                        {
                                            if(params.data.created_date !=undefined && params.data.created_date !="")
                                            {
                                                return getHyphenDDMMMYYYYHHMM(params.data.created_date);
                                            }
                                        }
                                    }
                                }},
                                { headerName: "Status Updated By",field: "status_updated_by", width:250, filter:true,resizable: true },
                                { headerName: "Remarks",field: "remarks", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData:async function(param) {
                            param.successCallback([]);
                            await redirectURL.post("/manage/getDoStatusLogs",{
                                delivery_order_number : param.data.delivery_order_number
                            }).then(async (response) =>{
                                var logs=response.data;
                                param.successCallback(logs);                                
                            }).catch(function(error){
                                console.log(error);
                            })
                             
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'delivery_order_number')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            //console.log("Child ", params);
                        params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "check_list")
        {
            if(params != "")
            {
                this.setState({
                    detailCellRendererParams : {},
                });
                var itemDetails=[];
                console.log("Step 3 ",itemDetails)
                var propsdata = params;
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                // { headerName: "Truck Platform Condition",field: "truck_condition", filter:true,resizable: true },
                                // { headerName: "Any Angles/Bolts/Nuts Projected",field: "abnprojected", filter:true,resizable: true },
                                // { headerName: "Any Chemical/dust/powder/Cement/Rust Contamination on truck platform",field: "acdpcrcontamination", filter:true,resizable: true },
                                // { headerName: "Any foul smell in truck",field: "foulsmell", filter:true,resizable: true },
                                // { headerName: "Are they having hygenic condition tarpaulins i.e, free from dust/smell/holes",field: "hygeniccondition", filter:true,resizable: true },
                                // { headerName: "Whether three no of tarpaulins available", field:"tarpaulins", filter:true,resizable: true },
                                // { headerName: "Are they having proper two ropes",field: "tworopes", filter:true,resizable: true },
                                // { headerName: "Are they following loading practices",field: "loadingpractices", filter:true,resizable: true },
                                { headerName: "Vehicle Check List",field: "chk_label", width: 600,filter:true,resizable: true, 
                                    valueGetter:function(params){
                                        if(params.data.chk_label == "truck_condition")
                                        {
                                            return "Truck Platform Condition";
                                        }
                                        else if(params.data.chk_label == "abnprojected")
                                        {
                                            return "Any Angles/Bolts/Nuts Projected";
                                        }
                                        else if(params.data.chk_label == "acdpcrcontamination")
                                        {
                                            return "Any Chemical/dust/powder/Cement/Rust Contamination on truck platform";
                                        }
                                        else if(params.data.chk_label == "foulsmell")
                                        {
                                            return "Any foul smell in truck";
                                        }
                                        else if(params.data.chk_label == "hygeniccondition")
                                        {
                                            return "Are they having hygenic condition tarpaulins i.e, free from dust/smell/holes";
                                        }
                                        else if(params.data.chk_label == "tarpaulins")
                                        {
                                            return "Whether three no of tarpaulins available";
                                        }
                                        else if(params.data.chk_label == "tworopes")
                                        {
                                            return "Are they having proper two ropes";
                                        }
                                        else if(params.data.chk_label == "loadingpractices")
                                        {
                                            return "Are they following loading practices";
                                        }
                                    }
                                },
                                { headerName: "",field: "chk_val", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData: function(param) {
                            if(params.data.check_list !=undefined && params.data.check_list !="")
                            {
                                var checkList = [];
                                checkList.push(params.data.check_list);
                                var cklist = checkList;
                                if(cklist.length > 0)
                                {
                                    cklist.map(function(id){
                                        var chkLabels = Object.keys(cklist[0]);
                                        chkLabels.map(function(cl){
                                            itemDetails.push({
                                                chk_label : cl,
                                                chk_val : id[cl]
                                            });
                                        });
                                    })

                                }
                            }
                            param.successCallback(itemDetails);                            
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'check_list')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            //console.log("Child ", params);
                        params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "security_checklist")
        {
            if(params != "")
            {
                this.setState({
                    detailCellRendererParams : {},
                });
                var itemDetails=[];
                console.log("Step 3 ",itemDetails)
                var propsdata = params;
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: "Security Check List",field: "chk_label", filter:true,resizable: true, 
                                    valueGetter:function(params){
                                        if(params.data.chk_label == "driver_licence")
                                        {
                                            return "Driver Licence";
                                        }
                                        else if(params.data.chk_label == "truck_insurance")
                                        {
                                            return "Truck Insurance";
                                        }
                                        else if(params.data.chk_label == "puc")
                                        {
                                            return "PUC";
                                        }
                                        else if(params.data.chk_label == "truck_fitness")
                                        {
                                            return "Truck Fitness";
                                        }
                                        else if(params.data.chk_label == "truck_road_tax")
                                        {
                                            return "Truck Road Tax";
                                        }
                                        else if(params.data.chk_label == "reverse_horn")
                                        {
                                            return "Reverse Horn";
                                        }
                                        else if(params.data.chk_label == "side_view_mirror")
                                        {
                                            return "Side View Mirror";
                                        }
                                        else if(params.data.chk_label == "hand_break")
                                        {
                                            return "Hand Break";
                                        }
                                    }
                                },
                                { headerName: "Validity",field: "chk_date", filter:true,resizable: true },
                                { headerName: "",field: "chk_val", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData: function(param) {
                            if(params.data.security_checklist !=undefined && params.data.security_checklist !="")
                            {
                                var checkList = [];
                                checkList.push(params.data.security_checklist);
                                var cklist = checkList;
                                if(cklist.length > 0)
                                {
                                    cklist.map(function(id){
                                        var chkLabels = Object.keys(cklist[0]);
                                        chkLabels.map(function(cl){
                                            if(cl !="driver_licence_date" && cl !="truck_insurance_date" && cl !="truck_fitness_date" && cl !="puc_date" && cl !="truck_road_tax_date")
                                            {
                                                var chk_date = "";
                                                if(cl !="reverse_horn" && cl !="side_view_mirror" && cl !="hand_break")
                                                {
                                                    chk_date = id[cl+"_date"];
                                                }
                                                itemDetails.push({
                                                    chk_label : cl,
                                                    chk_date : chk_date,
                                                    chk_val : id[cl]
                                                });
                                            }
                                        });
                                    })

                                }
                            }
                            param.successCallback(itemDetails);                            
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'security_checklist')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            //console.log("Child ", params);
                        params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "uploadedFile")
        {
            var uploadedFile = params.data.uploadedFile;
            var uploaded_from = params.data.uploaded_from;
            if(uploadedFile !=undefined && uploadedFile !="" && uploaded_from !=undefined && uploaded_from !="")
            {
                redirectURL.post("/manage/getLoadingImage",{delivery_order_number:params.data.delivery_order_number}).then(async(response)=>{
                    console.log(response.data);
                    var uploadedFileSrc = response.data;
                    this.setState({
                        loadshow : "show-n",
                        overly : "show-n",
                        imgSrc: "",
                        uploadedFile: uploadedFileSrc,
                        uploaded_from: uploaded_from,
                        open: true,
                    })
                }).catch((e)=>{
                    console.log(e);
                })
            }
        }
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onCellUpdateData = (e) =>{
        if(e.colDef.field == "status")
        {
            var status = e.data.status;
            if(status !=undefined && status !="")
            {
                var rowId = e.data._id;
                var params = {
                    rowId : rowId,
                    status : status,
                }
                redirectURL.post("/manage/updateplantstatus",params).then((response)=>{
                    console.log(response);
                    this.loadPageData();
                })
            }
        }
    }
    onChangeTransporterItem = (transporter) => {
		this.setState(
			{ transporter : transporter},
			() => console.log(`Transporter Option selected:`, this.state.transporter)
          );
	}
    selecttransporters(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    onChangeCustomerItem = (customer) => {
		this.setState(
			{ customer : customer},
			() => console.log(`customer Option selected:`, this.state.customer)
          );
	}
    selectcustomers(){
		let items = [];    
		try{
			if(this.state.customersList.length > 0)
			{
				this.state.customersList.map((item) =>{
					items.push({"value":item.customer_name,"label":item.customer_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    changeStatus = (e) => {
        var delivery_order_number = e.data.delivery_order_number;
        var status = e.data.status;
        var agi_do_number = e.data.agi_do_number;
        var documentation_completed = e.data.documentation_completed;
        var labelName = "";
        var verificationCheck = 1;
        if(status == 1)
        {
            labelName = "Gate-1 In Time";
        }
        else if(status == 2)
        {
            if(agi_do_number !=undefined && agi_do_number !="")
            {
                labelName = "Gate-2 In Time";
            }
            else
            {
                labelName = "Generate Delivery Order";
            }
        }
        else if(status == 3)
        {
            labelName = "Loading Started On";
        }
        else if(status == 4)
        {
            labelName = "Loading Completed On";
        }
        else if(status == 5 && documentation_completed == 0)
        {
            labelName = "Documentation Completed On";
            var lr_no = e.data.lr_no;
            if(lr_no !=undefined && lr_no !="")
            {
                this.setState({
                    lr_no : lr_no
                });
            }
            var loaded_item_details = e.data.loaded_item_details;
            var item_quantity = "";
            if(loaded_item_details !=undefined && loaded_item_details !="")
            {
                loaded_item_details = JSON.parse(loaded_item_details);
                if(loaded_item_details.length > 0)
                {
                    item_quantity = loaded_item_details[0].CONFIRM_QTY;
                }
            }
            if(item_quantity !=undefined && item_quantity !="")
            {
                this.setState({
                    item_quantity : item_quantity
                });
            }
        }
        else if(status == 5 && documentation_completed == 1)
        {
            labelName = "Gate-2 Out Time";
        }
        else if(status == 6)
        {
            labelName = "Gate-1 Out Time";
        }
        if(status < 7)
        {
            if(status > 2 && agi_do_number !=undefined && agi_do_number !="")
            {
                if(e.data.approve_status == 1)
                {
                    verificationCheck = 1;
                }
                else if(e.data.approve_status == 2)
                {   
                    verificationCheck = 2;
                }
                else
                {
                    verificationCheck = 0;
                }
            }
            if(verificationCheck == 1)
            {
                this.resetSecurityChecks();
                if(status == 2)
                {
                    if(agi_do_number !=undefined && agi_do_number !="")
                    {                        
                        $("#gateInDate").show();
                    }
                    else
                    {                        
                        $("#gateInDate").hide();
                    }
                }
                else{                                            
                    $("#gateInDate").show();
                }
                $("#dateField").attr("required",true);
                var itemDetails = [];
                if(e.data.item_details !=undefined && e.data.item_details !="")
                {
                    itemDetails = JSON.parse(e.data.item_details);
                }
                if(status == 5 && documentation_completed == 0)
                {
                    this.setState({
                        loadshow:"show-m",
                        overly:"show-m"
                    });
                    redirectURL.post("/manage/getMaterialInformation",{loaded_item_details:e.data.loaded_item_details,plant_code:e.data.plant_code}).then(async(response)=>{
                        var respDataFromAPI = response.data;
                        console.log(respDataFromAPI,'respDataFromAPI');
                        var stolList = [];
                        var batch = [];
                        respDataFromAPI.map(function(rsp){
                            stolList.push(rsp.StorageLocation);
                            batch.push(rsp.Batch);
                        });
                        this.setState({
                            labelName : labelName,
                            delivery_order_number : delivery_order_number,
                            status : status,
                            agi_do_number : agi_do_number,
                            documentation_completed : documentation_completed,
                            sliderTitle : "Update "+labelName+" - "+delivery_order_number,
                            overly :"show-m",
                            bulkslide : "slider-translate-50p",
                            rowDetails : e.data,
                            itemDetails : itemDetails,
                            sliderType : 1,
                            stolList : stolList,
                            batch : batch,
                            loadshow:"show-n",
                            overly:"show-m"
                        });
                    }).catch((e)=>{
                        console.log(e);
                        this.setState({
                            loadshow:"show-n",
                            overly:"show-m"
                        });
                    })                    
                }
                else
                {
                    console.log(e.data,"rowDetails")
                    console.log(labelName,"labelName")
                    console.log(itemDetails,"itemDetails")
                    this.setState({
                        labelName : labelName,
                        delivery_order_number : delivery_order_number,
                        status : status,
                        agi_do_number : agi_do_number,
                        documentation_completed : documentation_completed,
                        sliderTitle : "Update "+labelName+" - "+delivery_order_number,
                        overly :"show-m",
                        bulkslide : "slider-translate-50p",
                        rowDetails : e.data,
                        itemDetails : itemDetails,
                        sliderType : 1
                    });
                }
            }
            else if(verificationCheck == 2)
            {
                this.setState({
                    show : true,
                    basicTitle : "Truck Rejeceted",
                    basicType : "danger"
                });
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Truck Verification is pending",
                    basicType : "danger"
                });
            }
        }                            
        loadDateTimeScript();
    }
    changeApproveStatus = (e) => {
        var delivery_order_number = e.data.delivery_order_number;
        var approve_status = e.data.approve_status;
        
        if(approve_status == 0)
        {
            var itemQtyDetails = [];
            if(e.data.loaded_item_details !=undefined && e.data.loaded_item_details !="")
            {
                itemQtyDetails = JSON.parse(e.data.loaded_item_details);
            }
            this.setState({
                delivery_order_number : delivery_order_number,
                approve_status : approve_status,
                sliderTitle : "Update Verification Status "+e.data.truck_no,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                rowDetails : e.data,
                itemQtyDetails : itemQtyDetails,
                status : e.data.status,
                sliderType : 2
            });
        }
    }
    filterData = (event) => {
        this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
        if(selectedFilter !=undefined && selectedFilter !="" && inputTruckConsignment !=undefined && inputTruckConsignment !="")
        {
            var params = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
			}
            if(localStorage.getItem("user_type") == "TRANSPORTER")
            {
                params.transporter_code = localStorage.getItem("transportercode");
            }
            if(localStorage.getItem("plant_code") != undefined && localStorage.getItem("plant_code") != "undefined" && localStorage.getItem("plant_code") != "")
            {
                params.plant_code = localStorage.getItem("plant_code");
            }
            var pageType = this.state.pageType;
            if(pageType == 1)
            {
                redirectURL.post("/manage/getAssignedTrucksList",params).then(async(response)=>{
                    console.log(response.data);
                    var rowData = response.data;
                    var truckAssigned = rowData.filter((e) => e.status == 1 && e.security_approve_status !== 2);
                    var securityRejected = rowData.filter((e) => e.status == 1 && e.security_approve_status == 2);
                    var gate1In = rowData.filter((e) => e.status == 2);
                    // var pendingForVerifictaion = rowData.filter((e) => e.status == 2 && e.approve_status == 0);
                    // var verificationApproved = rowData.filter((e) => e.status == 2 && e.approve_status == 1);
                    // var verificationRejected = rowData.filter((e) => e.status == 2 && e.approve_status == 2);
                    var gate2In = rowData.filter((e) => e.status == 3);
                    var loadingStarted = rowData.filter((e) => e.status == 4);
                    var pendingForVerifictaion = rowData.filter((e) => e.status == 3 && e.approve_status == 0);
                    var verificationApproved = rowData.filter((e) => e.status == 3 && e.approve_status == 1);
                    var verificationRejected = rowData.filter((e) => e.status == 3 && e.approve_status == 2);
                    var loadingCompleted = rowData.filter((e) => e.status == 5);
                    var documentationPending = rowData.filter((e) => e.status == 5 && e.documentation_completed == 0);
                    var documentationCompleted = rowData.filter((e) => e.status == 5 && e.documentation_completed == 1);
                    var gate2Out = rowData.filter((e) => e.status == 6);
                    var gate1Out = rowData.filter((e) => e.status == 7);
                    this.setState({
                        allrowData : rowData,
                        rowData : rowData,
                        truckAssigned : truckAssigned,
                        securityRejected : securityRejected,
                        gate1In : gate1In,
                        pendingForVerifictaion : pendingForVerifictaion,
                        verificationApproved : verificationApproved,
                        verificationRejected : verificationRejected,
                        gate2In : gate2In,
                        loadingStarted : loadingStarted,
                        loadingCompleted : loadingCompleted,
                        documentationPending : documentationPending,
                        documentationCompleted : documentationCompleted,
                        gate2Out : gate2Out,
                        gate1Out : gate1Out,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }).catch=((e)=>
                {
                    console.log(e);
                })
            }
            else
            {
                redirectURL.post("/manage/getUnAssignedTrucksList",params).then(async(response)=>{
                    console.log(response.data);
                    var rowData = response.data;
                    this.setState({
                        unAssignrowData : rowData,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }).catch((e)=>{
                    console.log(e);
                })
            }
        }
        else
        {
            this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
        }    
    }
    
    onClickFilterButton = (event) => {
        this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var transporter = this.state.transporter;
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		var ship_city = $("#ship_city").val();
		var customer = this.state.customer;
        if(transporter.value !="" || ship_city !="" || customer !="")
        {
            var params = {
                "startDate":sDate,
                "endDate":eDate,
                "ship_city":ship_city,
                "customer":customer.value,
			}
            if(localStorage.getItem("user_type") == "TRANSPORTER")
            {
                params.transporter_code = localStorage.getItem("transportercode");
            }
            else
            {
                params.transporter_code = transporter.value;
            }
            if(localStorage.getItem("plant_code") != undefined && localStorage.getItem("plant_code") != "undefined" && localStorage.getItem("plant_code") != "")
            {
                params.plant_code = localStorage.getItem("plant_code");
            }
            var pageType = this.state.pageType;
            if(pageType == 1)
            {
                redirectURL.post("/manage/getAssignedTrucksList",params).then(async(response)=>{
                    console.log(response.data);
                    var rowData = response.data;
                    var truckAssigned = rowData.filter((e) => e.status == 1 && e.security_approve_status !== 2);
                    var securityRejected = rowData.filter((e) => e.status == 1 && e.security_approve_status == 2);
                    var gate1In = rowData.filter((e) => e.status == 2);
                    // var pendingForVerifictaion = rowData.filter((e) => e.status == 2 && e.approve_status == 0);
                    // var verificationApproved = rowData.filter((e) => e.status == 2 && e.approve_status == 1);
                    // var verificationRejected = rowData.filter((e) => e.status == 2 && e.approve_status == 2);
                    var gate2In = rowData.filter((e) => e.status == 3);
                    var loadingStarted = rowData.filter((e) => e.status == 4);
                    var pendingForVerifictaion = rowData.filter((e) => e.status == 3 && e.approve_status == 0);
                    var verificationApproved = rowData.filter((e) => e.status == 3 && e.approve_status == 1);
                    var verificationRejected = rowData.filter((e) => e.status == 3 && e.approve_status == 2);
                    var loadingCompleted = rowData.filter((e) => e.status == 5);
                    var documentationPending = rowData.filter((e) => e.status == 5 && e.documentation_completed == 0);
                    var documentationCompleted = rowData.filter((e) => e.status == 5 && e.documentation_completed == 1);
                    var gate2Out = rowData.filter((e) => e.status == 6);
                    var gate1Out = rowData.filter((e) => e.status == 7);
                    this.setState({
                        allrowData : rowData,
                        rowData : rowData,
                        truckAssigned : truckAssigned,
                        securityRejected : securityRejected,
                        gate1In : gate1In,
                        pendingForVerifictaion : pendingForVerifictaion,
                        verificationApproved : verificationApproved,
                        verificationRejected : verificationRejected,
                        gate2In : gate2In,
                        loadingStarted : loadingStarted,
                        loadingCompleted : loadingCompleted,
                        documentationPending : documentationPending,
                        documentationCompleted : documentationCompleted,
                        gate2Out : gate2Out,
                        gate1Out : gate1Out,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }).catch=((e)=>
                {
                    console.log(e);
                })
            }
            else
            {
                redirectURL.post("/manage/getUnAssignedTrucksList",params).then(async(response)=>{
                    console.log(response.data);
                    var rowData = response.data;
                    this.setState({
                        unAssignrowData : rowData,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }).catch((e)=>{
                    console.log(e);
                })
            }
        }
        else
        {
            this.setState({
				basicTitle : "Please enter any field to continue",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
        }    
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					startDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 	
				this.setState({
					startDate:startdate
				});
			}
		}
		else
		{
			this.setState({
				startDate:startdate
			});
		}
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					endDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 
				this.setState({
					endDate:edate
				});
			}
		}
		else
		{
			this.setState({
				endDate:edate
			});
		}
	}
	reloadPageData=()=>{
		window.location.reload();
	}
    onClickPage = async(pageType) => {
        $("#selectedFilter").val("");
        $("#inputTruckConsignment").val("");
        $("#ship_city").val("");
        this.setState({
            loadshow : "show-m",
            overly : "show-m"
        });
        if(pageType == 1)
        {
            var assigned = "activet";
            var unassigned = "";
        }
        else
        {                    
            loadDateTimeScript();
            var assigned = "";
            var unassigned = "activet";
        }
        await this.setState({
            pageType : pageType,
            assigned : assigned,
            unassigned : unassigned,
            transporter : {label:"Select Transporter",value:""},
            customer : {label:"Select Customer",value:""},
            startDate : "",
            endDate : "",
            loadshow : "show-n",
            overly : "show-n"
        });
        await this.loadPageData();
    }
    assignTruck = (e) => {
        var sales_order_number = e.data.sales_order_number;
        this.setState({
            sales_order_number : sales_order_number,
            sliderTitle : "Assign Truck",
            overly :"show-m",
            assignslide : "slider-translate-40p",
            salesOrderInfo : e.data,
            sliderType : 3
        });
        loadDateTimeScript();
    }
    onChangeTruckItem = (truck) => {
        var truck_capacity = truck.value;
		this.setState(
			{ truck : truck, truck_capacity:truck_capacity},
			() => console.log(`truck Option selected:`, this.state.truck)
          );
	}
    selecttrucks(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.trucksList.length > 0)
			{
				this.state.trucksList.map((item) =>{
					items.push({"value":item.truck_capacity,"label":item.truck_no})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    assignTruckToSalesOrder = (e) => {
        e.preventDefault();
        var reporting_time = $("#reporting_time").val();
        var driver_name = $("#driver_name").val();
        var driver_mobile = $("#driver_mobile").val();
        var sales_order_number = this.state.sales_order_number;
        var truck = this.state.truck;
        var truck_capacity = $("#truck_capacity").val();
        if(reporting_time !=undefined && reporting_time !="" && driver_name !=undefined && driver_name !="" && driver_mobile !=undefined && driver_mobile !="" && sales_order_number !=undefined && sales_order_number !="" && truck !=undefined && truck !="" && truck_capacity !=undefined && truck_capacity !="")
        {
            if(sales_order_number !="" && sales_order_number !=undefined)
            {
                if(truck !="" && truck !=undefined)
                {
                    var salesOrderInfo = this.state.salesOrderInfo;
                    var itemDetails = salesOrderInfo.item_details;
                    console.log(itemDetails,"itemDetails")
                    if(itemDetails !=undefined)
                    {
                        // var truck_capacity = truck.value;
                        var truck_no = truck.label;
                        if(salesOrderInfo !=undefined && salesOrderInfo !="")
                        {
                            this.setState({
                                loadshow : "show-m",
                                overly : "show-m"
                            });
                            var lr_no = "";
                            if(salesOrderInfo.lr_no !=undefined && salesOrderInfo.lr_no !="")
                            {
                                lr_no = salesOrderInfo.lr_no;
                            }
                            var params = {
                                delivery_order_number : salesOrderInfo.delivery_order_number,
                                sales_order_number : salesOrderInfo.sales_order_number,
                                sold_party : salesOrderInfo.sold_party,
                                ship_party : salesOrderInfo.ship_party,
                                ship_city : salesOrderInfo.ship_city,
                                plant_code : salesOrderInfo.plant_code,
                                plant_name : salesOrderInfo.plant_name,
                                plant_location : salesOrderInfo.plant_location,
                                sch_date : getHyphenYYYYMMDDHHMMSS(salesOrderInfo.sch_date),
                                customer_name : salesOrderInfo.customer_name,
                                item_details : itemDetails,
                                transporter_code : localStorage.getItem("transportercode"),
                                lr_no : lr_no,
                                truck_no : truck_no,
                                truck_capacity : truck_capacity,
                                reporting_time : reporting_time,
                                driver_name : driver_name,
                                driver_mobile : driver_mobile
                            }
                            redirectURL.post("/manage/assignsalesordertotransporter",params).then((response)=>{
                                console.log(response);
                                if(response.data.message == "Success")
                                {
                                    this.setState({
                                        basicTitle:"Updated Successfully.",
                                        basicType : "success",
                                        show : true,
                                        loadshow : "show-n",
                                        overly : "show-n",
                                        bulkslide:"",
                                        assignslide:"",
                                    })
                                    this.onClickPage(1);
                                }
                                else
                                {
                                    this.setState({
                                        basicTitle:response.data.message,
                                        basicType : "warning",
                                        show : true,
                                        loadshow : "show-n",
                                        overly : "show-n"
                                    })
                                }
                            })
                        }
                        else
                        {
                            this.setState({
                                show : true,
                                basicTitle : "Something went wrong.",
                                basicType : "warning",
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n"
                            });
                        }
                    }
                    else
                    {
                        this.setState({
                            show : true,
                            basicTitle : "Something went wrong.",
                            basicType : "warning",
                            show : true,
                            loadshow : "show-n",
                            overly : "show-n"
                        });
                    }
                }
                else
                {
                    this.setState({
                        show : true,
                        basicTitle : "Truck Should not be Empty.",
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    });
                }
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Sales Order Should not be Empty.",
                    basicType : "warning",
                    show : true,
                    loadshow : "show-n",
                    overly : "show-n"
                });
            }
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Fields Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    approveTruck = (e) =>{
        var delivery_order_number = e.data.delivery_order_number;
        var sales_order_number = e.data.sales_order_number;
        this.setState({
            sales_order_number : sales_order_number,
            delivery_order_number : delivery_order_number,
            sliderTitle : "Final Approve/Reject",
            overly :"show-m",
            bulkslide : "slider-translate-40p",
            salesOrderInfo : e.data,
            sliderType : 4
        });
    }
    finalApproveTruck = (e) =>{
        e.preventDefault();
        var finalApproveStatus = $("#final_approve_status").val();
        var finalRemarks = $("#final_remarks").val();
        if(finalApproveStatus !=undefined && finalApproveStatus !="")
        {
            var params = {
                approve_type : 2,
                approve_status : parseInt(finalApproveStatus),
                remarks : finalRemarks,
                check_list : "",
                delivery_order_number : this.state.delivery_order_number,
                status_updated_by : localStorage.getItem("email"),
            }
            console.log(params,"params")
            redirectURL.post("/manage/changeAssignedTruckApproveStatus",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        bulkslide:"",
                        assignslide:""
                    })
                    $("#dateField").val("");
                    this.loadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }
            })
        }
        else
        {
            this.setState({
                basicTitle:"Please select option to continue",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            })   
        }
    }
    selectTypes = () =>{
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            return [
                {label:"Truck No",value:"truck_no"},
            ];
        }
        else
        {
            return [
                {label:"Delivery Order No",value:"delivery_order_number"},
                {label:"Sales Order No",value:"sales_order_number"},
                {label:"Truck No",value:"truck_no"},
            ];
        }
    }
    changeApproveOption = (e) => {
        e.preventDefault();
        var selectedOption = $("#verification").val();
        if(selectedOption !=undefined && selectedOption !="")
        {
            if(selectedOption == 2)
            {
                $("#remarks").attr("required",true);
            }
            else
            {
                $("#remarks").removeAttr("required",true);
            }
        }
    }
    changeSecurityApproveOption = (e) => {
        e.preventDefault();
        var selectedOption = $("#security_verification").val();
        if(selectedOption !=undefined && selectedOption !="")
        {
            if(selectedOption == 1)
            {
                $("#gateInDate").show();
                $("#dateField").attr("required",true);
            }
            else
            {
                $("#gateInDate").hide();
                $("#dateField").removeAttr("required",true);
            }
        }
    }
    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };
    handleFileUpload = async (e) => {
        console.log(e.target.files,"e.target")
        const file = e.target.files[0];
        var fileName = file.name;
        if(fileName !=undefined && fileName !="")
        {
            fileName = fileName.split(".");
            fileName = fileName.reverse()[0];
            if(fileName == "jpg" || fileName == "jpeg" || fileName == "JPG" || fileName == "JPEG" || fileName == "png" || fileName == "PNG")
            {
                const base64 = await this.convertToBase64(file);
                this.setState({ 
                    myFile: base64 
                })
                console.log(base64,"base64")
            }
            else
            {
                $("#myFile").val("");
                this.setState({
                    show : true,
                    basicTitle : "Invalid File Format",
                    basicType : "warning"
                });
            }
        }
    };
    changeHandler = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        if(name == "truck_no")
        {
            if(value !=undefined && value !="")
            {
                var truck_capacity = "";
                var filteredData = this.state.trucksList.filter((f) => f.truck_no == value);
                if(filteredData.length > 0)
                {
                    if(filteredData[0].truck_capacity !=undefined && filteredData[0].truck_capacity !="")
                    {
                        truck_capacity = filteredData[0].truck_capacity;
                    }
                }
                this.setState({truck:{"label":value,"value":truck_capacity},truck_capacity:truck_capacity});
            }
        }
        else
        {
            this.setState({[name]:value});
        }
    }
    rejectSalesOrder = (params) => {
        console.log(params.data)
        if (window.confirm("Are You Sure! Do you want reject?"))
        {
            var qry = params.data;
            qry.transporter_code = localStorage.getItem("transportercode");
            redirectURL.post("/manage/rejectSalesOrder",qry).then((response)=>{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Success",
                        basicType : "success",
                        show : true,
                    })
                    this.loadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed. Please try agagin",
                        basicType : "warning",
                        show : true
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        }
    }
    getItemQuantity = () => {
        var item_chk_list = $("input[name='item_chk_list']:checked").val();
        var rowDetails = this.state.rowDetails;
        if(rowDetails !=undefined && rowDetails !="" && item_chk_list !=undefined && item_chk_list !="")
        {
            var itemDetails = rowDetails.item_details;
            if(itemDetails !=undefined && itemDetails !="")
            {
                itemDetails = JSON.parse(itemDetails);
                if(itemDetails.length > 0)
                {
                    var itemInfo = itemDetails.filter((i) => i.ITEM == item_chk_list);
                    if(itemInfo.length > 0)
                    {
                        this.setState({
                            confirm_quantity : itemInfo[0].CONFIRM_QTY
                        });
                    }
                }
            }
        }
    }
	render(){
        var userRoles = localStorage.getItem("roles");
        const { open } = this.state;
        var columnwithDefs = [];
        if(localStorage.getItem("user_type") !="TRANSPORTER")
        {
            columnwithDefs.push(                
            {
                headerName: "Delivery Order No",
                field: "delivery_order_number",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
                cellStyle:function(params){
                    if(params.data.approve_status == 2 || params.data.security_approve_status == 2)
                    {
                        return {'background-color': 'red' };
                    }
                }          
            },               
            {
                headerName: "AGI DO No",
                field: "agi_do_number",
                width:160,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Sales Order No",
                field: "sales_order_number",
                width:160,
                filter: true,
                resizable: true,
                editable:false,            
            },)
        }
        columnwithDefs.push(
        {
            headerName: "Truck No",
            field: "truck_no",
            width:150,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Truck Type",
            field: "truck_capacity",
            width:120,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Transporter",
            field: "transporter_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,            
        },  
        {
            headerName: "Status",
            field: "status",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.security_approve_status == 2)
                {
                    return "Security Rejected";
                }
                else if(params.data.status == 1)
                {
                    return "Truck Assgined";
                }
                else if(params.data.status == 2)
                {
                    return "Gate-1 In";
                }
                else if(params.data.status == 3)
                {
                    return "Gate-2 In";
                }
                else if(params.data.status == 4)
                {
                    return "Loading Started";
                }
                else if(params.data.status == 5)
                {
                    if(params.data.documentation_completed == 0)
                    {
                        return "Loading Completed - Documentation Pending";
                    }
                    else
                    {
                        return "Loading Completed - Documentation Completed";
                    }
                }
                else if(params.data.status == 6)
                {
                    return "Gate-2 Out";
                }
                else if(params.data.status == 7)
                {
                    return "Gate-1 Out";
                }
            }           
        },  
        {
            headerName: "Verification",
            field: "approve_status",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.approve_status == 0)
                {
                    return "Pending";
                }
                else if(params.data.approve_status == 1)
                {
                    return "Approved";
                }
                else if(params.data.approve_status == 2)
                {
                    return "Rejected";
                }
            }           
        });
        
        if(userRoles.indexOf("supervisor") == -1)
        {
            columnwithDefs.push(
                {
                    headerName: "Destination",
                    field: "ship_city",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,            
                },     
                {
                    headerName: "Customer Name",
                    field: "customer_name",
                    width:200,
                    filter: true,
                    resizable: true,
                    editable:false,            
                },     
                {
                    headerName: "Plant Name",
                    field: "plant_name",
                    width:200,
                    filter: true,
                    resizable: true,
                    editable:false,
                    valueGetter: function(params){
                        return params.data.plant_code+" - "+params.data.plant_name+" - "+params.data.plant_location
                    }
                }, 
            )
        }
        columnwithDefs.push(
            {
                headerName: "Scheduled Date",
                field: "sch_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.sch_date !=undefined && params.data.sch_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.sch_date);
                    }
                }    
            },
            {
                headerName: "Reporting Time",
                field: "reporting_time",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.reporting_time !=undefined && params.data.reporting_time !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.reporting_time);
                    }
                }           
            },
            {
                headerName: "Driver Name",
                field: "driver_name",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },   
            {
                headerName: "Driver Mobile",
                field: "driver_mobile",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },    
            {
                headerName: "Assigned Date",
                field: "created_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,  
                valueGetter: function(params){
                    if(params.data.created_date !=undefined && params.data.created_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date);
                    }
                }
            },    
            {
                headerName: "Gate-1 In Time",
                field: "gate1_in_time",
                width:150,
                filter: true,
                resizable: true,
                editable:false,  
                valueGetter: function(params){
                    if(params.data.gate1_in_time !=undefined && params.data.gate1_in_time !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate1_in_time);
                    }
                }
            },
        );
        
        
        if(userRoles.indexOf("supervisor") == -1)
        {
            columnwithDefs.push(
                {
                    headerName: "Gate-2 In Time",
                    field: "gate2_in_time",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,  
                    valueGetter: function(params){
                        if(params.data.gate2_in_time !=undefined && params.data.gate2_in_time !="")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate2_in_time);
                        }
                    }
                },
                {
                    headerName: "Loading Started On",
                    field: "loading_started_on",
                    width:160,
                    filter: true,
                    resizable: true,
                    editable:false,  
                    valueGetter: function(params){
                        if(params.data.loading_started_on !=undefined && params.data.loading_started_on !="")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.loading_started_on);
                        }
                    }
                },    
                {
                    headerName: "Loading Completed On",
                    field: "loading_completed_on",
                    width:170,
                    filter: true,
                    resizable: true,
                    editable:false,  
                    valueGetter: function(params){
                        if(params.data.loading_completed_on !=undefined && params.data.loading_completed_on !="")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.loading_completed_on);
                        }
                    }
                },    
                {
                    headerName: "Gate-2 Out Time",
                    field: "gate2_out_time",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,  
                    valueGetter: function(params){
                        if(params.data.gate2_out_time !=undefined && params.data.gate2_out_time !="")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate2_out_time);
                        }
                    }
                },    
                {
                    headerName: "Gate-1 Out Time",
                    field: "gate1_out_time",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,  
                    valueGetter: function(params){
                        if(params.data.gate1_out_time !=undefined && params.data.gate1_out_time !="")
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.gate1_out_time);
                        }
                    }
                },    
                {
                    headerName: "Load Verification Image",
                    field: "uploadedFile",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,                   
                    cellRenderer: function(params){
                        if(params.data.uploadedFile !=undefined && params.data.uploadedFile !="")
                        {
                            return '<button class="btn btn-info" style="padding-top:0">View</button>'
                        }
                    }
                },
            )
        }
        if(localStorage.getItem("user_type") !="TRANSPORTER" && userRoles.indexOf("supervisor") == -1)
        {
            columnwithDefs.push(
            {
                headerName: "Sold Party",
                field: "sold_party",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },     
            {
                headerName: "Ship Party",
                field: "ship_party",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "",
                field: "item_detais",
                width:50,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<i class="icofont icofont-list txt-grey-light f20" title="Item Details"></i>'
                    if(params.data.item_detais !=undefined && params.data.item_detais !="")
                    {
                        htmloption = '<i class="icofont icofont-list themefont-alt f20" title="Item Details"></i>';
                    }
                    return htmloption;
                },                
                
            },
            {
                headerName: "",
                field: "security_checklist",
                width:50,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<i class="icofont icofont-checked txt-grey-light f20" title="Security Check List"></i>';
                    if(params.data.security_checklist !=undefined && params.data.security_checklist !="")
                    {
                        htmloption = '<i class="icofont icofont-checked themefont-alt f20" title="Security Check List"></i>';
                    }
                    return htmloption;
                }, 
            },
            {
                headerName: "",
                field: "check_list",
                width:50,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<i class="icofont icofont-truck-alt txt-grey-light f20" title="Vehicle Checklist"></i>';
                    if(params.data.check_list !=undefined && params.data.check_list !="")
                    {
                        htmloption = '<i class="icofont icofont-truck-alt themefont-alt f20" title="Vehicle Checklist"></i>';
                    }
                    return htmloption;
                }, 
            },
            {
                headerName: "",
                field: "qr_code",
                width:50,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<i class="icofont icofont-qr-code themefont-alt f20" title="QR Code"></i>';
                    return htmloption;
                },
            },
            {
                headerName: "Update",
                field: "configure",
                width:250,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"right",
                cellRendererSelector:function(params){
                    if(parseInt(params.data.status) < 7)
                    {
                        if(params.data.approve_status == 2)
                        {
                            // if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("warehouse") >= 0)
                            // {                            
                            //     var rendComponent1 = {
                            //         component: 'configureWhApprove'
                            //     };
                            //     return rendComponent1;
                            // }
                        }
                        else
                        { 
                            if(params.data.security_approve_status !=2)
                            {                           
                                var rendComponent = {
                                    component: 'configureAction'
                                };
                                var check = 1;
                                if(userRoles.indexOf("loadingofficer") >= 0)
                                {
                                    if(params.data.approve_status != 1)
                                    {
                                        check = 0;
                                    }
                                }
                                if(userRoles.indexOf("billing") >= 0)
                                {
                                    if(params.data.documentation_completed == 1)
                                    {
                                        check = 0;
                                    }
                                }
                                if(userRoles.indexOf("gateinofficer") >= 0)
                                {
                                    if(params.data.status == 2)
                                    {
                                        if(params.data.agi_do_number == undefined || params.data.agi_do_number == "undefined" || params.data.agi_do_number == "")
                                        {
                                            check = 0;
                                        }
                                    }
                                    if(params.data.status == 5)
                                    {
                                        if(params.data.documentation_completed == 0)
                                        {
                                            check = 0;
                                        }
                                    }
                                }
                                if(check == 1)
                                {
                                    return rendComponent;
                                }
                            }
                        }
                    }
                    else
                    {
                        return "";
                    }
                }
            },)
        }
        if(userRoles.indexOf("SITE_ADMIN") >= 0 || userRoles.indexOf("loadingofficer") >= 0)
        {
            columnwithDefs.push(
                {
                    headerName: "Verification",
                    field: "configure_approve_status",
                    width:180,
                    filter: true,
                    resizable: true,
                    editable:false,
                    pinned:"right",
                    cellRendererSelector:function(params){
                        var rendComponent = {
                            component: 'configureApproveAction'
                        };
                        if(params.data.approve_status == 0 && parseInt(params.data.status) > 2)
                        {
                            return rendComponent;
                        }
                        else if(params.data.approve_status == 2 && params.data.final_approve_status == 0)
                        {
                            if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("loadingofficer") >= 0)
                            {                            
                                var rendComponent1 = {
                                    component: 'configureWhApprove'
                                };
                                return rendComponent1;
                            }
                            else
                            {
                                return "";
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                }
            );
        }
        if(userRoles.indexOf("SITE_ADMIN") >= 0 || userRoles.indexOf("billing") >= 0 || userRoles.indexOf("warehouse") >= 0)
        {
            columnwithDefs.push(
                {
                    headerName: "No Of Boxes",
                    field: "no_boxes",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,                     
                },
                {
                    headerName: "Storage Location",
                    field: "storage_location",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,                     
                },
                {
                    headerName: "Batch",
                    field: "batch",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,                     
                },
                {
                    headerName: "Qunatity",
                    field: "item_quantity",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,                     
                }
            );
        }
        var columnwithDefs1 = [];
        if(localStorage.getItem("user_type") !="TRANSPORTER")
        {
            columnwithDefs1.push(
            {
                headerName: "Sales Order No",
                field: "sales_order_number",
                width:160,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Transporter",
                field: "transporter_name",
                width:200,
                filter: true,
                resizable: true,
                editable:false
            })
        }
        columnwithDefs1.push(
            {
                headerName: "Destination",
                field: "ship_city",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },     
            {
                headerName: "Customer Name",
                field: "customer_name",
                width:180,
                filter: true,
                resizable: true,
                editable:false,            
            },    
            {
                headerName: "Plant Name",
                field: "plant_name",
                width:200,
                filter: true,
                resizable: true,
                editable:false,    
                valueGetter: function(params){
                    return params.data.plant_code+" - "+params.data.plant_name+" - "+params.data.plant_location
                }        
            },     
            {
                headerName: "Expected Truck Type",
                field: "expected_truck_type",
                width:180,
                filter: true,
                resizable: true,
                editable:false  
            },    
            {
                headerName: "Remarks",
                field: "remarks",
                width:180,
                filter: true,
                resizable: true,
                editable:false  
            },
            {
                headerName: "Scheduled Date",
                field: "sch_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.sch_date !=undefined && params.data.sch_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.sch_date);
                    }
                }    
            },    
            {
                headerName: "Assigned Date",
                field: "created_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,  
                valueGetter: function(params){
                    if(params.data.created_date !=undefined && params.data.created_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date);
                    }
                }
            }
        )
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            columnwithDefs1.push({
                    headerName: "",
                    field: "reject",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,
                    pinned:"right",
                    cellRendererSelector:function(params){
                        var rendComponent1 = {
                            component: 'configureTptReject'
                        };
                        if(params.data.status == 1)
                        {
                            return rendComponent1;
                        }
                    },
                },
                {
                    headerName: "Assign Truck",
                    field: "assign_truck",
                    width:200,
                    filter: true,
                    resizable: true,
                    editable:false,
                    pinned:"right",
                    cellRendererSelector:function(params){
                        var rendComponent = {
                            component: 'assignTruckSelector'
                        };
                        return rendComponent;
                    }
                }
            )
        }
        else
        {
            columnwithDefs1.push(
                {
                    headerName: "Status",
                    field: "status",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,
                    pinned:"right",
                    valueGetter:function(params){
                        if(params.data.status == 2)
                        {
                            return "Rejected";
                        }
                    },
                }
            )
        }
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = {minHeight:"234px"};
		var filterCardStyle2 = {padding:'10px'};
		var mnFilterClass = {marginLeft:"0px", padding:"0px"};
        var userRoles = localStorage.getItem("roles");
        var typeLabel = "Delivery Order/ Sales Order / Truck No";
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            typeLabel = "Truck No";
        }
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12'>
                        <h5>
                            <i className="icofont icofont-dashboard cus-i"></i> <span>Dashboard</span>
                            {/* <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                            <button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
                            {(localStorage.getItem("user_type") == "TRANSPORTER" && this.state.pageType == 1) ?
                            <button className="btn btn-success" style={{float:"right",marginRight:"10px"}} onClick={this.onClickPage.bind(this,2)}>Assign Truck</button>
                            :""}
                            {(localStorage.getItem("user_type") == "TRANSPORTER" && this.state.pageType == 2) ?
                            <button className="btn btn-warning" style={{float:"right",marginRight:"10px"}} onClick={this.onClickPage.bind(this,1)}>Back</button>
                            :""}
                        </h5>
                    </div>
                </div>
                {/* <div className="row" style={{marginBottom:"1%"}}>
					<div className={"col-xl-12 col-lg-12 " +  this.state.showFiltersForLongHaulScreenTab}>
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
					</div>
				</div> */}
                {(userRoles.indexOf("SITE_ADMIN") >= 0) || userRoles.indexOf("warehouse") >= 0 || (userRoles.indexOf("plantadmin") >= 0) ?
                <div className="row" style={{marginTop:"1%"}}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">                                
                                <li onClick={this.onClickPage.bind(this,1)} className={"lttabs "+(this.state.assigned)}>
                                    <button type="button" className="btn" style={{paddingTop:"0px"}}>Trucks Assigned</button>
                                </li>

                                <li onClick={this.onClickPage.bind(this,2)} className={"lttabs "+(this.state.unassigned)}>
                                    <button type="button" className="btn" style={{paddingTop:"0px"}}>Trucks Unassigned</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                : ""}
                <div className="row" style={{marginTop:"1%"}}>
					<div className={"col-xl-4 col-lg-4"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.filterData.bind(this)}>
									<div className={filterClassName}>
										<label>Select Type :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedFilter"
										style={{borderRadius:"0px"}} 
										options={this.selectTypes()} required />
									</div>
									<div className={filterClassName}>
										<label className="reason">{typeLabel}</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment"/>
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-primary">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className={"col-xl-8 col-lg-8"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
                                <div className="row col-xl-12 col-lg-12">
                                    {(localStorage.getItem("user_type") != "TRANSPORTER") ?
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <label>Transporter :  </label>
                                        <Select 
                                        placeholder={"Select Transporter"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeTransporterItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.transporter}
                                        options={this.selecttransporters()} />  
                                    </div>
                                    :""}
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label>From Date</label>
                                        <Datetime value={this.state.startDate} 
                                        disableCloseOnClickOutside={false} 
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off',readOnly : true }}
                                        dateFormat="YYYY-MM-DD" 
                                        name="startDate" 
                                        onChange={this.handlerStartDateTime.bind(this)} />

                                    </div>

                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label>To Date</label>
                                        {/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
                                        <Datetime value={this.state.endDate} 
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off',readOnly : true}} 
                                        dateFormat="YYYY-MM-DD" 
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                        
                                    </div>
                                </div>	
							    <div className="row col-xl-12 col-lg-12">
									<div className="form-group col-xl-4 col-lg-4">
										<label>Destination: </label>
                                        <input type="text" name="ship_city" autoComplete="off" class="form-control" id="ship_city"/>
									</div>
									
									<div className="form-group col-xl-4 col-lg-4">
										<label>Select Customer: </label>
										<Select placeholder={"Select Customer"} value={this.state.customer} className="border-radius-0" onChange={this.onChangeCustomerItem.bind(this)} style={{borderRadius:"0px"}} options={this.selectcustomers()} />
									</div>

                                    <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-primary" onClick={this.onClickFilterButton.bind(this)}>Submit</button>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.pageType == 1)?
                <div className="row mt-10p">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("plantinofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"truckAssigned")}>
                                            <span className="f13">Truck Assigned</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.truckAssigned.length)?this.state.truckAssigned.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("plantinofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"securityRejected")}>
                                            <span className="f13">Security Rejected</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.securityRejected.length)?this.state.securityRejected.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("warehouse") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("gateinofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"gate1In")}>
                                            <span className="f13">Gate-1 In</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.gate1In.length)?this.state.gate1In.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"gate2In")}>
                                            <span className="f13">Gate-2 In</span>
                                            <h4 class="txt-secondary f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.gate2In.length)?this.state.gate2In.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) ?
                                        <div className="col cirlce-d">
                                            <span className="f13">Verification</span>
                                            <div className="row col-xl-12 col-lg-12 col-sm-12" style={{padding:"0",margin:"0"}}>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onClickTab.bind(this,"pendingForVerifictaion")}>
                                                    <p className="">Pending 
                                                        <span className={"txt-warning counter f18"} style={{marginLeft:"8%"}}>
                                                            <CountUp end={(this.state.pendingForVerifictaion.length)?this.state.pendingForVerifictaion.length:0}/>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onClickTab.bind(this,"verificationApproved")}>
                                                    <p className="">Approved
                                                        <span className={"txt-info counter f18"} style={{marginLeft:"8%"}}>
                                                            <CountUp end={(this.state.verificationApproved.length)?this.state.verificationApproved.length:0}/>
                                                        </span>                                                    
                                                    </p>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onClickTab.bind(this,"verificationRejected")}>
                                                    <p className="">Rejected 
                                                        <span className={"txt-danger counter f18"} style={{marginLeft:"8%"}}>
                                                            <CountUp end={(this.state.verificationRejected.length)?this.state.verificationRejected.length:0}/>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>                              
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("loadingofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"pendingForVerifictaion")}>
                                            <span className="f13">Pending Verification</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.pendingForVerifictaion.length)?this.state.pendingForVerifictaion.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("loadingofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"verificationApproved")}>
                                            <span className="f13">Approved</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.verificationApproved.length)?this.state.verificationApproved.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("loadingofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"verificationRejected")}>
                                            <span className="f13">Rejected</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.verificationRejected.length)?this.state.verificationRejected.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("loadingofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"loadingStarted")}>
                                            <span className="f13">Loading Started</span>
                                            <h4 class="txt-secondary f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.loadingStarted.length)?this.state.loadingStarted.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("gateinofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"loadingCompleted")}>
                                            <span className="f13">Loading Completed</span>
                                            <h4 class="txt-primary f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.loadingCompleted.length)?this.state.loadingCompleted.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) ?
                                        <div className="col cirlce-d">
                                            <span className="f13">Documentation</span>
                                            <div className="row col-xl-12 col-lg-12 col-sm-12" style={{padding:"0",margin:"0"}}>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onClickTab.bind(this,"documentationPending")}>
                                                    <p className="">Pending 
                                                        <span className={"txt-warning counter f18"} style={{marginLeft:"8%"}}>
                                                            <CountUp end={(this.state.documentationPending.length)?this.state.documentationPending.length:0}/>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{padding:"0",marginTop:"5%"}} onClick={this.onClickTab.bind(this,"documentationCompleted")}>
                                                    <p className="">Completed
                                                        <span className={"txt-info counter f18"} style={{marginLeft:"8%"}}>
                                                            <CountUp end={(this.state.documentationCompleted.length)?this.state.documentationCompleted.length:0}/>
                                                        </span>                                                    
                                                    </p>
                                                </div>
                                            </div>                              
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("billing") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"documentationPending")}>
                                            <span className="f13">Documentation Pending</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.documentationPending.length)?this.state.documentationPending.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("billing") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"documentationCompleted")}>
                                            <span className="f13">Documentation Completed</span>
                                            <h4 class="txt-secondary f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.documentationCompleted.length)?this.state.documentationCompleted.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) || (userRoles.indexOf("plantinofficer") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"gate2Out")}>
                                            <span className="f13">Gate-2 Out</span>
                                            <h4 class="darkorange f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.gate2Out.length)?this.state.gate2Out.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                        {(userRoles.indexOf("SITE_ADMIN") >= 0) || (userRoles.indexOf("TRANSPORTER") >= 0) || (userRoles.indexOf("plantadmin") >= 0) ?
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"gate1Out")}>
                                            <span className="f13">Gate-1 Out</span>
                                            <h4 class="maroon f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.gate1Out.length)?this.state.gate1Out.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        :""}
                                    </div>                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                :""}

                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                        {(this.state.pageType == 1 && localStorage.getItem("roles").indexOf("TRANSPORTER") == -1 && localStorage.getItem("roles").indexOf("supervisor") == -1)?
                        <div className='row' style={{marginBottom:"1%"}}>
                            <div className='col-sm-6'></div>
                            <div className='col-sm-6'>
                                <i class="icofont icofont-list themefont-alt f20" title="Item Details"></i> Item Details &nbsp;&nbsp;
                                <i class="icofont icofont-checked themefont-alt f20" title="Security Check List"></i> Security Check List &nbsp;&nbsp;
                                <i class="icofont icofont-truck-alt themefont-alt f20" title="Vehicle Checklist"></i> Vehicle Checklist &nbsp;&nbsp;
                                <i class="icofont icofont-qr-code themefont-alt f20" title="QR Code"></i> QR Code &nbsp;&nbsp;
                            </div>
                        </div>
                        :""}
                        {(this.state.pageType == 1)?
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData}
                                />
                        </div>
                        :
                        
                        <div id="myGrid1" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs1}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.unAssignrowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady1}
                                onGridState={this.onGridState1}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData}
                                />
                        </div>
                        }
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"auto"}}>
                    <h4 className="subH">
                        {this.state.sliderTitle}
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                            {(this.state.sliderType < 3 && this.state.status > 1) ?
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Truck No</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.truck_no}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Transporter</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.transporter_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :""}
                            {(this.state.sliderType < 3 && this.state.status > 1) ?
                            <div className="row mt-20p">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Sold Party</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.sold_party}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Ship Party</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.ship_party}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Destination</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.ship_city}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :""}
                            {(this.state.status > 1 && this.state.sliderType < 3) ?
                            <div className="row mt-20p">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Plant Name</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.plant_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Driver Name</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.driver_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Driver Mobile</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.driver_mobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :""}
                            {(this.state.sliderType == 2) ?
                            <div className='row col-sm-12 mt-20p'>
                                <div className='row col-sm-12'>
                                    <label className="">Items List</label>
                                </div>
                                {this.state.itemQtyDetails.map((itd) => {
                                    return <div className="row col-sm-12">
                                        <div className="col-sm-6">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>{itd.ITEM}</h6>
                                        </div> 
                                        <div className="col-sm-6">
                                            {itd.CONFIRM_QTY}
                                        </div>
                                    </div>
                                })}
                            </div> 
                            :""}
                            {(this.state.sliderType == 1 || this.state.sliderType == "1") ?
                            <form method="POST" id="upform" className="theme-form mt-20p" onSubmit={this.updateStatus}>
                                {(this.state.status == 1) ?
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist-1">1. Driver Licence</label>
                                        <div class="form-check form-check-inline">
                                            <input class="datepicker" type="text" name="driver_licence_date" id="driver_licence_date" placeholder='Enter Validity' autoComplete='off'/>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="driver_licence" id="driver_licence_ok" value="Ok"/>
                                            <label class="form-check-label" for="driver_licence_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="driver_licence" id="driver_licence_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="driver_licence_not_ok">Not Ok</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist-1">2. Truck Insurance</label>
                                        <div class="form-check-inline">
                                            <input class="datepicker" type="text" name="truck_insurance_date" id="truck_insurance_date" placeholder='Enter Validity' autoComplete='off'/>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_insurance" id="truck_insurance_ok" value="OK"/>
                                            <label class="form-check-label" for="truck_insurance_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_insurance" id="truck_insurance_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="truck_insurance_not_ok">Not Ok</label>
                                        </div>
                                    </div>                                    
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist-1">3. PUC</label>
                                        <div class="form-check form-check-inline">
                                            <input class="datepicker" type="text" name="puc_date" id="puc_date" placeholder='Enter Validity' autoComplete='off'/>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="puc" id="puc_ok" value="OK"/>
                                            <label class="form-check-label" for="puc_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="puc" id="puc_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="puc_not_ok">Not Ok</label>
                                        </div>
                                    </div>                                    
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist-1">4. Truck Fitness</label>
                                        <div class="form-check-inline">
                                            <input class="datepicker" type="text" name="truck_fitness_date" id="truck_fitness_date" placeholder='Enter Validity' autoComplete='off'/>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_fitness" id="truck_fitness_ok" value="OK"/>
                                            <label class="form-check-label" for="truck_fitness_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_fitness" id="truck_fitness_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="truck_fitness_not_ok">Not Ok</label>
                                        </div>
                                    </div>                                    
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist-1">5. Truck Road Tax</label>
                                        <div class="form-check form-check-inline">
                                            <input class="datepicker" type="text" name="truck_road_tax_date" id="truck_road_tax_date" placeholder='Enter Validity' autoComplete='off'/>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_road_tax" id="truck_road_tax_ok" value="OK"/>
                                            <label class="form-check-label" for="truck_road_tax_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_road_tax" id="truck_road_tax_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="truck_road_tax_not_ok">Not Ok</label>
                                        </div>
                                    </div>                                    
                                    <div className='col-sm-12' style={{margin:"2% 0"}}>
                                        <div class="form-check form-check-inline">                                            
                                            <input class="form-check-input" type="checkbox" name="reverse_horn" id="reverse_horn" value="Ok"/>
                                            <label class="form-check-label" for="reverse_horn">Reverse Horn</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input class="form-check-input" type="checkbox" name="side_view_mirror" id="side_view_mirror" value="Ok"/>
                                            <label class="form-check-label" for="side_view_mirror">Side View Mirror</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input class="form-check-input" type="checkbox" name="hand_break" id="hand_break" value="Ok"/>
                                            <label class="form-check-label" for="hand_break">Hand Break</label>
                                        </div>
                                    </div> 
                                    <div className='col-sm-12 mt-10p'>
                                        <label className="">Approve/Reject</label>
                                        <select name="security_verification" id="security_verification" onChange={this.changeSecurityApproveOption} className="form-control" required>
                                            <option value="">Select Option</option>
                                            <option value="1">Approve</option>
                                            <option value="2">Reject</option>
                                        </select>
                                    </div>
                                </div>
                                :""}
                                {(this.state.status == 5 && this.state.documentation_completed == 0)?
                                <div className="row">
                                    <div className="form-group mt-10p col-sm-6">
                                        <label className="">LR Number</label> 
                                        <input type="text" name="lr_no" id="lr_no" onChange={this.changeHandler} autoComplete="off" className="form-control" value={this.state.lr_no} required />
                                    </div>
                                    <div className="form-group mt-10p col-sm-6">
                                        <label className="">No Of Boxes</label> 
                                        <input type="text" name="no_boxes" id="no_boxes" autoComplete="off" className="form-control" required />
                                    </div>
                                    <div className="form-group mt-10p col-sm-4">
                                        <label className="">Storage Location</label> 
                                        {(this.state.stolList.length > 0)?
                                        <select name="storage_location" id="storage_location" className="form-control" required>
                                            <option value="">Select Value</option>
                                            {(this.state.stolList.map(function(st){
                                                return <option value={st}>{st}</option>
                                            }))}
                                        </select>
                                        :
                                        <input type="text" name="storage_location" id="storage_location" autoComplete="off" className="form-control" required />
                                        }
                                    </div>
                                    <div className="form-group mt-10p col-sm-4">
                                        <label className="">Batch</label> 
                                        {(this.state.batch.length > 0)?
                                        <select name="batch" id="batch" className="form-control" required>
                                            <option value="">Select Value</option>
                                            {(this.state.batch.map(function(bt){
                                                return <option value={bt}>{bt}</option>
                                            }))}
                                        </select>
                                        :
                                        <input type="text" name="batch" id="batch" autoComplete="off" className="form-control" required />
                                        }
                                    </div>
                                    <div className="form-group mt-10p col-sm-4">
                                        <label className="">Item Quantity</label> 
                                        <input type="text" name="item_quantity" id="item_quantity" onChange={this.changeHandler} autoComplete="off" className="form-control" value={this.state.item_quantity} required />
                                    </div>
                                </div>
                                :""}
                                <div className="form-group mt-10p" id="gateInDate">
                                    <label className="">{this.state.labelName}</label> 
                                    <input type="text" name="dateField" id="dateField" className="form-control datetimepicker" required value={this.state.dateField} onChange={this.changeHandler}/>
                                </div>
                                {(this.state.status == 2 && this.state.agi_do_number == undefined || this.state.agi_do_number == "")?                                                                   
                                <div className='row' style={{margin:"2% 0"}}>
                                    <div className='row col-sm-12'>
                                        <label className="">Items List</label>
                                    </div>
                                    <div className='row col-sm-12'>
                                        {this.state.itemDetails.map((itd) => {
                                            return <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="item_chk_list" id={itd.ITEM} value={itd.ITEM} onChange={this.getItemQuantity} />
                                                <label class="form-check-label" for={itd.ITEM}>{itd.ITEM}</label>&nbsp;&nbsp;
                                            </div>
                                        })}
                                    </div>
                                    {(this.state.confirm_quantity !=undefined) ?
                                    <div className='row col-sm-12 mt-10p'>
                                        <label className="">Item Quantity</label> 
                                        <input type="text" name="confirm_quantity" id="confirm_quantity" onChange={this.changeHandler} autoComplete="off" className="form-control num-cls" value={this.state.confirm_quantity} required />   
                                    </div>
                                    :""}
                                </div> 
                                :""}
                                {(this.state.status == 4)?
                                <div className="form-group mt-10p">
                                    <label className="">Upload Image</label> 
                                    <input
                                    type="file"
                                    label="Image"
                                    name="myFile"
                                    id="myFile"
                                    accept=".jpeg, .png, .jpg"
                                    className="form-control"
                                    onChange={(e) => this.handleFileUpload(e)}
                                    />
                                </div>
                                :""}
                                {(this.state.myFile !=undefined && this.state.myFile !="")?
                                <div style={{display:"flex",width:"100%",justifyContent:"center",height:"120px"}}>
                                    <img src={this.state.myFile}></img>
                                </div>
                                :""}
                                <div className="form-group mt-10p">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            :""}
                            {(this.state.sliderType == "2" || this.state.sliderType == 2) ?
                            <form method="POST" id="upform" className="theme-form mt-20p" onSubmit={this.updateApproveStatus}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">1. Truck Platform Condition</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_condition" id="truck_condition_ok" value="Ok"/>
                                            <label class="form-check-label" for="truck_condition_ok">Ok</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="truck_condition" id="truck_condition_not_ok" value="Not Ok"/>
                                            <label class="form-check-label" for="truck_condition_not_ok">Not Ok</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">2. Any Angles/Bolts/Nuts Projected</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="abnprojected" id="abnprojected_no" value="No"/>
                                            <label class="form-check-label" for="abnprojected_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="abnprojected" id="abnprojected_yes" value="Yes"/>
                                            <label class="form-check-label" for="abnprojected_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">3. Any Chemical/dust/powder/Cement/Rust Contamination on truck platform</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="acdpcrcontamination" id="acdpcrcontamination_no" value="No"/>
                                            <label class="form-check-label" for="acdpcrcontamination_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="acdpcrcontamination" id="acdpcrcontamination_yes" value="Yes"/>
                                            <label class="form-check-label" for="acdpcrcontamination_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">4. Any foul smell in truck</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="foulsmell" id="foulsmell_no" value="No"/>
                                            <label class="form-check-label" for="foulsmell_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="foulsmell" id="foulsmell_yes" value="Yes"/>
                                            <label class="form-check-label" for="foulsmell_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">5. Are they having hygenic condition tarpaulins i.e, free from dust/smell/holes</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="hygeniccondition" id="hygeniccondition_no" value="No"/>
                                            <label class="form-check-label" for="hygeniccondition_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="hygeniccondition" id="hygeniccondition_yes" value="Yes"/>
                                            <label class="form-check-label" for="hygeniccondition_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">6. Whether three no of tarpaulins available</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tarpaulins" id="tarpaulins_no" value="No"/>
                                            <label class="form-check-label" for="tarpaulins_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tarpaulins" id="tarpaulins_yes" value="Yes"/>
                                            <label class="form-check-label" for="tarpaulins_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">7. Are they having proper two ropes</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tworopes" id="tworopes_no" value="No"/>
                                            <label class="form-check-label" for="tworopes_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tworopes" id="tworopes_yes" value="Yes"/>
                                            <label class="form-check-label" for="tworopes_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <label className="vehicle-chklist">8. Are they following loading practices</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="loadingpractices" id="loadingpractices_no" value="No"/>
                                            <label class="form-check-label" for="loadingpractices_no">No</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="loadingpractices" id="loadingpractices_yes" value="Yes"/>
                                            <label class="form-check-label" for="loadingpractices_yes">Yes</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 mt-10p'>
                                        <label className="">Approve/Reject</label>
                                        <select name="verification" id="verification" onChange={this.changeApproveOption} className="form-control" required>
                                            <option value="">Select Option</option>
                                            <option value="1">Approve</option>
                                            <option value="2">Reject</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-12 mt-10p'>
                                        <label className="">Remarks</label>
                                        <input type="text" name="remarks" id="remarks" className="form-control" required  />
                                    </div>
                                    <div className='col-sm-12 mt-20p'>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </div>
                            </form>
                            :""}
                            {(this.state.sliderType == 4 || this.state.sliderType == "4")?
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.finalApproveTruck}>
                                <div className='row'>
                                    <div className='col-sm-12 mt-10p'>
                                        <label className="">Final Approve/Reject</label>
                                        <select name="final_approve_status" id="final_approve_status" onChange={this.changeApproveOption} className="form-control" required>
                                            <option value="">Select Option</option>
                                            <option value="1">Approve</option>
                                            <option value="2">Reject</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-12 mt-10p'>
                                        <label className="">Remarks</label>
                                        <input type="text" name="final_remarks" id="final_remarks" className="form-control"  />
                                    </div>
                                    <div className='col-sm-12 mt-20p'>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                    </div>
                                </div>
                            </form> 
                            : ""}
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.assignslide)} style={{overflow:"hidden"}}>
                    <h4 className="subH">
                        {this.state.sliderTitle}
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">                            
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.assignTruckToSalesOrder}>
                                <div className='col-sm-12 mt-10p'>
                                    <label className="">Truck</label>
                                    {/* <Select 
                                        placeholder={"Select Truck"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeTruckItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.truck}
                                        options={this.selecttrucks()} /> */}
                                    <input type="text" name="truck_no" id="truck_no" value={this.state.truck_no} className="form-control" onChange={this.changeHandler} required  />                                    
                                </div>
                                <div className="col-sm-12 mt-20p">
                                    <div className="form-group">
                                        <label className="">Truck Type</label> 
                                        <input type="text" name="truck_capacity" id="truck_capacity" value={this.state.truck_capacity} className="form-control" onChange={this.changeHandler} required  />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-20p'>
                                    <div className="form-group">
                                        <label className="">Reporting Time</label> 
                                        <input type="text" name="reporting_time" id="reporting_time" autoComplete='off' className="form-control datetimepicker" required  />
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-20p">
                                    <div className="form-group">
                                        <label className="">Driver Name</label> 
                                        <input type="text" name="driver_name" id="driver_name" className="form-control" required  />
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-20p">
                                    <div className="form-group">
                                        <label className="">Driver Mobile</label> 
                                        <input type="text" name="driver_mobile" id="driver_mobile" className="form-control" required  />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-20p'>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} closeOnOverlayClick={false} styles={{ width: "500px" }} >
                    <div className="row">
                        {(this.state.imgSrc !=undefined && this.state.imgSrc !="")?
                        <div className='col-sm-12'>
                            <img style={{width:"250px"}} src={this.state.imgSrc}/>
                        </div>
                        : ""}
                        {(this.state.uploadedFile !=undefined && this.state.uploadedFile !="" && this.state.uploaded_from == 1) ? 
                        <div className='col-sm-12' style={{display:"flex",width:"100%",justifyContent:"center",height:"350px"}}>
                            <img src={this.state.uploadedFile}/>
                        </div>
                        :""}
                        {(this.state.uploadedFile !=undefined && this.state.uploadedFile !="" && this.state.uploaded_from == 2) ? 
                        <div className='col-sm-12' style={{display:"flex",width:"100%",justifyContent:"center",height:"350px"}}>
                            <img src={"data:application/octet-stream;base64,"+this.state.uploadedFile}/>
                        </div>
                        :""}
                    </div>
                </Modal>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadDateTimeScript(){
	// alert("datetime");
    $( document ).ready(function() {
        $('.datetimepicker').datetimepicker({
            datepicker: true,
            // minuteStepping:30,
            format: 'Y-m-d H:i',
        });
        $('.datepicker').datetimepicker({
            datepicker: true,
            timepicker: false,
            // minuteStepping:30,
            format: 'Y-m-d',
        });
    });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}

