import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';


import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
} from '../common/utils';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}

export default class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
			pageTitle: "Users",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add User",
            rowId : "",
            plantsList : [],
            plant:{value:"",label:"Select Plant"},
        };
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        var fdate = moment.parseZone().format("DD-MM-YYYY");
        var currentdate = moment.parseZone().format("DD-MM-YYYY");
        var params = {status:1}
        redirectURL.post("/manage/getPlantsList",params).then(async(response)=>{
            var plantsList = response.data;
            this.setState({
                plantsList : plantsList,
            })
        }).catch((e)=>{
            console.log(e);
        })
        this.loadPageData();
    }
    
    loadPageData = () =>{
        redirectURL.post("/manage/getUsersList").then(async(response)=>{
            console.log(response.data);
            var rowData = response.data;
            await rowData.map(function(r){
                if(r.verified == 1)
                {
                    r.verified = "Active";
                }
                else
                {
                    r.verified = "In Active";
                }
            })
            this.setState({
                rowData : response.data
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        //console.log(this.gridApi);    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
        });
    }
    showBulkUpload(){
        this.setState({
            sliderType : 2,
            sliderTitle : "Bulk Upload",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                { 
                    name: 'First Name',
                    inputName: 'firstname',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Last Name',
                    inputName: 'lastname',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Email',
                    inputName: 'email',
                    required: false
                },
                { 
                    name: 'Plant',
                    inputName: 'plant_code',
                    required: false
                }                   
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData,"csvData")
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
    
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    saveNewRowData = (e) => {
        e.preventDefault();
        var firstname = $("#firstname").val();
        var lastname = $("#lastname").val();
        var email = $("#email").val();
        var user_type = $("#user_type").val();
        var plant = this.state.plant;
        if(firstname !=undefined && firstname !="" && email !=undefined && email !="" && email !=undefined && user_type !=undefined && user_type !="" && plant.value !=undefined && plant.value !="")
        {
            var plantName = "";
            var plantLocation = "";
            if(plant.label !=undefined && plant.label !="")
            {
                if(plant.label.includes("-") == true)
                {
                    var plantLabel = plant.label.split("-");
                    plantName = plantLabel[1].trim();
                    plantLocation = plantLabel[2].trim();
                }
            }
            this.setState({
                loadshow : "show-m",
                overly : "show-m"
            });
            var params = {
                firstname : firstname,
                lastname : lastname,
                email : email,
                user_type : user_type,
                plant_code : plant.value,
                plant_name : plantName,
                plant_location : plantLocation,
            }
            redirectURL.post("/manage/saveusers",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"User Created Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        bulkslide:"",
                        sliderType:"",
                    })
                    this.loadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }
            })
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Fields Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/manage/savebulkusers",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        overly:'show-n',
                    })
                    this.loadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    addNewRow = () => {
        this.setState({
            sliderType : 1,
            sliderTitle : "Add User",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    onCellClicked = (e) =>{
        if(e.colDef.field == "edit")
        {
            var rowId = e.data._id;
            var firstname = e.data.firstname;
            var lastname = e.data.lastname;
            console.log(firstname,lastname,"firstname")
            this.setState({
                sliderType : 3,
                sliderTitle : "Edit User",
                rowId:rowId,
                overly :"show-m",
                bulkslide : "slider-translate",
            })
            $("#firstname").val("");
            $("#firstname").val(firstname);
            $("#lastname").val("");
            $("#lastname").val(lastname);
        }
    }
    onCellUpdateData = (e) =>{
        if(e.colDef.field == "verified")
        {
            var verified = e.data.verified;
            if(verified !=undefined && verified !="")
            {
                var rowId = e.data._id;
                var params = {
                    rowId : rowId,
                    verified : verified,
                }
                redirectURL.post("/manage/updateuserstatus",params).then((response)=>{
                    console.log(response);
                    this.loadPageData();
                })
            }
        }
    }
    onChangePlantItem = (plant) => {
		this.setState(
			{plant},
			() => console.log(`plant Option selected:`, this.state.plant)
        );
	}
    selectplants = () => {
		let items = [];    
		try{
			if(this.state.plantsList.length > 0)
			{
				this.state.plantsList.map((item) =>{
					items.push({"value":item.plant_code,"label":item.plant_code+" - "+item.plant_name+" - "+item.plant_location})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
	render(){
        var columnwithDefs = [      
            {
                headerName: "User Name",
                field: "username",
                width:170,
                filter: true,
                resizable: true,
                editable:false,            
            },    
            {
                headerName: "Email",
                field: "email",
                width:250,
                filter: true,
                resizable: true,
                editable:false
            }, 
            {
                headerName: "Login Password",
                field: "password_text",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },         
            {
                headerName: "User Type",
                field: "roles",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.roles !=undefined && params.data.roles !="")
                    {
                        var userType = params.data.roles[0];
                        if(userType == "plantinofficer")
                        {
                            return "Gate 1 Officer";
                        }
                        else if(userType == "gateinofficer")
                        {
                            return "Gate 2 Officer";
                        }
                        else if(userType == "loadingofficer")
                        {
                            return "Loading Officer";
                        }
                        else if(userType == "plantadmin")
                        {
                            return "Plant Admin";
                        }
                        else if(userType == "warehouse")
                        {
                            return "Warehouse";
                        }
                        else if(userType == "marketing")
                        {
                            return "Marketing";
                        }
                        else if(userType == "supervisor")
                        {
                            return "Supervisor";
                        }
                        else if(userType == "billing")
                        {
                            return "Billing";
                        }
                    }
                }       
            },   
            {
                headerName: "Plant",
                field: "plant_name",
                width:220,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    return params.data.plant_code+" - "+params.data.plant_name+" - "+params.data.plant_location
                }         
            },
            {
                headerName: "Status",
                field: "verified",
                width:120,
                filter: true,
                resizable: true,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Active","In Active"],
                },
            },
            // {
            //     headerName: "Actions",
            //     field: "edit",
            //     width:100,
            //     filter: true,                
			// 	cellRenderer: (params) => {
            //         return '<i class="fa fa-pencil-square-o"></i>';
			// 	},
            // },  
        ]
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12'>
                        <h5>
                            <i className="icofont icofont-users cus-i"></i> <span>Users</span>
                            {/* <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                            <button className="btn btn-success" style={{float:"right",marginRight:"10px"}} onClick={this.addNewRow.bind(this)}>Add User</button>
                        </h5>
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                onCellClicked={this.onCellClicked}
                                onCellEditingStopped={this.onCellUpdateData}
                                />
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h4 className="subH">
                        {this.state.sliderTitle}
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            {(this.state.sliderType == 1 || this.state.sliderType == 3)?
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.saveNewRowData}>
                                <div className="form-group">
                                    <label className="">First Name</label> 
                                    <input type="text" name="firstname" id="firstname" className="form-control" required  />
                                </div>
                                <div className="form-group">
                                    <label className="">Last Name</label> 
                                    <input type="text" name="lastname" id="lastname" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label className="">Email</label> 
                                    <input type="text" name="email" id="email" className="form-control"  required/>
                                </div>
                                <div className="form-group">
                                    <label className="">User Type</label> 
                                    <select type="text" name="user_type" id="user_type" className="form-control" required>
                                        <option value="">Select User Type</option>
                                        <option value="plantadmin">Plant Admin</option>
                                        <option value="warehouse">Warehouse</option>
                                        <option value="marketing">Marketing</option>
                                        <option value="plantinofficer">Gate 1 Officer</option>
                                        <option value="gateinofficer">Gate 2 Officer</option>
                                        <option value="loadingofficer">Loading Officer</option>
                                        <option value="supervisor">Supervisor</option>
                                        <option value="billing">Billing</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="">Plant</label> 
                                    <Select 
                                    placeholder={"Select Plant"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangePlantItem} 
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.plant}
                                    options={this.selectplants()} />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            :""}
                            {(this.state.sliderType == 2)?
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                                <div className="form-group">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                            :""}
                            {(this.state.sliderType == 2)?
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/sample_transporters.csv')} target="_blank">Sample Template</a>
                            </div>	
                            :""}
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

