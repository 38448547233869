import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';
import Modal from 'react-responsive-modal';
import configureAction from './configureaction';
import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
} from '../common/utils';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}

export default class IndentReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
                configureAction : configureAction
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams:{},
            animateRows: true,
			pageTitle: "Assigned Trucks",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add Plant",
            rowId : "",
            transporters : [],
            transporter : {label:"Select Transporter",value:""},
            customer : {label:"Select Customer",value:""},
            allrowData : [],
            truckAssigned : [],
            truckUnAssigned : [],
            rejected : [],
            gate1In : [],
            gate2In : [],
            loadingStarted : [],
            loadingCompleted : [],
            gate2Out : [],
            gate1Out : [],
            open: false,
            labelName : "",
            sales_order_number : "",
            status : "",
            rowDetails : "",
            startDate : "",
            plantsList : [],
            customersList : [],
            defaultsdate : moment.parseZone().subtract(30,"days").format("YYYY-MM-DD"),
            defaultedate : moment.parseZone().format("YYYY-MM-DD"),
            startDate : moment.parseZone().subtract(180,"days").format("YYYY-MM-DD"),
            endDate : moment.parseZone().format("YYYY-MM-DD"),
        };
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        loadDateTimeScript();
        var fdate = moment.parseZone().format("DD-MM-YYYY");
        var currentdate = moment.parseZone().format("DD-MM-YYYY");
        var params = {
            status : 1
        }
        redirectURL.post("/manage/getTransportersList",params).then(async(response)=>{
            console.log(response.data);
            this.setState({
                transporters : response.data
            })
        }).catch((e)=>{
            console.log(e);
        })
        redirectURL.post("/manage/getCustomerAndPlantsList").then(async(response)=>{
            var plantsList = response.data.plants;
            var customersList = response.data.customers;
            this.setState({
                plantsList : plantsList,
                customersList : customersList,
            })
        }).catch((e)=>{
            console.log(e);
        })
        this.loadPageData();
    }
    
    loadPageData = () =>{
        var params = {};
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            params.transporter_code = localStorage.getItem("transportercode");
        }
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			params.startDate = this.state.defaultsdate;
		}
		else{
			params.startDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			params.endDate = this.state.defaultedate;
		}
		else{
			params.endDate = this.state.endDate;
		}
        if(localStorage.getItem("plant_code") != undefined && localStorage.getItem("plant_code") != "undefined" && localStorage.getItem("plant_code") != "")
        {
            params.plant_code = localStorage.getItem("plant_code");
        }
        redirectURL.post("/manage/getIndentReport",params).then(async(response)=>{
            console.log(response.data);
            var rowData = response.data;
            var truckAssigned = rowData.filter((e) => e.assigned_check == 1);
            var truckUnAssigned = rowData.filter((e) => e.assigned_check != 1 &&  e.status != 2);
            var rejected = rowData.filter((e) => e.status == 2);
            this.setState({
                allrowData : rowData,
                rowData : rowData,
                truckAssigned : truckAssigned,
                truckUnAssigned : truckUnAssigned,
                rejected : rejected
            })
        }).catch((e)=>{
            console.log(e);
        })
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        //console.log(this.gridApi);    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
        });
    }
    showBulkUpload(){
        this.setState({
            sliderType : 2,
            sliderTitle : "Bulk Upload",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                { 
                    name: 'plant_code',
                    inputName: 'plant_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'plant_name',
                    inputName: 'plant_name',
                    required: false,
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData,"csvData")
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
    
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
        
    }
    updateStatus = (e) => {
        e.preventDefault();
        var dateField = $("#dateField").val();
        var sales_order_number =  this.state.sales_order_number;
        var status =  this.state.status;
        var labelName =  this.state.labelName;
        console.log(dateField,sales_order_number,status);
        if(dateField !=undefined && dateField !="")
        {
            if(sales_order_number !=undefined && sales_order_number !="" && status !=undefined && status !="")
            {
                this.setState({
                    loadshow : "show-m",
                    overly : "show-m"
                });
                var check = 0;
                var errorMsg = "";
                var rowDetails = this.state.rowDetails
                if(status == 1)
                {
                    var check = 1;
                }
                if(status == 2)
                {
                    if(rowDetails.gate1_in_time !=undefined && rowDetails.gate1_in_time !="")
                    {
                        var gate1_in_time = moment.parseZone(new Date(rowDetails.gate1_in_time)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(gate1_in_time));
                        console.log(gate1_in_time,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Gate-2 In Time Should be Grater than Gate-1 In Time";
                        }
                    }
                }
                if(status == 3)
                {
                    if(rowDetails.gate2_in_time !=undefined && rowDetails.gate2_in_time !="")
                    {
                        var gate2_in_time = moment.parseZone(new Date(rowDetails.gate2_in_time)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(gate2_in_time));
                        console.log(gate2_in_time,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Loading Started On Time Should be Grater than Gate-2 In Time";
                        }
                    }
                }
                if(status == 4)
                {
                    if(rowDetails.loading_started_on !=undefined && rowDetails.loading_started_on !="")
                    {
                        var loading_started_on = moment.parseZone(new Date(rowDetails.loading_started_on)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(loading_started_on));
                        console.log(loading_started_on,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Loading Completed On Time Should be Grater than Loading Started On Time";
                        }
                    }
                }
                if(status == 5)
                {
                    if(rowDetails.loading_completed_on !=undefined && rowDetails.loading_completed_on !="")
                    {
                        var loading_completed_on = moment.parseZone(new Date(rowDetails.loading_completed_on)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(loading_completed_on));
                        console.log(loading_completed_on,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Gate-2 Out Time Should be Grater than Loading Completed On Time";
                        }
                    }
                }
                if(status == 6)
                {
                    if(rowDetails.gate2_out_time !=undefined && rowDetails.gate2_out_time !="")
                    {
                        var gate2_out_time = moment.parseZone(new Date(rowDetails.gate2_out_time)).utcOffset("-05:30")._d;
                        var dateToChk = dateField+":00";
                        dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                        var comparision = moment(dateToChk).isAfter(moment(gate2_out_time));
                        console.log(gate2_out_time,dateToChk)
                        console.log(comparision,"comparision")
                        if(comparision == true)
                        {
                            check = 1;
                        }
                        else
                        {
                            check = 0;
                            errorMsg = "Gate-1 Out Time Should be Grater than Gate-2 Out Time";
                        }
                    }
                }
                var params = {
                    dateField : dateField,
                    sales_order_number : sales_order_number,
                    status : status
                }
                if(check == 1)
                {
                    redirectURL.post("/manage/changeAssignedTruckStatus",params).then((response)=>{
                        console.log(response);
                        if(response.data.message == "Success")
                        {
                            this.setState({
                                basicTitle:"Updated Successfully.",
                                basicType : "success",
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n",
                                bulkslide:"",
                                sliderType:"",
                            })
                            $("#dateField").val("");
                            this.loadPageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:response.data.message,
                                basicType : "warning",
                                show : true,
                                loadshow : "show-n",
                                overly : "show-n"
                            })
                        }
                    })
                }
                else
                {
                    this.setState({
                        show : true,
                        basicTitle : errorMsg,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    });
                }
            }
            else
            {
                this.setState({
                    show : true,
                    basicTitle : "Something went wrong.",
                    basicType : "warning",
                    show : true,
                    loadshow : "show-n",
                    overly : "show-n"
                });
            }
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : labelName+" Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/manage/savebulkplants",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        overly:'show-n',
                    })
                    this.loadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    addNewRow = () => {
        window.location.href="/assigntruck";
    }
    onCellClicked = async(params) =>{
        if(params.colDef.field == "item_detais")
        {
            if(params != "")
            {
                var itemDetails=[];
                console.log("Step 3 ",itemDetails)
                var propsdata = params;
                await this.setState({
                        
                    detailCellRendererParams:{
                        detailGridOptions: {
                            columnDefs: [
                                { headerName: "Item",field: "SO_ITEM", filter:true,resizable: true },
                                { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                                { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                            ],
                            overlayNoRowsTemplate: 'No rows to show',
                                                            
                        },
                        getDetailRowData: function(param) {
                            if(params.data.item_details !=undefined && params.data.item_details !="")
                            {
                                itemDetails = JSON.parse(params.data.item_details);
                            }
                            param.successCallback(itemDetails);                            
                        },
                        masterDetail: true
                    }
                });
                
                if(propsdata.column.colDef.field == 'item_detais')
                {
                    propsdata.node.setExpanded(!propsdata.node.expanded);
                }
                else{
                    propsdata.node.setExpanded(false);
                }
                console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
            }
            else{
                this.setState({
                
                    detailCellRendererParams:{
                        detailGridOptions: {
                        columnDefs: [
                            { headerName: "Item Code",field: "ITEM", filter:true,resizable: true },
                            { headerName: "Item Description",field: "ITEM_DES", filter:true,resizable: true },
                            { headerName: "Quantity",field: "CONFIRM_QTY", filter:true,resizable: true },
                        ]
                        
                        },
                        getDetailRowData: function(params) {
                            //console.log("Child ", params);
                        params.successCallback([]);
                        }
                    }
                });
            }
        }
        if(params.colDef.field == "qr_code")
        {
            var query = {
                delivery_order_number : params.data.delivery_order_number
            }
            redirectURL.post("/manage/generateQrCode",query).then((response)=>{
                console.log(typeof(response.data),"typeof");
                
                this.setState({
                    imgSrc: response.data,
                    open: true,
                })
            })
        }
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onCellUpdateData = (e) =>{
        if(e.colDef.field == "status")
        {
            var status = e.data.status;
            if(status !=undefined && status !="")
            {
                var rowId = e.data._id;
                var params = {
                    rowId : rowId,
                    status : status,
                }
                redirectURL.post("/manage/updateplantstatus",params).then((response)=>{
                    console.log(response);
                    this.loadPageData();
                })
            }
        }
    }
    onChangeTransporterItem = (transporter) => {
		this.setState(
			{ transporter : transporter},
			() => console.log(`Transporter Option selected:`, this.state.transporter)
          );
	}
    selecttransporters(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    onChangeCustomerItem = (customer) => {
		this.setState(
			{ customer : customer},
			() => console.log(`customer Option selected:`, this.state.customer)
          );
	}
    selectcustomers(){
		let items = [];    
		try{
			if(this.state.customersList.length > 0)
			{
				this.state.customersList.map((item) =>{
					items.push({"value":item.customer_name,"label":item.customer_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    changeStatus = (e) => {
        var sales_order_number = e.data.sales_order_number;
        var status = e.data.status;
        var labelName = "";
        if(status == 1)
        {
            labelName = "Gate-1 In Time";
        }
        else if(status == 2)
        {
            labelName = "Gate-2 In Time";
        }
        else if(status == 3)
        {
            labelName = "Loading Started On";
        }
        else if(status == 4)
        {
            labelName = "Loading Completed On";
        }
        else if(status == 5)
        {
            labelName = "Gate-2 Out Time";
        }
        else if(status == 6)
        {
            labelName = "Gate-1 Out Time";
        }
        if(status < 7)
        {
            this.setState({
                labelName : labelName,
                sales_order_number : sales_order_number,
                status : status,
                sliderTitle : "Update "+labelName+" - "+sales_order_number,
                overly :"show-m",
                bulkslide : "slider-translate-40p",
                rowDetails : e.data
            });
        }
    }
    filterData = (event) => {
        this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
        if(selectedFilter !=undefined && selectedFilter !="" && inputTruckConsignment !=undefined && inputTruckConsignment !="")
        {
            var params = {
				"selectedFilter":selectedFilter,
				"inputTruckConsignment":inputTruckConsignment,
			}
            if(localStorage.getItem("user_type") == "TRANSPORTER")
            {
                params.transporter_code = localStorage.getItem("transportercode");
            }
            redirectURL.post("/manage/getIndentReport",params).then(async(response)=>{
				console.log(response.data);
                var rowData = response.data;
                var truckAssigned = rowData.filter((e) => e.assigned_check == 1);
                var truckUnAssigned = rowData.filter((e) => e.assigned_check != 1 &&  e.status != 2);
                var rejected = rowData.filter((e) => e.status == 2);
                this.setState({
                    allrowData : rowData,
                    rowData : rowData,
                    truckAssigned : truckAssigned,
                    truckUnAssigned : truckUnAssigned,
                    rejected : rejected,
                    loadshow : "show-n",
                    overly : "show-n"
                })
			}).catch=((e)=>
			{
				console.log(e);
			})	
        }
        else
        {
            this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
        }    
    }
    
    onClickFilterButton = (event) => {
        this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		event.preventDefault();
		var transporter = this.state.transporter;
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		var ship_city = $("#ship_city").val();
		var customer = this.state.customer;
        if(transporter.value !="" || ship_city !="" || customer !="")
        {
            var params = {
                "startDate":sDate,
                "endDate":eDate,
                "ship_city":ship_city,
                "customer":customer.value,
			}
            if(localStorage.getItem("user_type") == "TRANSPORTER")
            {
                params.transporter_code = localStorage.getItem("transportercode");
            }
            else
            {
                params.transporter_code = transporter.value;
            }
            redirectURL.post("/manage/getIndentReport",params).then(async(response)=>{
				console.log(response.data);
                var rowData = response.data;
                var truckAssigned = rowData.filter((e) => e.assigned_check == 1);
                var truckUnAssigned = rowData.filter((e) => e.assigned_check != 1 &&  e.status != 2);
                var rejected = rowData.filter((e) => e.status == 2);
                this.setState({
                    allrowData : rowData,
                    rowData : rowData,
                    truckAssigned : truckAssigned,
                    truckUnAssigned : truckUnAssigned,
                    rejected : rejected,
                    loadshow : "show-n",
                    overly : "show-n"
                })
			}).catch=((e)=>
			{
				console.log(e);
			})	
        }
        else
        {
            this.setState({
				basicTitle : "Please enter any field to continue",
				basicType : "danger",
				show : true,
				loadshow : "show-n",
				overly : "show-n"
			})
        }    
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					startDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 	
				this.setState({
					startDate:startdate
				});
			}
		}
		else
		{
			this.setState({
				startDate:startdate
			});
		}
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					endDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 
				this.setState({
					endDate:edate
				});
			}
		}
		else
		{
			this.setState({
				endDate:edate
			});
		}
	}
	reloadPageData=()=>{
		window.location.reload();
	}
	render(){
        const { open } = this.state;
        var columnwithDefs = [
            // {
            //     headerName: "Delivery Order No",
            //     field: "delivery_order_number",
            //     width:160,
            //     filter: true,
            //     resizable: true,
            //     editable:false,            
            // },
            {
                headerName: "Sales Order No",
                field: "sales_order_number",
                width:160,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width:160,
                filter: true,
                resizable: true,
                editable:false,            
            },
            {
                headerName: "Destination",
                field: "ship_city",
                width:150,
                filter: true,
                resizable: true,
                editable:false,            
            },     
            {
                headerName: "Customer Name",
                field: "customer_name",
                width:180,
                filter: true,
                resizable: true,
                editable:false,            
            },     
            {
                headerName: "Plant Name",
                field: "plant_name",
                width:200,
                filter: true,
                resizable: true,
                editable:false,    
                valueGetter: function(params){
                    return params.data.plant_code+" - "+params.data.plant_name+" - "+params.data.plant_location
                }        
            },     
            {
                headerName: "Expected Truck Type",
                field: "expected_truck_type",
                width:180,
                filter: true,
                resizable: true,
                editable:false  
            },    
            {
                headerName: "Remarks",
                field: "remarks",
                width:180,
                filter: true,
                resizable: true,
                editable:false  
            },
            {
                headerName: "Scheduled Date",
                field: "sch_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.sch_date !=undefined && params.data.sch_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.sch_date);
                    }
                }    
            },    
            {
                headerName: "Assigned Date",
                field: "created_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false,  
                valueGetter: function(params){
                    if(params.data.created_date !=undefined && params.data.created_date !="")
                    {
                        return getHyphenDDMMMYYYYHHMM(params.data.created_date);
                    }
                }
            },
            {
                headerName: "Status",
                field: "status",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"right",
                valueGetter:function(params){
                    if(params.data.status == 2)
                    {
                        return "Rejected";
                    }
                    else if(params.data.assigned_check == 1)
                    {
                        return "Truck Assigned";
                    }
                    else
                    {
                        return "Pending";
                    }
                },
            }
        ]
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = {minHeight:"234px"};
		var filterCardStyle2 = {padding:'10px'};
		var mnFilterClass = {marginLeft:"0px", padding:"0px"};
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className=' col-xl-12 col-lg-12'>
                        <h5>
                            <i className="icofont icofont-list cus-i"></i> <span>Indent Report</span>
                            {/* <button className="btn btn-info" style={{float:"right"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                            <button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
                        </h5>
                    </div>
                </div>
                {/* <div className="row" style={{marginBottom:"1%"}}>
					<div className={"col-xl-12 col-lg-12 " +  this.state.showFiltersForLongHaulScreenTab}>
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{float:"right"}}>Reset Filters</button>
					</div>
				</div> */}
                <div className="row" style={{marginTop:"1%"}}>
					<div className={"col-xl-4 col-lg-4"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{padding:"10px 30px"}}>
								<form className="row" onSubmit={this.filterData.bind(this)}>
									<div className={filterClassName}>
										<label>Select Type :  </label>
										<Select 
										className="border-radius-0"
										isMulti={false}
										id="selectedFilter"
										style={{borderRadius:"0px"}} 
										options={[
											// {label:"Delivery Order No",value:"delivery_order_number"},
											{label:"Sales Order No",value:"sales_order_number"},
											{label:"Truck No",value:"truck_no"},
											]} required />
									</div>
									<div className={filterClassName}>
										<label className="reason">Sales Order / Truck No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment"/>
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-primary">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className={"col-xl-8 col-lg-8"}>
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
                                <div className="row col-xl-12 col-lg-12">
                                    {(localStorage.getItem("user_type") != "TRANSPORTER") ?
                                    <div className="form-group col-xl-4 col-lg-4">
                                        <label>Transporter :  </label>
                                        <Select 
                                        placeholder={"Select Transporter"}
                                        closeMenuOnSelect={true}
                                        onChange={this.onChangeTransporterItem} 
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        value={this.state.transporter}
                                        options={this.selecttransporters()} />  
                                    </div>
                                    :""}
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label>From Date</label>
                                        <Datetime value={this.state.startDate} 
                                        disableCloseOnClickOutside={false} 
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off',readOnly : true }}
                                        dateFormat="YYYY-MM-DD" 
                                        name="startDate" 
                                        onChange={this.handlerStartDateTime.bind(this)} />

                                    </div>

                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label>To Date</label>
                                        {/* <input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required /> */}
                                        <Datetime value={this.state.endDate} 
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true} 
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off',readOnly : true}} 
                                        dateFormat="YYYY-MM-DD" 
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                        
                                    </div>
                                </div>	
							    <div className="row col-xl-12 col-lg-12">
									<div className="form-group col-xl-4 col-lg-4">
										<label>Ship City: </label>
                                        <input type="text" name="ship_city" autoComplete="off" class="form-control" id="ship_city"/>
									</div>
									
									<div className="form-group col-xl-4 col-lg-4">
										<label>Select Customer: </label>
										<Select placeholder={"Select Customer"} value={this.state.customer} className="border-radius-0" onChange={this.onChangeCustomerItem.bind(this)} style={{borderRadius:"0px"}} options={this.selectcustomers()} />
									</div>

                                    <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-primary" onClick={this.onClickFilterButton.bind(this)}>Submit</button>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-10p">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"truckAssigned")}>
                                            <span className="f13">Trucks Assigned</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.truckAssigned.length)?this.state.truckAssigned.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"truckUnAssigned")}>
                                            <span className="f13">Trucks Unassigned</span>
                                            <h4 class="txt-warning f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.truckUnAssigned.length)?this.state.truckUnAssigned.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"rejected")}>
                                            <span className="f13">Trucks Unassigned</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.rejected.length)?this.state.rejected.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                    </div>                                
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                masterDetail={true}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                onCellClicked={this.onCellClicked}
                                // onCellEditingStopped={this.onCellUpdateData}
                                />
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h4 className="subH">
                        {this.state.sliderTitle}
                        <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Truck No</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.truck_no}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Transporter</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.transporter_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-20p">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Sold Party</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.sold_party}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Ship Party</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.ship_party}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Ship City</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.ship_city}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-20p">
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Plant Code</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.plant_code}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Driver Name</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.driver_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h6 style={{fontWeight:"500",color:"#198754"}}>Driver Mobile</h6>
                                        </div> 
                                        <div className="col-sm-12">
                                            {this.state.rowDetails.driver_mobile}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form method="POST" id="upform" className="theme-form mt-20p" onSubmit={this.updateStatus}>
                                <div className="form-group">
                                    <label className="">{this.state.labelName}</label> 
                                    <input type="text" name="dateField" id="dateField" className="form-control datetimepicker" required  />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} closeOnOverlayClick={false} styles={{ width: "300px" }} >
                    <div className="row">
                        <div className='col-sm-12'>
                            {(this.state.imgSrc !=undefined && this.state.imgSrc !="")?
                                <img style={{width:"250px"}} src={this.state.imgSrc}/>
                            : ""}
                        </div>
                    </div>
                </Modal>
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function loadDateTimeScript(){
	// alert("datetime");
	$('.datetimepicker').datetimepicker({
		datepicker: true,
        // minuteStepping:30,
        format: 'Y-m-d H:i',
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}

