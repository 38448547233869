import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import './ErrorMessage.css'; // Import CSS file for styling
var redirectURL = require('../redirectURL');
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class EnmUserLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {successLogin:0,loginMessage:""};
	}
    async componentDidMount() {
        const token = this.props.match.params.token;
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);
            if(response.data.auth == true)
            {
                this.setState({
                    "successLogin" : 1,
                })
                var userloggparams = {
                    token:response.data.token,
                    userId:response.data.userid
                }
                await redirectURL.post("/manage/saveuserlogged",userloggparams)
                .then((resp) => {
                    console.log("resp ", resp)
                })
                
                //	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
                localStorage.setItem('token', response.data.token);
                                    
                localStorage.setItem('userid', response.data.userid);
                let deptCode = JSON.stringify(response.data.user.dept_code);
                //console.log("In  LoginForm, response.data = ", response.data);
                localStorage.setItem('dept_code', deptCode);
                localStorage.setItem('is_admin', response.data.user.is_admin);
                localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                localStorage.setItem('is_global', response.data.user.is_global);
                localStorage.setItem('firstname', response.data.user.firstname);
                localStorage.setItem('username', response.data.user.username);
                localStorage.setItem('lastname', response.data.user.lastname);
                localStorage.setItem('is_sales_type', response.data.user.is_sales_type);
                localStorage.setItem('region', response.data.user.region);
                localStorage.setItem('sales_type', response.data.user.sales_type);
                var useremailat = response.data.user.email.replace("[at]","@")
                var useremail = useremailat.replace("[dot]",".")
                
                localStorage.setItem('email', useremail);
                localStorage.setItem('user_type', response.data.user.user_type);
                // localStorage.setItem('prt_transporter_code', response.data.user.prt_transporter_code);
                localStorage.setItem('prt_transporter_code', JSON.stringify(response.data.user.prt_transporter_code));
                
                console.log("response.data.menus ", response.data.menus);
                localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                //console.log("response.data.usermenucodes ", response.data.usermenucodes);
                localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                localStorage.setItem('transportercode', (response.data.user.transporter_code));
                localStorage.setItem('plant_code', (response.data.user.plant_code));
                console.log(response.data.user.roles,"response.data.user.roles")
                console.log(typeof(response.data.user.roles),"type of response.data.user.roles")
                localStorage.setItem('roles', JSON.stringify(response.data.user.roles));
                localStorage.setItem('is_enmovil_transporter', this.state.is_enmovil_transporter);
                localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
                localStorage.setItem('cha_code', response.data.user.cha_code);
                // var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                // var m = md(JSON.stringify(response.data.user))
                // localStorage.setItem('m', m);
                localStorage.setItem('supplier_code', response.data.user.supplier_code);
                try{
                    if(response.data.user.ro_zone != undefined){
                        localStorage.setItem('ro_zone', response.data.user.ro_zone);
                    }
                    else if(response.data.user.vehicle_group != undefined)
                    {
                        localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
                    }
                    else{
                        localStorage.setItem('vehicle_group', "");
                    }
                }
                catch(e){}
                
                //console.log('users roles', typeof response.data.user.roles);
                sessionStorage.setItem('ref', 1);
                if(response.data.user.is_department_admin !=undefined)
                {
                    localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
                }
                this.setState({
                    currentDepartmentCode: response.data.user.dept_code
                });
                if (response.data.token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                } else {
                delete axios.defaults.headers.common['Authorization'];
                }

                this.setState({
                    username:'',
                    email:'',
                    password:'',
                    loginMessage:response.data.msg
                });
            }
            else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
		if(localStorage.getItem("user_type") == "TRANSPORTER")
		{
			var dashboardUrl = "/assignedtrucks";
		}
		else if(localStorage.getItem("roles").indexOf("warehouse") >= 0)
		{
			// dashboardUrl = "/assignsalesorders";
			dashboardUrl = "/assignedtrucks";
		}
		else
		{
			if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0)
			{
				dashboardUrl = "/dashboard";
			}
			else
			{
				dashboardUrl = "/assignedtrucks";
			}
		}
		// var dashboardUrl = "/assignedtrucks";
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}
    
    
	render(){
        if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
        {
            var successLogin = this.state.successLogin;
            if(successLogin == 1)
            {
                let dashboardUrl = this.getUserDashboardUrl();
                console.log(dashboardUrl,"dashboardUrl1143");
                //let dashboardUrl = this.state.landingpage;
                // return <Redirect to="dashboard/snddefault" push={true} />

                return <Redirect to={dashboardUrl} push={true} />
            }
        }
		return (
            <div className="container-fluid">
                {/* Loader ends */}
                {(this.state.loginMessage)?
                <div className="error-container">
                    {/* SVG for error icon */}
                    <svg
                    className="error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        fill="red"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v8h-2zm0 10h2v2h-2z"
                    />
                    </svg>
                    {/* Error message */}
                    <p className="error-message">{this.state.loginMessage}</p>
                </div>
                :
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                }
            </div>
        );
    }
}

