import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';
import DashboardChart from "./dashboardchart";
import ColumnChartLoadingDashboard from './dashboardchart';

import {
	getDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
} from '../common/utils';

var moment = require("moment");
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const today = moment();
const disableFutureDt = current => {
    return current.isBefore(today)
}

export default class dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            frameworkComponents : {
            },
            statusBar: {
                statusPanels: [
                  {
                  statusPanel: "agTotalAndFilteredRowCountComponent",
                  align: "left"
                  },
                  {
                  statusPanel: "agTotalRowCountComponent",
                  align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize:200,
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
			pageTitle: "Plants",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            show:false,
            rowData:[],
            sliderType:1,
            sliderTitle : "Add Plant",
            rowId : "",
            transporters : [],
            transporter : {label:"Select Transporter",value:""},
            startDate:"",
            endDate:"",
            seriesData: [],
            categories: [],
            highestData: [],
            highestCategories: [],
            drpData : [],
            drpCategories : [],
            truckData : [],
            truckCategories : []
        };
        this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
	}
    
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){        
        var fdate = moment.parseZone().format("DD-MM-YYYY");
        var currentdate = moment.parseZone().format("DD-MM-YYYY");
        this.loadPageData();
    }
    
    loadPageData = () =>{
        var params = {};
        if(this.state.startDate !=undefined && this.state.startDate !="")
        {
            params.startDate = this.state.startDate;
            params.endDate = this.state.endDate;
        }
        if(localStorage.getItem("user_type") == "TRANSPORTER")
        {
            params.transporter_code = localStorage.getItem("transportercode");
        }
        else if(this.state.transporter.value !=undefined && this.state.transporter.value !="")
        {
            params.transporter_code = this.state.transporter_code.value;
        }
        // AVERAGE AND HIGHEST COUNT
        redirectURL.post("/manage/getDashboardAvgTimeData",params).then(async(response)=>{
            console.log(response.data);
            var respData = [{
                    "name" : "Gate-1 In To Gate-2 In",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.plantInAvgTime.toFixed(2)),
                },
                {
                    "name" : "Gate-2 In To Loading Started",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.gateInAvgTime.toFixed(2)),
                },
                {
                    "name" : "Loading Started To Loading Completed",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.loadingStartedAvgTime.toFixed(2)),
                },
                {
                    "name" : "Loading Completed To Gate-2 Out",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.loadingCompletedAvgTime.toFixed(2)),
                },
                {
                    "name" : "Gate-2 Out To Gate-1 Out",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.gateOutAvgTime.toFixed(2)),
                },
            ];
            var highestData = [{
                    "name" : "Gate-1 In To Gate-2 In",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.plantInDurationHighest.toFixed(2)),
                },
                {
                    "name" : "Gate-2 In To Loading Started",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.gateInDurationHighest.toFixed(2)),
                },
                {
                    "name" : "Loading Started To Loading Completed",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.loadingStartedHighest.toFixed(2)),
                },
                {
                    "name" : "Loading Completed To Gate-2 Out",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.loadingCompletedHighest.toFixed(2)),
                },
                {
                    "name" : "Gate-2 Out To Gate-1 Out",
                    "showTooltip": 1,
                    "y" : parseFloat(response.data.gateOutHighest.toFixed(2)),
                },
            ];
            var categories = ["Gate-2 In","Loading Started","Loading Completed","Gate-2 Out","Gate-1 Out"];
            this.setState({
                seriesData : respData,
                categories : categories,
                highestData: highestData,
                highestCategories: categories,
            })
        }).catch((e)=>{
            console.log(e);
        })
        // SO ITEMS ASSIGN REPORT
        redirectURL.post("/manage/getDashboardSoItemsReport",params).then(async(response1)=>{
            console.log(response1.data);
            var drpData = [];
            var drpCategories = [];
            response1.data.map(function(k){
                drpData.push({
                    "name": k.dataType,
                    "showTooltip": 1,
                    "y": parseFloat(k.dataCount.toFixed(2)),
                })
                drpCategories.push(k.dropped_reason)
            });
            // var records = response1.data;
            // if(records.length > 0)
            // {
            //     var transporterGroup = groupBy(records, rdata => rdata.transporter_name);
            //     var drpData = [];
            //     var drpCategories = [];
            //     console.log(transporterGroup,"transporterGroup")
            //     transporterGroup.forEach((wares, wkeys) => {
            //         var assignedItems = wares.filter((a) => a.assigned_check == 1);
            //         var unassignedItems = wares.filter((a) => a.assigned_check == undefined || a.assigned_check == "undefined" || a.assigned_check == "");
            //         console.log(wkeys,"wkeys")
            //         // wares.map(function(w){
            //             drpData.push(
            //                 {
            //                     "name" : wkeys,
            //                     "showTooltip": 1,
            //                     "y" : parseFloat(assignedItems.length.toFixed(2)),
            //                 }, 
            //                 {
            //                     "name" : wkeys,
            //                     "showTooltip": 1,
            //                     "y" : parseFloat(unassignedItems.length.toFixed(2)),
            //                 }
            //             );
            //         // });
            //         drpCategories.push(wkeys);
            //     })
            //     this.setState({
            //         drpData : drpData,
            //         drpCategories : drpCategories,
            //     })
            // }
            
            this.setState({
                drpData : drpData,
                drpCategories : drpCategories,
            })
        }).catch((e)=>{
            console.log(e);
        })
        // TRUCKS REPORT
        redirectURL.post("/manage/getDashboardTrucksReport",params).then(async(response2)=>{
            console.log(response2.data);
            var truckData = [];
            var truckCategories = [];
            var resp = response2.data;
            resp.map(function(t){
                truckData.push({
                    "name" : t.truck_no,
                    "showTooltip": 1,
                    "y" : parseFloat(t.deliveryOrders.toFixed(2)),
                });
                truckCategories.push(t.truck_no);
            })
            
        this.setState({
            truckData : truckData,
            truckCategories : truckCategories
        })
        }).catch((e)=>{
            console.log(e);
        })
        
    }

	closeAlert = async() => {
        await this.setState({
            show: false,
            loadshow:"show-n",
            overly:"show-n"
        });
        // await window.location.reload();
	}
	
    onGridReady = params => {
        this.gridApi = params.api;  
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () =>{
        //console.log(this.gridApi);    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            sliderType:"",
        });
    }
    showBulkUpload(){
        this.setState({
            sliderType : 2,
            sliderTitle : "Bulk Upload",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    
    saveNewRowData = (e) => {
        e.preventDefault();
        var plant_code = $("#plant_code").val();
        var plant_name = $("#plant_name").val();
        if(plant_code !=undefined && plant_code !="" && plant_name !=undefined && plant_name !="")
        {
            this.setState({
                loadshow : "show-m",
                overly : "show-m"
            });
            var params = {
                plant_code : plant_code,
                plant_name : plant_name
            }
            if(this.state.sliderType == 3)
            {
                params.rowId = this.state.rowId;
            }
            redirectURL.post("/manage/saveplants",params).then((response)=>{
                console.log(response);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n",
                        bulkslide:"",
                        sliderType:"",
                    })
                    this.loadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "warning",
                        show : true,
                        loadshow : "show-n",
                        overly : "show-n"
                    })
                }
            })
        }
        else
        {
            this.setState({
                show : true,
                basicTitle : "Fields Should not be Empty.",
                basicType : "warning",
                show : true,
                loadshow : "show-n",
                overly : "show-n"
            });
        }
    }
    uploadBulkFormHandler(event){
        event.preventDefault();
        var fileData = this.state.csvData;
        var flag=0;
        console.log(fileData,"fileData");
        
        if(flag == 0)
        {
            if(fileData.length > 0)
            {
                var reqParams = {
                    csvData : fileData,
                }
                console.log("reqParams",reqParams)
                redirectURL.post("/manage/savebulkplants",reqParams).then((response)=>{
                    var resp=response.data.message;
                    console.log(resp)
                    this.setState({
                        basicTitle : resp,
                        basicType : "success",
                        show : true,
                        bulkslide:'',
                        overly:'show-n',
                    })
                    this.loadPageData();
                }).catch((e)=>{
                    console.log(e);
                })
            }
            else
            {
                this.setState({
                    basicTitle : "Invalid File",
                    basicType : "warning",
                    show : true,
                })
            }
            $('#upform')[0].reset();
        }
        else
        {
            $("#bulkUploadID").val();
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
            $('#upform')[0].reset();
        }
    };
    addNewRow = () => {
        this.setState({
            sliderType : 1,
            sliderTitle : "Add Truck",
            overly :"show-m",
            bulkslide : "slider-translate"
        })
    }
    onCellClicked = (e) =>{
        if(e.colDef.field == "edit")
        {
            var rowId = e.data._id;
            var plant_code = e.data.plant_code;
            var plant_name = e.data.plant_name;
            console.log(plant_code,plant_name,"plant_code")
            this.setState({
                sliderType : 3,
                sliderTitle : "Edit Truck",
                rowId:rowId,
                overly :"show-m",
                bulkslide : "slider-translate",
            })
            $("#plant_code").val("");
            $("#plant_code").val(plant_code);
            $("#plant_name").val("");
            $("#plant_name").val(plant_name);
        }
    }
    onCellUpdateData = (e) =>{
        if(e.colDef.field == "status")
        {
            var status = e.data.status;
            if(status !=undefined && status !="")
            {
                var rowId = e.data._id;
                var params = {
                    rowId : rowId,
                    status : status,
                }
                redirectURL.post("/manage/updateplantstatus",params).then((response)=>{
                    console.log(response);
                    this.loadPageData();
                })
            }
        }
    }
    onChangeTransporterItem = (transporter) => {
		this.setState(
			{ transporter : transporter},
			() => console.log(`Transporter Option selected:`, this.state.transporter)
          );
	}
    selecttransporters(){
		let items = [];    
        // console.log(this.state.transporters,"this.state.transporters")
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.plant_code,"label":item.plant_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					startDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 	
				this.setState({
					startDate:startdate
				});
			}
		}
		else
		{
			this.setState({
				startDate:startdate
			});
		}
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startTime > endTime)
			{
				this.setState({
					endDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 
				this.setState({
					endDate:edate
				});
			}
		}
		else
		{
			this.setState({
				endDate:edate
			});
		}
	}
    onClickFilterButton = () => {
        this.loadPageData();
    }
	render(){
        var columnwithDefs = [  
            {
                headerName: "Plant Code",
                field: "plant_code",
                width:180,
                filter: true,
                resizable: true,
                editable:true,            
            },      
            {
                headerName: "Plant Name",
                field: "plant_name",
                width:300,
                filter: true,
                resizable: true,
                editable:true,            
            },
            {
                headerName: "Status",
                field: "status",
                width:180,
                filter: true,
                resizable: true,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ["Active","In Active"],
                },
            },
            {
                headerName: "Actions",
                field: "edit",
                width:120,
                filter: true,                
				cellRenderer: (params) => {
                    return '<i class="fa fa-pencil-square-o"></i>';
				},
            },  
        ]
		return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                
                <div className="row">
                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Sales order from date</label>
                        <Datetime value={this.state.startDate} 
                        disableCloseOnClickOutside={false} 
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'Select date', name: 'startDate', autoComplete:'off'}}
                        dateFormat="YYYY-MM-DD" 
                        name="startDate" 
                        onChange={this.handlerStartDateTime.bind(this)} />
                    </div>

                    <div className="col-xl-2 col-lg-2 form-group">
                        <label>Sales order to date</label>
                        <Datetime value={this.state.endDate} 
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true} 
                        inputProps={{ placeholder: 'Select date', name: 'endDate', autoComplete:'off'}} 
                        dateFormat="YYYY-MM-DD" 
                        onChange={this.handlerEndDateTime.bind(this)} />                        
                    </div>                    

                    <div className="form-group col-xl-2 col-lg-2" style={{textAlign:"left",marginTop:"2.3%"}}>
                        <button type="submit"  className="btn btn-primary" onClick={this.onClickFilterButton.bind(this)}>Submit</button>
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className='col-xl-12 col-lg-12'>
                    </div>
                </div>
                <div className={"row mt-10p"}>
                    <div className="col-xl-6">
                        <div className='card' style={{padding:"2% 0"}}>
                            <DashboardChart
                            chartType="column"
                            title={"Average Time Spent (In Mins)"}
                            subtitle={""}
                            context={this}
                            series={this.state.seriesData}
                            categories={this.state.categories}
                            seriesName=""
                            yaxistitle="Average Time Spent (In Mins)"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className='card' style={{padding:"2% 0"}}>
                            <DashboardChart
                            chartType="line"
                            title={"Maximum Time Spent (In Mins)"}
                            subtitle={""}
                            context={this}
                            series={this.state.highestData}
                            categories={this.state.highestCategories}
                            seriesName=""
                            yaxistitle="Maximum Time Spent (In Mins)"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                </div>
                <div className={"row mt-30p"}>
                    <div className="col-xl-6">
                        <div className='card' style={{padding:"2% 0"}}>
                            <ColumnChartLoadingDashboard
                            chartType="pie"
                            title={"Sales Order Items"}
                            subtitle={""}
                            context={this}
                            series={this.state.drpData}
                            categories={this.state.drpCategories}
                            seriesName=""
                            yaxistitle="Sale Order Items"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className='card' style={{padding:"2% 0"}}>
                            <DashboardChart
                            chartType="spline"
                            title={"Transporters With DL's"}
                            subtitle={""}
                            context={this}
                            series={this.state.truckData}
                            categories={this.state.truckCategories}
                            seriesName=""
                            yaxistitle="Transporters With DL's"
                            drilldownseries={[]}
                            // dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

