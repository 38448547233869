var axios = require('axios');
const cipher = salt => {
  try{
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  
    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
  }
  catch(e){

  }
  
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

let listUrls = ["/organisation/savevehiclebusinessinfo","/driver/savedriver","/organisation/savevehicleaddress","/vehicle/sendenquiry"]
var axiosInstance = axios.create({
  // baseURL: 'http://localhost:5002/agi/',
  baseURL : "https://agi.autometrics.in/agi/"
  /* other custom settings */
});
// axiosInstance.interceptors.request.use(function (params) {
//   // console.log("params edirecturl", params)
//   // console.log("params frnt", params.data)
//   var parameters = params.data;
//   var newparameters = {}
//   var flag = 0;
//   if(params.url != "/common/removeloggeduser" && params.url != "/common/applogin" && params.url != "/organisation/savevehiclebusinessinfo" && params.url != "/driver/savedriver" && params.url != "/vehicle/saveuserphoto" && params.url != "/organisation/savevehiclebusinessinfo")
//   {
//    if(params.url == "/applogin"){
//     var format = /[&$^*;'|<>\/]+/;
      
//    } 
//    else{  
//       if(listUrls.includes(params.url))
//         {
//         var format = /[!#$%^*\=;'|<>\/?]+/;
//       }
//       else {
//         var format = /[!#$%^*()\=;'|<>\/?]+/;  
//       }
//     }
//       // var format = /[!#$%^&*()+\=\[\]{};'"\\|,<>?]+/;  
//     // parameters.testing = "SELECT";
//     for(var key in parameters)
//     {
//       if(params.url == "/organisation/savevehicleaddress" || params.url == "/driver/savedriveraddress" || params.url == "/vehicle/savevehicle" || params.url == "/usermanagement/saveUserAssign" || params.url == '/usermanagement/verifydata')
//       {
//         // console.log("parameters[key] ", parameters[key])
//         if(parameters[key] != "" && parameters[key] != undefined && parameters[key] != "undefined")
//         {
//           if(parameters[key] == "undefined" || parameters[key] == undefined)
//           {
//             newparameters[key] = "";  
//           }
//           else{
//             try{
//               // console.log("Number ",key+" == "+typeof parameters[key])
//               if(typeof parameters[key] != "number")
//               {
//                 newparameters[key] =encode(parameters[key]);
//               }
//               else{
//                 var pm = parameters[key].toString();
//                 newparameters[key] =encode(pm);
//               }
              
//             }
//             catch(e){

//             }
            
//           }
//         }
//         else{
//           newparameters[key] =parameters[key];
//         }
//       }
//       else{
          
//         if(format.test(parameters[key]))
//         {
//           flag = 1;
//         }
//         else{
        
//             // console.log(typeof parameters[key])
//             if(parameters[key] != "" && parameters[key] != undefined && typeof parameters[key] != "number")
//             {
              
//               // console.log(typeof parameters[key])
//               // console.log(parameters[key])
//               if(key == "businesslogo")
//               {
//                 newparameters[key] =parameters[key];
//               }
//               else{
//                 newparameters[key] =encode(parameters[key]);
//               }
              
//             }
//             else{
//               // console.log(typeof parameters[key])
//               // console.log(parameters[key])
//               if(parameters[key] != undefined)
//               {
//                 if(key == "businesslogo")
//                 {
//                   newparameters[key] =parameters[key];
//                 }
//                 else{
//                   var pm = parameters[key].toString();
//                   newparameters[key] =encode(pm);
//                 }
                
//               }
              
//             }
            
          
//         }
//       }
//     }
//     // console.log("params ", parameters)
//     params.data=newparameters;
//   }
//   else{
//     flag=0;
//   }
//   // console.log("new parameters ", newparameters)
//   if(flag == 0)
//   {
//     return params;
//   }
//   else{
//     return false;
//   }
  
// });

module.exports = axiosInstance;
