import React, { Component } from 'react';

import { Button, Form, FormGroup, Label, Input, FormText, Alert, UncontrolledAlert } from 'reactstrap';

import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from 'react-router-dom';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");


const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}

var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
export const validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            dept_code: '',
            role: '',
            alldepartments: [],
            alertFade: true,
            formMessage: '',
            formStatus: '',
            show: false,
            basicTitle: '',
            basicType: "default",
            registration: 0,
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            loadshow: "show-n",
            cnfpassword: "",
            checkbx: 0,
            shownext: "show-n",
            successLogin: 0,
            errinp: "",
            showerr: "show-n",
            showerrcnf: "show-n",
            passworderror: "",
            showpwderr: "show-n",
            emailer: "",
            formerror: "",
            showfrmerr: "show-n",
            navMenus:false,
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.departmentDropdownOptions = this.departmentDropdownOptions.bind(this);
        this.formHandler = this.formHandler.bind(this);
        this.toggleHeaderNav = this.toggleHeaderNav.bind(this);
        this.cipher = this.cipher.bind(this);

        this.changeFormHandler = this.changeFormHandler.bind(this);

        this.formRegHandler = this.formRegHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        redirectURL.post('/overview/departments', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
            .then((response) => {
                this.setState({
                    alldepartments: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            })
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }
    departmentDropdownOptions() {
        var options = [];
        this.state.alldepartments.map((item) => {
            options.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>)
        });
        return options;
    }
    changeHandler(event) {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({
            [name]: value
        });

    }
    formHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

        var data = new FormData();
        data.append('firstname', this.state.firstname)
        data.append('lastname', this.state.lastname)
        data.append('email', this.state.email)
        data.append('password', this.state.password)
        data.append('dept_code', this.state.dept_code)
        // console.log("Form data ",data);
        var email = this.state.email.replace(/\s/g, "").toLowerCase();
        var verifypwd = CheckPassword(this.state.password)
        var fvalid = CheckField(this.state.firstname);
        var lvalid = CheckField(this.state.lastname);
        var evalid = CheckField(this.state.email);
        if (fvalid == false && lvalid == false && evalid == false) {
            this.setState({
                show: true,
                basicTitle: "Please enter valid data in fields",
                basicType: "danger",
                firstname: "",
                lastname: "",
                email: "",
                dept_code: "",
                password: ""
            })
        }
        else {
            //console.log("verifypwd ",verifypwd)
            if (verifypwd == true) {
                var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                var usrpwd = ppwwdd(this.state.password)
                var formdata = {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: email,
                    password: usrpwd,
                    dept_code: this.state.dept_code,
                }
                console.log("formdata ", formdata);
                redirectURL.post('/registration', formdata, {
                    headers: {
                        'Content-Disposition': 'application/x-www-form-urlencoded',
                        'Access-Control-Allow-Methods': 'POST'
                    }
                })
                    .then((response) => {
                        this.setState({
                            formMessage: response.data.message,
                            formStatus: response.data.status,
                            alertFade: false,
                            firstname: '',
                            lastname: '',
                            email: '',
                            password: '',
                            dept_code: '',
                            role: '',
                        });

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                    basicType: "danger"
                })
            }
        }
    }
    closeAlert() {
        this.setState({
            show: false,
            basicTitle: ""
        })
    }


    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    formRegHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

        var email = this.state.email.replace(/\s/g, "").toLowerCase();

        var verifypwd = "";

        var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (this.state.password.match(decimal)) {
            verifypwd = true;
        }
        else {
            verifypwd = false;
        }
        var fvalid = this.state.firstname;
        var lvalid = this.state.lastname;
        var evalid = this.state.email;
        if (fvalid == "") {
            this.setState({
                formerror: "Please enter valid data in fields",
                firstname: "",
                lastname: "",
                email: "",
                password: ""
            })
        }
        else {
            // console.log("this.state.mobilenumber ", this.state.mobilenumber)
            // console.log(this.state.password +"=="+ this.state.cnfpassword)
            // console.log("email.includes(@)",email.includes("@"))
            // if(email.includes("@") == true && email.includes(".") == true)
            // {
            var ff = 0;
            if (email != "") {
                if (!validEmail.test(email)) {
                    ff = 1
                    this.setState({
                        showerr: "show-n",
                        emailer: ""
                    })
                }
                else {
                    ff = 0
                }
            }
            else {
                ff = 0
            }
            if (verifypwd == true && ff == 0) {
                if (this.state.password == this.state.cnfpassword) {
                    this.setState({
                        showpwderr: "show-n",
                        passworderror: ""
                    });
                    var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                    var usrpwd = this.state.password
                    var a = decode(localStorage.getItem("a"))
                    var formdata = {
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email: email,
                        password: usrpwd,
                        a: a
                    }
                    // console.log("formdata ", formdata);
                    redirectURL.post('/registration', formdata, {
                        headers: {
                            'Content-Disposition': 'application/x-www-form-urlencoded',
                            'Access-Control-Allow-Methods': 'POST'
                        }
                    })
                        .then((response) => {
                            if (response.data.status == "success") {
                                localStorage.removeItem("a");
                                localStorage.removeItem("s");
                                localStorage.removeItem("cntr");
                                localStorage.removeItem("b");
                                localStorage.removeItem("registration");

                                localStorage.clear();

                                var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

                                localStorage.setItem('token', response.data.token);
                                localStorage.setItem('b', response.data.b);

                                localStorage.setItem('userid', response.data.userid);
                                let deptCode = JSON.stringify(response.data.user.dept_code);
                                //console.log("In  LoginForm, response.data = ", response.data);
                                localStorage.setItem('dept_code', deptCode);
                                localStorage.setItem('is_admin', response.data.user.is_admin);
                                localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                                localStorage.setItem('is_global', response.data.user.is_global);
                                localStorage.setItem('firstname', response.data.user.firstname);
                                localStorage.setItem('username', response.data.user.username);
                                localStorage.setItem('lastname', response.data.user.lastname);
                                // localStorage.setItem('m', encode(response.data.user));
                                // var useremailat = response.data.user.email.replace("[at]","@")
                                // var useremail = useremailat.replace("[dot]",".")
                                var useremail = response.data.user.email

                                localStorage.setItem('email', useremail);
                                localStorage.setItem('user_type', response.data.user.user_type);
                                // localStorage.setItem('prt_transporter_code', response.data.user.prt_transporter_code);
                                localStorage.setItem('m', ppwwdd(JSON.stringify(response.data.user)));

                                // console.log("response.data.menus ", response.data.menus);
                                localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                                //console.log("response.data.usermenucodes ", response.data.usermenucodes);
                                localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                                localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));

                                localStorage.setItem('roles', response.data.user.roles);
                                localStorage.setItem('isregister', 1);

                                //console.log('users roles', typeof response.data.user.roles);
                                sessionStorage.setItem('ref', 1);
                                var comp_code = "";


                                if (response.data.user.designation != undefined) {
                                    localStorage.setItem('designation', response.data.user.designation);
                                }
                                if (response.data.user.active_status != undefined) {
                                    localStorage.setItem('active_status', response.data.user.active_status);
                                }
                                if (response.data.user.is_department_admin != undefined) {
                                    localStorage.setItem('is_department_admin', response.data.user.is_department_admin);
                                }
                                if (response.data.user.staff_id != undefined) {
                                    localStorage.setItem('staff_id', response.data.user.staff_id);
                                }
                                if (response.data.user.dept_head_id != undefined) {
                                    localStorage.setItem('dept_head_id', response.data.user.dept_head_id);
                                }
                                if (response.data.user.report_manager_id != undefined) {
                                    localStorage.setItem('report_manager_id', response.data.user.report_manager_id);
                                }
                                this.setState({
                                    currentDepartmentCode: response.data.user.dept_code
                                });
                                if (response.data.token) {
                                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                                } else {
                                    delete axios.defaults.headers.common['Authorization'];
                                }
                                this.setState({
                                    show: true,
                                    basicTitle: "Successfully registered.",
                                    basicType: "success",
                                    firstname: '',
                                    lastname: '',
                                    email: '',
                                    password: '',
                                    role: '',
                                    successLogin: 1
                                });

                            }
                            else {
                                if (response.data.message == "Email ID already registered.") {
                                    this.setState({
                                        showerr: "show-m",
                                        emailer: "Email already resigtered."
                                    })
                                }
                                else {
                                    this.setState({
                                        showfrmerr: "show-m",
                                        formerror: response.data.message,
                                        firstname: '',
                                        lastname: '',
                                        email: '',
                                        password: '',
                                        role: '',
                                    });
                                }


                            }

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    this.setState({
                        showpwderr: "show-m",
                        passworderror: "Passwords did not match."
                    });
                }
            }
            else {
                if (email.length !== 0 && !validEmail.test(email)) {
                    this.setState({
                        showerr: "show-m",
                        emailer: "Invalid email ID."
                    })
                }
                else {
                    this.setState({
                        showpwderr: "show-m",
                        passworderror: "Password should be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",

                    })
                }
            }
            // }
            // else{
            //     this.setState({
            //         errinp:"errinp",
            //         showerr:"show-m"
            //     })
            // }
        }
    }

    changeFormHandler(event) {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    toggleHeaderNav() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')
        if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
            var successLogin = this.state.successLogin;
            if (successLogin == 1) {

                return <Redirect to={"/landing"} push={true} />
            }
        }

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert.bind(this)}
                >
                </SweetAlert>
                {this.state.alert}
                {/* Loader starts */}
                {/* <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div> */}
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">
                    {/* Page Header */}
                    <div className="page-main-header cus-main-header" id="pheader">
                        <div className="container-fluid">
                        <div className="main-header-left" style={{left:"20px"}}>
                                <div className="logo-wrapper">
                                    <a href="/">
                                        <span>
                                            <img src={require('../../assets/images/logo_620.png')} alt=""/>
                                        </span>
                                        
                                    </a>
                                </div>
                            </div>
                            <div className="main-header-right row">
                                <div className="nav-right col">
                                    <ul className={"nav-menus headermenus " + (this.state.navMenus ? 'open' : '')} >
                                        <li>
                                            <a href="/pages/support" title="Support">
                                                Support
                                            </a>
                                        </li>
                                
                                    </ul>
                                </div>
                                
                                {/* <div className="d-lg-none mobile-toggle" onClick={() => this.toggleHeaderNav() }>
                                    <i className="icon-align-justify"></i>
                                </div> */}

                                <div className="d-lg-none mobile-toggle">
                                    <a href="/pages/support" className="themefont" title="Support">
                                        Support
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Page Header End */}
                    <div className="container-fluid">
                        {/*login page start*/}
                        <div className="authentication-main" >

                            <div className="row">
                                <div className="col-md-8 signupbg mt-80p">

                                </div>
                                <div className="col-md-4 mt-30p">
                                    <div className="auth-innerright" >
                                        <div className="authentication-box">
                                            <h3 className="text-center f24">My Profile</h3>
                                            <h6 className="f14">
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.
                                            </h6>
                                            <div className="">

                                                {this.state.formStatus != "" ?
                                                    <div>
                                                        {(this.state.formStatus == 'Success') ?
                                                            <Alert color="secondary" fade={this.state.alertFade}>

                                                                <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                {this.state.alertFade = true}
                                                                {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                            </Alert>
                                                            :
                                                            <Alert color="danger" fade={this.state.alertFade}>
                                                                <i className="icon-alert"></i>  {this.state.formMessage}
                                                                {this.state.alertFade = true}
                                                            </Alert>
                                                        }
                                                    </div>
                                                    : ""
                                                }
                                                <form className="theme-form" onSubmit={this.formRegHandler}>
                                                    {(this.state.formerror != "") ?
                                                        <div className="errfnt">{this.state.formerror}</div>
                                                        : ""}
                                                    <div className="form-group mb-5p">
                                                        <label className="col-form-label f12">Name <span className="redColor">*</span></label>
                                                        <input required type="text" className="myforminpreg form-control" name="firstname" value={this.state.firstname} onChange={this.changeFormHandler} placeholder="" />
                                                    </div>

                                                    <div className="form-group mb-5p">
                                                        <label className="col-form-label f12">Mobile No.</label>
                                                        <input type="text" readonly required style={{ background: "#ddd", cursor: "not-allowed" }} className="myforminpreg form-control inpreadonly" name="mobile" value={(localStorage.getItem("a") != "" && localStorage.getItem("a") != undefined) ? decode(localStorage.getItem("a")) : ""} onChange={this.changeFormHandler} autoComplete="off" placeholder="" />
                                                    </div>
                                                    <div className="form-group mb-5p">
                                                        <label className="col-form-label f12">Email</label>
                                                        <input type="email" className={"myforminpreg form-control " + (this.state.errinp)} name="email" value={this.state.email} onChange={this.changeFormHandler} placeholder="" />
                                                        <div className={"errfnt " + this.state.showerr}>
                                                            <i className="icofont icofont-warning-alt"></i> {this.state.emailer}
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-5p">
                                                        <label className="col-form-label f12">New Password <span className="redColor">*</span></label>
                                                        <input type="password" required className="myforminpreg form-control pwdsp" name="password" value={this.state.password} onChange={this.changeFormHandler} placeholder="" />
                                                        {(this.state.password.length >= 1 && !validPassword.test(this.state.password)) ?
                                                            <div className={"errfnt"}>
                                                                <i className="icofont icofont-warning-alt"></i> Password does not meet the policy requirements.
                                                            </div> : <div />
                                                        }
                                                        <div className={"errfnt " + this.state.showpwderr}>
                                                            <i className="icofont icofont-warning-alt"></i> {this.state.passworderror}
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-5p">
                                                        <label className="col-form-label f12">Re-enter Password <span className="redColor">*</span></label>
                                                        <input type="password" required className="myforminpreg form-control pwdsp" name="cnfpassword" value={this.state.cnfpassword} onChange={this.changeFormHandler} placeholder="" />
                                                        {(this.state.password.length >= 1 && this.state.cnfpassword.length >= 1 && this.state.cnfpassword != this.state.password) ?
                                                            <div className={"errfnt"}>
                                                                <i className="icofont icofont-warning-alt"></i> Passwords do not match.
                                                            </div> : <div />
                                                        }
                                                    </div>
                                                    <div className="form-group form-row mt-3">
                                                        <div className="col-md-12 textAlignCenter">
                                                            {(this.state.firstname === '' || (this.state.email.length !== 0 && !validEmail.test(this.state.email)) || !validPassword.test(this.state.password) || this.state.cnfpassword !== this.state.password) ?
                                                                <button type="button" id="myLogRegbtn" style={{ backgroundColor: "#C4C4C4" }} className={"btn myLogRegbtn"}>Sign Up
                                                                </button>
                                                                :
                                                                <button type="submit" id="myLogRegbtn" style={{ backgroundColor: "#198754" }} className={"btn myLogRegbtn"}>Sign Up
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

function CheckPassword(inputtxt) {
    var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (inputtxt.match(decimal)) {
        return true;
    }
    else {
        return false;
    }
}
function CheckField(inputtxt) {
    var decimal = /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9]+$/;
    if (inputtxt.match(decimal)) {
        return true;
    }
    else {
        return false;
    }
}
export default Registration;