import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';
import Login from './components/login/login'
import EnmUserLogin from './components/login/enmuserloginnew'
import Logout from './components/login/logout'
import Register  from './components/login/register'
import Registration  from './components/login/registration'
import AuthCallBack  from './components/login/authcallback'
import ForgetPassword  from './components/pages/forget-password'
import Comingsoon  from './components/pages/comingsoon'
import Maintenance  from './components/pages/maintenance';
import ResetPassword from './components/login/reset-password';
import Transporters from "./components/masters/transporters";
import Users from "./components/masters/users";
import Trucks from "./components/masters/trucks";
import Plants from "./components/masters/plants";
import AssignedTrucks from "./components/masters/assignedtrucks";
import IndentReport from "./components/masters/indentreport";
import ItemsWiseReport from "./components/masters/itemswisereport";
import AssignTruck from "./components/masters/assigntruck";
import AssignSalesOrders from "./components/masters/assignsalesorders";
import GenerateQRcode from "./components/masters/generateqrcode";
import Dashboard from "./components/masters/dashboard";



class Root extends React.Component {

    render() {
        return (
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
 
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                        <Route path={`${process.env.PUBLIC_URL}/registration`} component={Registration} />
                        <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />                         
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/qrcode/:donumber`} component={GenerateQRcode} />
                        <App>
                            <Route path={`${process.env.PUBLIC_URL}/transporters`} component={Transporters} />
                            <Route path={`${process.env.PUBLIC_URL}/users`} component={Users} />
                            <Route path={`${process.env.PUBLIC_URL}/trucks`} component={Trucks} />
                            <Route path={`${process.env.PUBLIC_URL}/plants`} component={Plants} />
                            <Route path={`${process.env.PUBLIC_URL}/assignedtrucks`} component={AssignedTrucks} />
                            <Route path={`${process.env.PUBLIC_URL}/indentreport`} component={IndentReport} />
                            <Route path={`${process.env.PUBLIC_URL}/itemswisereport`} component={ItemsWiseReport} />
                            <Route path={`${process.env.PUBLIC_URL}/assigntruck`} component={AssignTruck} />
                            <Route path={`${process.env.PUBLIC_URL}/assignsalesorders`} component={AssignSalesOrders} />
                            <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                        </App>
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </Provider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));


